import React from 'react'
import Layout from '../Layout'
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

function Flights() {
  return (
    <Layout name="Flights">
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              {/* Chart */}
              <Grid item xs={12} md={12} lg={12}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 240,
                  }}
                >
                  Here will be flight data
                </Paper>
              </Grid>
            </Grid>
          </Container>
    </Layout>
  )
}

export default Flights