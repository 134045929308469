import React, { useState, useEffect } from "react"
import Layout from "./dashboard/Layout"
import {Container,Grid,} from "@mui/material"



import CategoryTable from "./categoryTable"
import { addCategory, getcategories } from "../../api-services/adminServices/categoryService"


function Category() {
  const [open, setOpen] = useState(false)
  const [formValue, setformValue] = useState({
    categoryName: "",
    categoryIcon: "",
    categoryMedia: ""
  })
  const [categories, setCategories] = useState([])
  const handleChange = (event) => {
    setformValue({
      ...formValue,
      [event.target.name]: event.target.value,
    })
  }

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  const getCategoryData = async () => {
    const response = await getcategories()
    setCategories(response.data.data)
  }
  const addcategorySubmit = async (e) => {
    e.preventDefault()
    const CategoryFormData = new FormData()
    CategoryFormData.append("name", formValue.categoryName)
    CategoryFormData.append("icon", formValue.categoryMedia)
    const response = addCategory(CategoryFormData)
    response.then((res) => {
      if (res.data.success === true) {
        setOpen(false)
        getCategoryData()
      }
    })
    setformValue({ categoryIcon: "", categoryName: "" })
  }
  useEffect(() => {
    getCategoryData()
  }, [])
  return (
    <Layout name='Categories'>
      <Container maxWidth='lg' sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <CategoryTable categories={categories} />
          </Grid>
        </Grid>
      </Container>
    </Layout>
  )
}
export default Category
