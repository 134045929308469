import {
  Grid,
  Toolbar,
  AppBar,
  Typography,
  styled,
  Box,
  Collapse,
  ListItemButton,
  ListItemText,
  List,
  Button,
  TextField,Accordion,AccordionSummary,AccordionDetails
} from "@mui/material";


import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  ExpandLess,
  ExpandMore,
  Add,
  Person,
  PersonAddAlt1,
  Menu,
  Delete,

} from "@mui/icons-material";

import { useState } from "react";
import Logo from "../../images/logo.svg";
import Autocomplete from "react-google-autocomplete"
const GuideDashboard = () => {
  const AppBarStyled = styled(AppBar)({
    background: "white",
    padding: "0",
  });
  const ToolbarStyled = styled(Toolbar)({});
  const [open, setOpen] = useState(true);
const[mobileList,setMobileList]=useState(false)
  const [genralTips, setGenralTips] = useState("General tips");
  const [dayPlace, setDayPlace] = useState({
    day: "Day1",
    visitPlaceToday: "Places to visit",
  });
  const [newRoute,setNewRoute]=useState([1])
// const [placeSelected,setPlaceSelected]=useState({
//   parentId:'',
//   place:''
// })
const[placeVisit,setPlaceVisit]=useState([])
  const handleClick = () => {
    setOpen(!open);
  };
  const ListItemButtonStyled = styled(ListItemButton)({
    pl: 4,
    "&:focus": {
      textDecoration: "underline ",
      textUnderlineOffset: "5px",
    },
  });
  const ListItemButtonMobile=styled(ListItemButton)({
    pl:1,
  
  })
const AddNewRoute=()=>{
  setNewRoute([...newRoute,newRoute.length+1])


}
// console.log('place',placeSelected?.place.formatted_address,"parentId",.parentId)

console.log('place',placeVisit?.placeName)
  return (
    <>
      <Grid container spacing={0}>
        <Grid
          item
          xs={12}
          sm={8.5}
          xl={6}
          sx={{ display: "flex", flexDirection: "column" }}
       
     
          
        >
          <AppBarStyled
            sx={{
              mr: { xs: "0rem", sm: "32.22%",md:'29.2%', xl:'50%'},
              width: { xs: "100%", sm: "67.5%",md:'70.7%' ,xl:'50.5%'},
              background:'white'
            }}
          >
            <ToolbarStyled>
              <Typography>
                <Box
                  component="img"
                  src={Logo}
                  sx={{ width: { xs: 160, sm: 302 } }}
                />
              </Typography>
            </ToolbarStyled>
          </AppBarStyled>
          <Grid container mt={8} sx={{ px: "0rem" }}>
            <Grid
              
              sm={3}
              md={2}
              xl={1}
              sx={{
                height: "100vh",
                width: "15%",
                background: 'white',
                p: ".5rem",
                position: "fixed",
                left: "0",
                zIndex: "1",
              display:{xs:'none',md:'block'}
              }}
            >
              {/* ////////////////////////////////////////////// */}
              <List
                sx={{
                  width: "100%",
                  bgcolor: "background.paper",
                }}
                component="nav"
                aria-labelledby="nested-list-subheader"
              >
                <ListItemButton
                  onClick={handleClick}
                  sx={{
                    background: "black",
                    color: "white",
                    borderRadius: "10px",
                    width: "100%",
                    "&:hover": {
                      background: "black",
                      color: "white",
                    },
                  }}
                >
                  {open ? <ExpandLess /> : <ExpandMore />}
                  <ListItemText primary="Overview" />
                </ListItemButton>
                <Collapse in={open} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    <ListItemButtonStyled Link to="#generalTips">
                      <ListItemText primary="General tips" />
                    </ListItemButtonStyled>
                  </List>
                  {newRoute?.map((data)=>(
                  <List component="div" disablePadding>
                    <ListItemButtonStyled>
                  
 <ListItemText primary={`Day ${data}`} />
                  
                     
                    </ListItemButtonStyled>
                  </List>
                      ))}
                  <List component="div" disablePadding>
                    <ListItemButtonStyled Link to="#visitedplace">
                      <ListItemText primary="Places to visit" />
                    </ListItemButtonStyled>
                  </List>
                  <List component="div" disablePadding>
                    <ListItemButtonStyled>
                      <ListItemText primary="Untitled" />
                    </ListItemButtonStyled>
                  </List>
                </Collapse>
              </List>
              {/* ////////////////////////////////////////////// */}
            </Grid>
            {/* ///////////////////////// mobile list */}
            {mobileList?
            <Grid
              
              xs={4}
              sm={3}
              sx={{
                minHeight: "50vh",
                width: {xl:'30%',sm:"15%"},
                background: "transparent",
                px: ".5rem",
                py:'0',
                position: "absolute",
                left: "0",
                top:'7rem',
                zIndex: "999",
              display:{md:'none'},
           
              }}
            >
              {/* ////////////////////////////////////////////// */}
              <List
                sx={{
                  width: "100%",
                  bgcolor: "background.paper",
                }}
                component="nav"
                aria-labelledby="nested-list-subheader"
              >
                <ListItemButton
                  onClick={handleClick}
                  sx={{
                    background: "black",
                    color: "white",
                    borderRadius: "10px",
                    width: "100%",
                 
                   pl:'0',
                    "&:hover": {
                      background: "black",
                      color: "white",
                    },
                  }}
                >
                  {open ? <ExpandLess /> : <ExpandMore />}
                  <Typography variant='body2' sx={{fontSize:'.8rem'}}>Overview</Typography>
                </ListItemButton>
                <Collapse in={open} timeout="auto" unmountOnExit >
                  <List component="div" disablePadding>
                    <ListItemButtonMobile Link to="#generalTips" >
                    
                      <Typography variant='body2' sx={{fontSize:'.8rem'}}>General tips</Typography>
                    </ListItemButtonMobile>
                  </List>
                  <List component="div" disablePadding>
                    <ListItemButtonMobile>
                      {newRoute?.map((data)=>(
   <Typography variant='body2' sx={{fontSize:'.8rem'}}>Day{data}</Typography>
                      ))}
                 
                    </ListItemButtonMobile>
                  </List>
                  <List component="div" disablePadding>
                    <ListItemButtonMobile Link to="#visitedplace">
                    <Typography variant='body2' sx={{fontSize:'.7rem'}}>Places to Visit</Typography>
                    </ListItemButtonMobile>
                  </List>
                  <List component="div" disablePadding>
                    <ListItemButtonMobile>
                    <Typography variant='body2' sx={{fontSize:'.8rem'}}>Untitled</Typography>
                    </ListItemButtonMobile>
                  </List>
                </Collapse>
              </List>
              {/* ////////////////////////////////////////////// */}
            </Grid>:null}
            <Grid
             xs={12}
              md={10}
            xl={10.5}
        
              sx={{ p: "0rem", border: ".7px solid lightGray", ml: {xs:'0rem',md:"20.8%",xl:"19%"} }}
            >
              
              <Box
                sx={{
                  width: "100%",
                  position: "relative",
                  height: "300px",
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  backgroundImage: `url( "https://www.travelandleisure.com/thmb/SPUPzO88ZXq6P4Sm4mC5Xuinoik=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/eiffel-tower-paris-france-EIFFEL0217-6ccc3553e98946f18c893018d5b42bde.jpg"
               )`,
                  display: "flex",
                  justifyContent: "flex-end",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Menu sx={{color:'white',fontSize:'2.5rem',cursor:'pointer',display:{xs:'block',md:'none'},position:'absolute',top:'0',left:'0',zIndex:'999'}} onClick={()=>setMobileList(!mobileList)}/>
                <Box
                  sx={{
                    width:{xs:'95%',md:"70%",},
                    height: "7rem",
                    background: "white",
                    position: "absolute",
                    bottom: "-3rem",
                    p: ".8rem",
                    display: "block",
                    zIndex: "1",
                    borderRadius: "10px",
                    boxShadow:
                      "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                  }}
                >
                  <Typography variant="h4" sx={{ fontWeight: "bold" }}>
                    Japan Guide
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "end",
                      pt: "1rem",
                    }}
                  >
                    <Person
                      sx={{
                        color: "gray",
                        borderRadius: "50%",
                        background: "lightGray",
                        mr: ".5rem",
                        cursor: "pointer",
                        "&:hover": {
                          transform: "scale(1.5)",
                        },
                      }}
                    />
                    <PersonAddAlt1
                      sx={{
                        color: "gray",
                        cursor: "pointer",
                        "&:hover": {
                          transform: "scale(1.5)",
                        },
                      }}
                    />
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ width: "100%", mt: "2rem" }}>
                {/* ///////////////////////////////////////////////////////////////// genral */}

                <Accordion sx={{ my: "1rem", py: "1rem" }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="generalTips"
                  >
                    <TextField
                      value={genralTips}
                      onChange={(e) => genralTips(e.target.value)}
                      variant="standard"
                      size="small"
                      sx={{ fontSize: "5rem", fontWeight: "bold" }}
                    />
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box sx={{ marginLeft: "0rem" }}>
                      <TextField
                        variant="standard"
                        placeholder="Write or paste anything here: how to get around, tips and tricks"
                        sx={{
                          width: "70%",
                          height: "30px",
                          marginTop: "0.8rem",
                          background: "#fffde7",
                        }}
                      />
                    </Box>
                  </AccordionDetails>
                </Accordion>

                {/* ///////////////////////////////////////////////////////////////// day1 */}
              {newRoute?.map((data,ind)=>(
                  <Accordion sx={{ my: "1rem", py: "1rem"  }} key={ind}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="days"
                  >
                  <Typography variant='h5'>Day {data}</Typography>
               
                  </AccordionSummary>
              
                  <AccordionDetails>
                    {
                          placeVisit?.map((data)=>(
                            <Box sx={{display:'flex',width:'100%',justifyContent:'space-between',alignItems:'center',p:'5px'}}>
                            <Box >
                            <Typography variant="h6">
                            {data ? JSON.parse(data)?.formatted_address : null}
                            </Typography>
                            <input type="text" placeholder="Add notes here"  style={{border:'none',height:'30px',outline:'none',background:'#EAFFCB'}}/>
                          </Box>
                          <Box sx={{display:'flex',alignItems:'start'}}>
                          <Box component='img' sx={{width:'200px',height:'100px',borderRadius:'10px'}} src="https://images.unsplash.com/flagged/photo-1559717201-fbb671ff56b7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OXx8ZHViYWklMjBjaXR5fGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60"/>
                          <Delete sx={{cursor:'pointer','&:hover':{
                            color:'red',
                         
                          }}}/>
      
                          </Box>
                          </Box>
                          ))

                    }
                    
                    <Box sx={{ marginLeft: "0rem" }}>
                    <Autocomplete
          
          style={{
            height:' 42px',
            borderRadius: '5px',
            padding:' 0px 10px',
            width:' 50%',
            marginTop: '0.6rem',
            border: '1px solid #F45714',
            color:'#F45714',
            outline:'#F45714',
            
           
          }}
          placeholder="Add a place"
        
          onPlaceSelected={(place) => {
            setPlaceVisit(current => [...current, JSON.stringify(place)]) 
            
       }}
   
          />
                  
                    </Box>
                  </AccordionDetails>
            
                </Accordion>
              ))}

                {/* ///////////////////////////////////////////////////////////////// day1 end*/}
                {/* ///////////////////////////////////////////////////////////////// places to visit */}
                <Accordion sx={{ my: "1rem", py: "1rem" }}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="visitedplace"
                  >
                    <TextField
                      value={dayPlace.visitPlaceToday}
                      onChange={(e) =>
                        setDayPlace({
                          ...dayPlace,
                          visitPlaceToday: e.target.value,
                        })
                      }
                      variant="standard"
                      size="small"
                      sx={{ fontSize: "5rem" }}
                    />
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box sx={{ marginLeft: "0rem" }}>
                      <TextField
                        variant="standard"
                        placeholder="Write or paste anything here: how to get around, tips and tricks"
                        sx={{
                          width: "70%",
                          height: "30px",
                          marginTop: "0.8rem",
                          background: "#fffde7",
                        }}
                      />
                    </Box>
                  </AccordionDetails>
                </Accordion>

                {/* ///////////////////////////////////////////////////////////////// places to visit end*/}
                <Box
                  sx={{
                    height: "200px",
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    background: "#fff8e1",
                    pl: "5%",
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      mr: "2rem",
                      borderRadius: "10px",
                      textTransform: "none",
                    }}
                  >
                    <Add /> New list
                  </Button>
                  <Button
                    variant="outlined"
                    sx={{ borderRadius: "10px", textTransform: "none" }}
onClick={AddNewRoute}                  >
                    <Add /> Naw route
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          sm={3.5}
          xl={6}
          
          sx={{ display: { xs: "none", sm: "block", height: "100vh",position:'fixed',right:'0',width:'100%',top:'0'}, }}
        >
   
          <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d13292.403961014967!2d73.0523224!3d33.60268405!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2s!4v1670829235070!5m2!1sen!2s"
           style={{height:'100vh',width:'100%',border:'none'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </Grid>
      </Grid>
    </>
  );
};

export default GuideDashboard;
