import * as React from "react"
import Card from "@mui/material/Card"
import CardActionArea from "@mui/material/CardActionArea"
import CardContent from "@mui/material/CardContent"
import CardMedia from "@mui/material/CardMedia"
import Button from "@mui/material/Button"
import {Typography,Box,CardActions,Container} from "@mui/material"
import Slider from "react-slick";
export const AccordionDetail = () => {
  const cardData=[
    {imageUrl:'https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcQvnB0dD_MCmdoIGVGsC5tJdMxu5hjKag-mVuc8ZcbcBsJO0Yr-EtxTIU5Md9h3qoFaynWEh-qz4iuOwAbP_f7_7A',
    Title:'   Top Places for Paris',
    desc:'  ranging across all continents Antarctica'
  },
    {imageUrl:'https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcQvnB0dD_MCmdoIGVGsC5tJdMxu5hjKag-mVuc8ZcbcBsJO0Yr-EtxTIU5Md9h3qoFaynWEh-qz4iuOwAbP_f7_7A',
    Title:'   Top Places for Pakistan',
    desc:'Lizards s, with over 6,000 '
  },
    {imageUrl:'https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcQvnB0dD_MCmdoIGVGsC5tJdMxu5hjKag-mVuc8ZcbcBsJO0Yr-EtxTIU5Md9h3qoFaynWEh-qz4iuOwAbP_f7_7A',
    Title:'   Top Places for Dubai',
    desc:'over 6,000 species Antarctica'
  },
    {imageUrl:'https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcQvnB0dD_MCmdoIGVGsC5tJdMxu5hjKag-mVuc8ZcbcBsJO0Yr-EtxTIU5Md9h3qoFaynWEh-qz4iuOwAbP_f7_7A',
    Title:'   Top Places for Japan',
    desc:'Lizards are a widesprd ',
  },
    {imageUrl:'https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcQvnB0dD_MCmdoIGVGsC5tJdMxu5hjKag-mVuc8ZcbcBsJO0Yr-EtxTIU5Md9h3qoFaynWEh-qz4iuOwAbP_f7_7A',
    Title:'   Top Places for Japan',
    desc:'Lizards in Antarctica'
  },

  ]
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow:3,
    slidesToScroll: 3,
    initialSlide: 0,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (<>
     <Container sx={{width:{xs:'20rem',md:'40rem',xl:'100%'},padding:'0rem',m:'0 auto'}}>
        <Box sx={{  width: "100%",m: "0 auto", height: "50vh" }}>
         
          <Slider {...settings} >
            {cardData?.map((data, ind) => (
              <Card
                key={ind}
                sx={{ width: "100%", position: "relative",boxShadow:'none' }}
             
              >
                <CardActionArea sx={{ height: "9rem" }}>
                  <CardMedia
                    component="img"
                    src={data.imageUrl}
                    sx={{
                      height: "100%",
                      transition: "all .3s",
                      borderRadius: "5px",
                      "&:hover": {
                        transform: "scale(1.1)",
                      },
                    }}
                  />
           
                </CardActionArea>
                <CardContent sx={{ height: "100%", }}>
                  <Typography variant="subtitle2" sx={{'&:hover':{cursor:'pointer',}}}>{data.Title}</Typography>
              <Typography variant="body2">{data.desc}</Typography>
                </CardContent>
                <Typography
                    variant="body2"
                    sx={{
                      mt: ".3rem",
                      display: "flex",
                      alignItems: "center",
                      color: "gray",
                    }}
                  >
                    <Box
                      component="img"
                      src={data.imageUrl}
                      sx={{
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        cursor: "pointer",
                        mr: "4px",
                      }}
                    />
                 {data.Title}
                  </Typography>
              </Card>
            ))}
          </Slider>
        </Box>
      </Container>
  </>)
}
