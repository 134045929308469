import React, { useState, useEffect } from "react"
import {Table,TableBody,TablePagination,Box,CircularProgress,Paper,TableRow,TableHead,TableContainer,TableCell,tableCellClasses} from "@mui/material"








import { styled } from '@mui/material/styles';
import { Emoji } from 'emoji-picker-react';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';


import { Link } from "react-router-dom"

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#F45714',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 17,
    fontWeight:600
  },
}));

export default function SimpleTable({ categories }) {
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(7)

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, categories.length - page * rowsPerPage)
// console.log(categories.length)
  return (
    <TableContainer sx={{ mt: "1rem" }} component={Paper}>
      <Table aria-label='simple table'>
        <TableHead>
          <TableRow>
            <StyledTableCell>Name</StyledTableCell>
            <StyledTableCell align='center'>Icon</StyledTableCell>
            <StyledTableCell align='center'>Sub Categories Count</StyledTableCell>
            <StyledTableCell align='center'>Status</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {categories.length > 0  ? (
            categories
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <TableRow key={row.name}>
                  <TableCell component='th' scope='row'>
                    {row.name}
                  </TableCell>
                  <TableCell align='center'>
                    <Emoji unified={row.icon} size="25" />
                  </TableCell>
                  <TableCell align='center' v-align="middle">
                  <Box sx={{display: 'flex', width: 'fit-content', margin: 'auto'}}>
                  <Link
                    to={{pathname: `/auth/admin/${row.id}/sub-categories`}}
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <RemoveRedEyeIcon sx={{marginRight: '1rem'}}/>
                  </Link>
                    
                     ({row.sub_categories_count})
                     </Box>
                  </TableCell>
                  <TableCell align='center'>{row.status == 1 ? <Box sx={{backgroundColor: 'green', width: '50%', margin: 'auto',color: 'white'}}>Active</Box> : <Box sx={{backgroundColor: 'red', width: '50%', margin: 'auto',color: 'white'}}>Active</Box>}</TableCell>
                </TableRow>
              ))
          ) : (
            <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
             ml:'25rem',
             mt:'5rem'
            }}
          >
            <CircularProgress size='6rem' />
          </Box>
          )}

          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component='div'
        count={categories.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  )
}
