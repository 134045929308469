import API from "../api"

export const getUsers = async () => {
    try {
      return await API.get("/system-users")
    } catch (error) {
      console.log(error)
    }
  }
  // user save information
  export const saveUserData=async()=>{
   return API.post('/save-user-additional-info')
   .then(({data})=>{
    return data
   }).catch(err=>{throw err})
  }

  export const getCategoriesBudget = async () => {
    try {
      return await API.get("/get-categories-budget")
    } catch (error) {
      console.log(error)
    }
  }

  export const getCurrencies = async () => {
    try {
      return await API.get("/get-currencies")
    } catch (error) {
      console.log(error)
    }
  }

  export const saveUserCurrency = async (data) => {
    try {
      return await API.post("/save-currency",data)
    } catch (error) {
      console.log(error)
    }
  }

  export const saveUserInfo = async (data) => {
    try {
      return await API.post("/save-user-additional-info", data)
    } catch (error) {
      console.log(error)
    }
  }

  export const saveUserProfileImage = async (data) => {
    try {
      return await API.post("/save-user-profile-image", data)
    } catch (error) {
      console.log(error)
    }
  }
  // export const saveAdminProfileImage = async (data) => {
  //   try {
  //     return await API.post("/save-admin-profile-image", data)
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

