import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { Typography } from '@mui/material';



export default function RangeSlider() {
  const [value, setValue] = React.useState([1000, 2000]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Slider
     
        max={80000}
        min={1000}
        value={value}
        onChange={handleChange}
        valueLabelDisplay="auto"
       
      />
  <Box sx={{display:'flex',justifyContent:'space-between'}}>
  <Typography sx={{p:'0',mt:'-5px'}}>PKR 1000</Typography>
  <Typography sx={{p:'0',mt:'-5px'}}>PKR ,{value[1]}</Typography>
  </Box>
        </>
    
  );
}