import "./index.css"
import App from "./App"
import React from "react"
import store from "./redux"
import { Provider } from "react-redux"
import { createRoot } from "react-dom/client"
import reportWebVitals from "./reportWebVitals"
import { BrowserRouter } from "react-router-dom"
import { appTheme } from "./theme"
import { ThemeProvider } from "@mui/material/styles"
const container = document.getElementById("root")
const root = createRoot(container)
root.render(
  <ThemeProvider theme={appTheme}>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </ThemeProvider>
)

// ReactDOM.render(
//   <React.StrictMode>
//     <Provider store={store}>
//       <RouterProvider
//         router={router}
//       />
//     </Provider>
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
