import * as React from "react";
import {
  Box,
  Button,
  Typography,
  Container,
  Grid,
  Avatar,
  Rating,
  Stack,
  styled,
} from "@mui/material";
import Topbar from "./Topbar";

import { Link } from "react-router-dom";
import Footer from "../../components/footer";
import Slider from "./Slider";
import AirplanemodeActiveIcon from "@mui/icons-material/AirplanemodeActive";
const otherTools = [
  {
    img: " https://wanderlog.com/assets/MainFeatureTiles__addPlaces.png",
    name: " Add places from guides with 1 click",
    detail:
      "We crawled the web so you don’t have to. Easily save mentioned places.",
  },
  {
    img: "https://wanderlog.com/assets/MainFeatureTiles__collaborate.png",
    name: " Collaborate with friends in real time",
    detail:
      " Plan along with your friends with live syncing and collaborative editing.",
  },
  {
    img: "https://wanderlog.com/assets/MainFeatureTiles__import.png",
    name: " Import flight and hotel reservations",
    detail:
      "Connect or forward your emails to get them magically added into your trip plan.",
  },
  {
    img: " https://wanderlog.com/assets/MainFeatureTiles__optimizeRoute.png",
    name: " Optimize your route",
    detail:
      "Perfect for road trips and saving $$$ on gas! Get the best route auto-rearranged.",
  },
  {
    img: "https://wanderlog.com/assets/MainFeatureTiles__recommendations.png",
    name: "Get personalized suggestions",
    detail:
      "Find the best places to visit with smart recommendations based on your itinerary.",
  },
  {
    img: "https://wanderlog.com/assets/MainFeatureTiles__export.png",
    name: "Export your places to Google Maps",
    detail: "Synced automatically, for when plans change.",
  },
];

const HomePage = () => {
  const AvatarStyled = styled(Avatar)({
    marginLeft: "-1.2rem",
    cursor: "pointer",
  });
  return (
    <React.Fragment>
      <Topbar />

      <Box sx={{width: '100%'}}>
          <Slider>
          <Box
        sx={{
         
          position: "relative",
          color: "black",
          fontWeight: "300",
         
          zIndex: "999",

          height: "100vh",
        }}
      >
        <Box
          sx={{
            maxWidth: { xs: "100%", md: "100%", lg: "40%" },

            ml: {sm: "7rem", md: "0rem", xl: "1rem" },
            py: "2rem",
            pt: {xs:"20%",md:'30%',lg:'20%'}
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "1.8rem", md: "2.25rem" },
              fontWeight: 800,
              textAlign: { xs: "center", sm: "left" },
              lineHeight: { xs: "2.2rem", sm: "2.3rem" },
              mx: { xs: "1rem", sm: "0rem" },
              textTransform: "capitalize",
              textAlign: { xs: "center", sm: "left", lg: "center" },
              mt: { xs: "0rem", md: "-4rem" },
              width:{xs:'90vw',md:'100%'},
              ml:{md:'3rem',lg:'3rem'}
              
            }}
          >
            Plan the trip of your dreams
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: ".8rem", md: "1.75rem" },
              mt: "1.3rem",
              lineHeight: { md: "2rem" },
              textAlign: { xs: "center", sm: "left", lg: "center" },
              mx: { xs: "3rem", sm: "0rem" },
              textTransform: "capitalize",
              ml:{md:'3rem',lg:'0'},
              // textAlign:'center',
              fontWeight: "700",
            }}
          >
            even on a shoestring budget
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: { xs: "center", sm: "left", lg: "center" },
              mt: { xs: "1rem", sm: "0rem" },
              ml:{md:'8rem',lg:'0'}
            }}
          >
            <Link to="/createplan" style={{ textDecoration: "none" }}>
              <Button
                variant="outlined"
                // size={{ xs: "small", sm : "large" }}
                sx={{
                  color: "black",
                  mt: { sm: "2rem", md: "1rem" },
                  textTransform: "capitalize",
                  background:'#F45714',
                  fontSize: { xs: "1rem", sm: "1.2rem" },
                  boxShadow: "#F45714 1.95px 1.95px 2.6px",
                  color: "white",
                  transition:'all .7s',
                  "&:hover": {
                    boxShadow: "#000 1.95px 1.95px 2.6px",
                    color: "white",
                    padding:'5px 25px',
                    background:'#F45714',
                  
                  },
                }}
              >
                <AirplanemodeActiveIcon sx={{ mr: ".5rem" }} />
                plan your trip today
              </Button>
            </Link>
          </Box>
          <Typography
            sx={{
              fontSize: { xs: "1rem", sm: "1.2rem" },
              mt: { xs: "1.3rem", md: "0.7rem" },
              lineHeight: { md: "2rem" },
              textAlign: { xs: "center", sm: "left", lg: "center" },
              mx: { xs: "3rem", sm: "0rem" },
              color: "black",
              ml:{md:'10rem',lg:'0'}
            }}
          >
            No credit card required
          </Typography>
          <Grid
            container
            spacing={2}
            sx={{ ml: { md: "2rem", xl: "8rem" }, mt: {sm:'1rem', md: ".7rem" } }}
          >
            <Grid
              item
              xs={12}
              sm={6}
              md={2}
              lg={6}
              xl={6}
              sx={{ alignItems: "center", ml: { xs: "20%", sm: "0", } }}
            >
              <Stack direction="row" spacing={0} sx={{ ml: "1rem" }}>
                <AvatarStyled alt="Remy Sharp" src="./1.jpeg" />
                <AvatarStyled alt="Travis Howard" src="./2.jpeg" />
                <AvatarStyled alt="Cindy Baker" src="./3.jpeg" />
                <AvatarStyled alt="Cindy Baker" src="./4.jpeg" />
                <AvatarStyled alt="Cindy Baker" src="./5.jpeg" />
                <AvatarStyled alt="Remy Sharp" src="./6.jpeg" />
                <AvatarStyled alt="Travis Howard" src="./7.jpeg" />
                <AvatarStyled alt="Cindy Baker" src="./8.jpeg" />
               
              </Stack>
            </Grid>

            <Grid item xs={12} sm={6} md={2} lg={6} xl={6}>
              <Rating
                name="half-rating-read"
                defaultValue={4.8}
                readOnly
                sx={{ ml: { xs: "30%", sm: "-5rem",md:'8rem',lg:'-8rem' } }}
              />
              <Typography
                sx={{  
                  mt: ".2rem", 
                  fontSize: { xs: "1rem", sm: "1rem" },  
                  lineHeight: { md: "1rem" },
                  textAlign: { xs: "center", sm: "left",md:'center',lg:'left' },
                  mx: { xs: "1rem", sm: "0rem" },
                  color: "black",
                  width:{xs:'90vw',sm:'31vw'}
                }}
              >
                Join the 5,354 Travel Budgeter's
              </Typography>
              <Typography
                sx={{
                  fontSize: { xs: "1rem", sm: "1rem" },
                  mt: "0.2rem",
                  lineHeight: { md: "1rem" },
                  textAlign: { xs: "center", sm: "left",md:'center',lg:'left' },
                  mx: { xs: "3rem", sm: "0rem" },
                  color: "black",
                  width:{xs:'90vw',sm:'31vw'}
                }}
              >
                who rated us 4.8/5 stars
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
          </Slider>
      </Box>
      <Container maxWidth="xl">
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column",md:'row'},
            mt: { xs: "-10rem", sm: "14rem" },
            justifyContent: { sm: "space-between" },
            alignItems:'center',
            mx: { xs: "3rem", sm: "2rem" },
          }}
        >
          <Box sx={{ maxWidth: "34rem" ,mt:{xs:'0rem'}}}>
            <Box component='img' src="./left.png" sx={{width:{xs:'15rem',md:'30vw'},mt:{xs:'-20vh',md:'0rem'}}}/>
            {/* <Typography
              sx={{
                fontSize: { xs: "", sm: "1.8rem" },
                fontWeight: 800,
                lineHeight: "2.3rem",
              }}
            >
              You'll never travel without our trip planner again
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "", sm: "1.4rem" },
                mt: "1.3rem",
                lineHeight: "2rem",
              }}
            >
              No more switching between different apps, tabs, and tools to keep
              track of your travel plans.
            </Typography> */}
          </Box>
          <Box sx={{ maxWidth: "28rem", zIndex: "999",mt:{
            xs:'-4rem',
            md:'-6rem'
            } }}>
            <Typography
              sx={{
               
                // width:{xs:'90vw',md:'100%'},
               align:'justify',
                fontSize: { xs: "1rem", sm: "1.8rem" },
                textAlign: { xs: "center", sm: "left" },
                mx: { xs: "0rem", sm: "0rem" },
                mt: { xs: "0rem", sm: "0rem" },
                fontWeight: 800,
                lineHeight: { xs: "1.7rem", sm: "2.3rem" },
              }}
            >
              You'll never travel without our trip planner
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "1rem", sm: "1.4rem" },
                mt: "1.3rem",
                lineHeight: { xs: "1.4rem", sm: "2rem" },
                textAlign: { xs: "center", sm: "left" },
                mx: { xs: "0.5rem", sm: "0rem" },
              }}
            >
              No more switching between different apps, tabs, and tools to keep
              track of your travel plans.
            </Typography>
          </Box>
        </Box>
      </Container>
      <Container maxWidth="xl">
        <Box sx={{ mt: { xs: "3rem", sm: "17rem" } }}>
          <Typography
            sx={{
              fontSize: { xs: "1.34rem", sm: "1.9rem" },
              fontWeight: 800,
              textAlign: "center",
              lineHeight: { xs: "1.8rem", sm: "2rem" },
              mx: { xs: "0.5rem", sm: "0rem" },
            }}
          >
            Features to replace all your other tools
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-around",
              mx: "auto",
              mt: "1rem",
              maxWidth: "80%",
            }}
          >
            {otherTools.map((data, index) => {
              return (
                <Box
                  key={index}
                  sx={{ maxWidth: { xs: "18rem", sm: "19rem" }, mt: "3rem" }}
                >
                  <Box
                    component="img"
                    src={data.img}
                    sx={{ width: { xs: 260, sm: 302 } }}
                  />

                  <Typography
                    sx={{
                      fontSize: { xs: "1rem", ms: "1.1rem" },
                      fontWeight: 800,
                      mt: "1rem",
                      textAlign: "center",
                    }}
                  >
                    {data.name}
                  </Typography>
                  <Typography
                    sx={{ fontSize: "1rem", textAlign: "center", mt: "1rem" }}
                  >
                    {data.detail}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Container>
      {/* /////////////////////////////////////////////////  Discover your favorite destination*/}
      <Container>
      <Box sx={{ mt: { xs: "4rem", sm: "7rem" },width:{xs:'100%',sm:'50%',} ,m:'0 auto'}}>
          <Typography
            sx={{
              fontSize: { xs: ".5rem", sm: "1rem" },
              fontWeight: 300,
              textAlign: "center",
              lineHeight: { xs: "1rem", sm: "2rem" },
            }}
          >
       Have tips of your own? Write a guide to share with other travelres like you!
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Link to="/travelGuide" style={{ textDecoration: "none" }}>
            <Button
                variant="outlined"
                // size={{ xs: "small", sm : "large" }}
                sx={{
              
                  mt: { sm: "2rem", md: "1rem" },
                  textTransform: "capitalize",
                  fontSize: { xs: ".8rem", sm: "1rem" },
                  color: '#F45714',
                }}
              >
                 
                Write your own guide
              </Button>
            </Link>
          </Box>
        </Box>
      </Container>

      {/* ///////////////////////////////////////////////// */}
      <Container maxWidth="xl">
        <Box sx={{ mt: { xs: "6rem", sm: "12rem" } }}>
          <Typography
            sx={{
              fontSize: { xs: "1.34rem", sm: "1.9rem" },
              fontWeight: 800,
              textAlign: "center",
              lineHeight: { xs: "1.9rem", sm: "2rem" },
            }}
          >
            Ready to plan your trip in half the time?
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Link to="/createplan" style={{ textDecoration: "none" }}>
            <Button
                variant="outlined"
                // size={{ xs: "small", sm : "large" }}
                sx={{
                  color: "black",
                  mt: { sm: "2rem", md: "1rem" },
                  textTransform: "capitalize",
                  background:'#F45714',
                  fontSize: { xs: "1rem", sm: "1.2rem" },
                  boxShadow: "#F45714 1.95px 1.95px 2.6px",
                  color: "white",
                  transition:'all .7s',
                  "&:hover": {
                    boxShadow: "#000 1.95px 1.95px 2.6px",
                    color: "white",
                    padding:'5px 25px',
                    background:'#F45714',
                  
                  },
                }}
              >
                  <AirplanemodeActiveIcon sx={{ mr: ".5rem" }} />
                start planing
              </Button>
            </Link>
          </Box>
        </Box>
      </Container>
      <Box
        sx={{
          mt: { xs: "10rem", sm: "12rem" },
        }}
      >
        <Footer />
      </Box>
    </React.Fragment>
  );
};

export default HomePage;
