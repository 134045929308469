import { useState,useEffect } from "react";
import {Button,CssBaseline,CircularProgress,TextField,Box,Typography,FormControl,Container} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Footer from "../footer/index";
import CreatePlanTopBar from "./createPlanTopBar/CreatePlanTopBar";
import { useLocation, useNavigate } from "react-router-dom";
import { FormHelperText } from "@mui/material";
import Autocomplete from "react-google-autocomplete"
import {addTripPlan} from "../../api-services/userServices/tripPlanServices"
import dayjs from "dayjs";
export const CreatePlan = () => {
  const { pathname } = useLocation();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isSubmit, setIsSubmit] = useState(false);

  const navigate = useNavigate();

  const [budget, setBudget] = useState(null)
  const [place, setPlace] = useState(null)

  const [formErrors, setFormErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBudget(value);
  };
  
  const handleSubmit = (event) => {
    event.preventDefault();
    setFormErrors(validate());
    // setIsSubmit(true);
    addTripPlanFun(event);
  };
  const validate = () => {
    const errors = {};
    if (!budget) {
      errors.budget = "Field Required";
    }
    if (!place) {
      errors.destination = "Field Required";
    }
    if (!startDate) {
      errors.startDate = "Select Your Start Date";
    }
    if (!endDate) {
      errors.endDate = "Select Your End Date";
    }

    return errors;
  };

  // function for adding trip duration and destination
  const addTripPlanFun = async (e) => {
    setIsSubmit(true)
    e.preventDefault()
    const PlanTripData = new FormData()
    PlanTripData.append("budget", budget)
    PlanTripData.append("destination", place)
    PlanTripData.append("start_date", dayjs(startDate).format("YYYY-MM-DD"))
    PlanTripData.append("end_date", dayjs(endDate).format("YYYY-MM-DD"))
    PlanTripData.append("trip_no", JSON.parse(place)?.place_id)
    const response = addTripPlan(PlanTripData)
    response.then((res) => {
      setIsSubmit(res?.data?.success)
     
      if (res?.data?.success === true) {
        navigate(`/trip/${res.data.data.id}/${res.data.data.trip_no}`);
      }
    })
  }

  useEffect(() => {
    // if (Object.keys(formErrors).length === 0 && isSubmit) {
    //   navigate("/trip");
    // }
  }, [formErrors]);
  useEffect(() => {
    if (pathname === "/createplan") {
      // localStorage.clear();
    }
  }, []);
  

  return (
    <>
      <CreatePlanTopBar />
      <Container component="main" maxWidth="xs" sx={{ mb: "3rem" }}>
        <CssBaseline />
        <Box
          sx={{
            marginTop: 18,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            component="h1"
            variant="h4"
            fontWeight={600}
            sx={{
              fontSize: { xs: "1.7rem", sm: "2.8rem", md: "3rem" },
              mt: { xs: "-1.5rem", md: "0rem" },
              pt: { xs: "1rem" },
            }}
          >
            Plan a new trip
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 2 }}
          >
            <TextField
              margin="normal"
              variant="outlined"
              required
              fullWidth
              id="budget"
              label="Your Budget $0.00"
              name="budget"
              onChange={handleChange}
              sx={{
                color: "text.primary",

                "& .MuiFormLabel-root": {
                  color: "text.primary",
                },
              }}
            />
               <FormHelperText sx={{color:'red'}}>{formErrors.budget}</FormHelperText>

            <FormControl required fullWidth margin="normal">
              {/* <InputLabel
                id="destination "
                sx={{ color: "text.primary" }}
                name="destination"
              >
                Where To?
              </InputLabel> */}
              {/* <Select
                labelId="destination"
                id="destination"
                name="destination"
                value={formsValues.destination}
                onChange={handleChange}
              >
                <MenuItem value="Paris">Paris</MenuItem>
                <MenuItem value="Melbourne">Melbourne</MenuItem>
                <MenuItem value="Manchester">Manchester</MenuItem>
                <MenuItem value="Florida">Florida</MenuItem>
                <MenuItem value="California">California</MenuItem>
              </Select> */}

              <Autocomplete
                   
                    style={{
                      height:' 52px',
                      borderRadius: '5px',
                      padding:' 0px 10px',
                      width:' 100%',
                      marginTop: '0.6rem',
                      border: '1px solid #aaa',
                      color:'#F45714',
                      outline:'#eee'
                    }}
                    onPlaceSelected={(place) => {
                        setPlace(JSON.stringify(place));
                    }}
                />

              <FormHelperText sx={{color:'red'}}>{formErrors.destination}</FormHelperText>
            </FormControl>
            <Box sx={{ display: "flex", alignItems: "center", mt: "1.4rem" }}>
         <Box>
         <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  id="startDate"
                  name="startDate"
                  label="Start Date"
                  inputFormat="MM/DD/YYYY"
                  value={startDate}
                  onChange={(newValue) => {
                    setStartDate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField variant="outlined" {...params} />
                  )}
                />
              </LocalizationProvider>
              <FormHelperText sx={{color:'red'}}>{formErrors.startDate}</FormHelperText>
         </Box>
              <Typography sx={{ mx: { xs: "0.6rem", sm: "1rem" } }}>
                To
              </Typography>
             <Box>
             <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  id="endDate"
                  name="endDate"
                  label="End Date"
                  inputFormat="MM/DD/YYYY"
                  minDate={startDate}
                  value={endDate}
                  onChange={(newValue) => {
                    setEndDate(newValue);
                  }}
                  renderInput={(params) => (
                    <TextField variant="outlined" {...params} />
                  )}
                 
                />
              </LocalizationProvider>
              <FormHelperText sx={{color:'red'}}>{formErrors.endDate}</FormHelperText>
             </Box>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
              <Button
                type="submit"
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  width:'40%'
                }}
              >
      {isSubmit?<CircularProgress sx={{color:'white'}}/>:"start planing"}
              </Button>
            </Box>
          </Box>
        </Box>
      </Container>
      <Footer />
    </>
  );
};
