import * as React from "react";
import {
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { getPlanBudgetWeekly } from "../../../../api-services/userServices/budgetServices";
import { Emoji } from "emoji-picker-react";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  bgcolor: "background.paper",
  border: "none !important",
  outline: "none !important",
  boxShadow: "none",

  p: 0,
};

export default function WeeklyBudget(props) {
  const [open, setOpen] = React.useState(false);
  const [dataId, setDataId] = React.useState(props.id);
  const [weeklyData, setWeeklyData] = React.useState();
  const[isFetching,setIsFetching]=React.useState(true)

  const handleClose = () => setOpen(false);
  const GetWeekly = async (id) => {
    setIsFetching(false)
    const resp = await getPlanBudgetWeekly(id);
    setWeeklyData(resp?.data?.data);
    setIsFetching(resp?.data.success)
  };
  const handleOpen = () => {
    GetWeekly(dataId);
    setOpen(true);
  };
//   console.log(weeklyData);
  return (
    <div>
      <Typography onClick={handleOpen}>{props.children}</Typography>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Paper    sx={{
              background: "#f7ede8",
              pt: "1rem",
              width: "100%",
              overflowX: "auto",
              overflowY: "hidden",
            }}>
          <TableContainer>
            <Table
              sx={{ minWidth: "100%", outline: "none" }}
              aria-label="simple table"
            >
              <TableHead
                sx={{
                  background: "#ff7043",
                  color: "white",
                  fontWeight: "700",
                }}
              >
                <TableRow>
                  <TableCell
                    sx={{ color: "white", fontSize: "20px", fontWeight: "700" }}
                  >
                    Title
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ color: "white", fontSize: "20px", fontWeight: "500" }}
                  >
                    Budget
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {!isFetching?<CircularProgress sx={{ml:'100%'}}/>:weeklyData?.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ "&:nth-of-type(even)": { background: "lightgray" } }}
                  >
                    <TableCell
                      sx={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                   
                      <Emoji unified={row?.category.icon} size="20" /> 
                 <Typography variant="body2" sx={{ml:'.5rem',fontWeight:'700'}}>{row?.category.name}</Typography>
                    </TableCell>
                    <TableCell>{row.budget}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          </Paper>
        </Box>
      </Modal>
    </div>
  );
}
