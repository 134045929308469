// import AppBar from "@mui/material/AppBar"
// import Box from "@mui/material/Box"
// import Toolbar from "@mui/material/Toolbar"
// import Typography from "@mui/material/Typography"
// import Button from "@mui/material/Button"
// import useScrollTrigger from "@mui/material/useScrollTrigger"
// import Slide from "@mui/material/Slide"

// const navItems = ["Signup", "Login"]
// function HideOnScroll(props) {
//   const { children, window } = props
//   const trigger = useScrollTrigger({
//     target: window ? window() : undefined,
//   })

//   return (
//     <Slide appear={false} direction='down' in={!trigger}>
//       {children}
//     </Slide>
//   )
// }

// function Topbar(props) {
//   const { children, window } = props
//   const trigger = useScrollTrigger({
//     target: window ? window() : undefined,
//   })
//   console.log(window)
//   return (
//     <>
//       {!trigger ? (
// <HideOnScroll {...props}>
//   <AppBar
//     component='nav'
//     sx={{ backgroundColor: "transparent", boxShadow: "none" }}
//   >
//     <Toolbar>
//       <Typography
//         variant='h6'
//         component='div'
//         sx={{
//           flexGrow: 1,
//           display: { xs: "none", sm: "block" },
//           fontWeight: 900,
//           ml: "1rem",
//         }}
//       >
//         Shoestring
//       </Typography>
//       <Box sx={{ display: { xs: "none", sm: "block" }, mr: "1rem" }}>
//         {navItems.map((item) => (
//           <Button key={item} sx={{ color: "#fff" }}>
//             {item}
//           </Button>
//         ))}
//       </Box>
//     </Toolbar>
//   </AppBar>
// </HideOnScroll>
//       ) : (
//         <AppBar
//           component='nav'
//           sx={{ backgroundColor: "#fff", }}
//         >
//           <Toolbar>
//             <Typography
//               variant='h6'
//               component='div'
//               sx={{
//                 flexGrow: 1,
//                 display: { xs: "none", sm: "block" },
//                 fontWeight: 900,
//                 ml: "1rem",
//               }}
//             >
//               Shoestring
//             </Typography>
//             <Box sx={{ display: { xs: "none", sm: "block" }, mr: "1rem" }}>
//               {navItems.map((item) => (
//                 <Button key={item} sx={{ color: "black" }}>
//                   {item}
//                 </Button>
//               ))}
//             </Box>
//           </Toolbar>
//         </AppBar>
//       )}
//     </>
//   )
// }

// export default Topbar

import React from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  ThemeProvider,
  CssBaseline,
  createMuiTheme,
  Button,
  Box,
  ButtonGroup,
} from "@mui/material";
import { Link } from "react-router-dom";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import { Login } from "../../components/authBackDrop";
import { Register } from "../../components/authBackDrop/register";
import Logo from "../../images/logo.svg";
import Dropdown from "../../components/user/dashboard/Dropdown";
const navItems = ["Signup", "Login"];
const ScrollHandler = (props) => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: props.window ? window() : undefined,
  });

  return React.cloneElement(props.children, {
    style: {
      backgroundColor: trigger ? "white" : "transparent",
      color: trigger ? "black" : "black",
      transition: trigger ? "0.3s" : "0.5s",
      boxShadow: trigger ? " -1px 1px 14px 2px rgba(99,96,96,0.16)" : "none",
    },
  });
};

const ScrollToColor01 = (props) => {
  return <ScrollHandler {...props}>{props.children}</ScrollHandler>;
};

function Topbar(props) {
  const theme = createMuiTheme();
  const user = JSON.parse(localStorage.getItem("user"));



  return (
    <ThemeProvider>
      <ScrollToColor01>
        <AppBar component="nav">
          <Toolbar>
            <Typography
              variant="h6"
              component="div"
              sx={{
                flexGrow: 1,
                fontWeight: 900,
                ml: { sm: "5rem", md: "9rem", lg: "5rem" },
                width:'50%',
                mr:'0',
              pr:'0'
           
              }}
            >
            <Link to='/'> 
             <Box
                component="img"
                src={Logo}
                sx={{ width: { xs: 160, sm: 302 },cursor:'pointer' }}
              /></Link>
                
            </Typography>
         
            {user?.name ? (
              <Dropdown>
              <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                  component="img"
                  src={user?.image_path}
                  sx={{
                    width: "35px",
                    height: "35px",
                    borderRadius: "50%",
                    cursor: "pointer",
                  }}
                />
                <Typography variant="h6" sx={{ ml: ".5rem" }}>
                  {user?.name}
                </Typography>
             
              </Box></Dropdown>
            ) : (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Login />
                <Register />
              </Box>
            )}
          </Toolbar>
        </AppBar>
      </ScrollToColor01>
    </ThemeProvider>
  );
}

export default Topbar;
