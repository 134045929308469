import API from "../api"
export const addCategory = async (data) => {
  try {
    return await API.post("/add-category", data)
  } catch (error) {
    console.log(error)
  }
}
export const getcategories = async () => {
  try {
    return await API.get("/get-categories")
  } catch (error) {
    console.log(error)
  }
}

export const getSubCategory = async (id) => {
  try{
    return await API.get(`/get-sub-categories/${id}`)
  }catch(error){
    console.log(error)
  }
}

export const editCategory = async (id) => {
  try{
    return await API.get(`/get-category/${id}`)
  }catch(error){
    console.log(error);
  }
}

export const deleteCategory = async (id) => {
  try{
    return await API.get(`/delete-category/${id}`)
  }catch(error){
    console.log(error);
  }
}
export const userChangePassword=async(data)=>{
  try{
    return API.post(`/change-password`,data)
  }catch(error){
    console.log(error)
  }
}