import React, { useState, useEffect } from "react";
import {
  Paper,
  Box,
  Table,
  TableBody,
  TableContainer,
  IconButton,
  Tooltip,
  CircularProgress,
  TableHead,
  TableRow,
  TablePagination,
  styled,
  tableCellClasses,
  TableCell,
  Modal,
  Typography,
  Button 
} from "@mui/material";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

import { appTheme } from "../../../../theme";
import { Edit, RemoveRedEye,Delete ,ListAlt} from "@mui/icons-material";
import WeeklyBudget from "./WeeklyBudget";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: appTheme.palette.primary.main,
    // color: theme.palette.common.white,
    backgroundColor: "#cfd8dc",
    color: appTheme.palette.primary.main,
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 17,
    fontWeight: 600,
  },
}));

export const BudgetPlannerTable = (props) => {
  const Budgets = props.Budgets;
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [showBox, setShowBox] = useState(false);

  const handleClose = () => setShowBox(false);
  const[deletedId,setDeletedId]=useState()
  const navigate = useNavigate();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, Budgets.length - page * rowsPerPage);
  const deleteShowBox = (id) => {
    setDeletedId(id)
    setShowBox(true);
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 2,
    border:'none',
    outline:'none'
  };
 
  return (
    <>
    
    <Modal
  open={showBox}
  onClose={handleClose}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={style}>
    <Typography id="modal-modal-title" variant="h6" component="h2" textAlign='center'>
     Are you want to delete it ?
    </Typography>
    <Typography id="modal-modal-title" variant="body1" component="h2">
 If you delete budget then micro transaction will be also deleted
    </Typography>
  
   <Box sx={{display:'flex',
  justifyContent:'space-between'}}>
   <Button variant="outlined" sx={{cursor:'pointer',color:'blue',mt:'1rem',p:'3px',mb:'0',}} onClick={()=>setShowBox(false)}>Cancel</Button >
   <Button variant="contained"  sx={{cursor:'pointer',color:'blue',mt:'1rem',p:'3px',color:'white'}} onClick={()=>{props.deleteFun(deletedId)
  setShowBox(false)}}>Ok</Button>

   </Box>
  </Box>
</Modal>
      <Paper sx={{ width: "100%", overflow: "hidden", mt: "1.4rem" }}>
        <TableContainer component={Paper}>
       
          {props.tableDataSuccess?
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Title</StyledTableCell>
                <StyledTableCell align="center">Duration</StyledTableCell>
                <StyledTableCell align="center">Destination</StyledTableCell>
                <StyledTableCell align="center">Travel Budget</StyledTableCell>
                <StyledTableCell align="center">Weekly Budget</StyledTableCell>

                <StyledTableCell align="center">More Details</StyledTableCell>
                <StyledTableCell align="center">Actions</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Budgets.length > 0 ? (
                Budgets.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                ).map((row, index) => (
                  <TableRow key={row.title}    sx={{ "&:nth-of-type(even)": { background: "lightgray" } }}>
                    <TableCell component="th" scope="row">
                      {row.title}
                    </TableCell>
                    <TableCell align="center">
                      {dayjs(row.from_date).format('DD-MMM-YYYY')} To {dayjs(row.to_date).format('DD-MMM-YYYY')}
                    </TableCell>
                    <TableCell align="center">
                      {row.destination ? JSON.parse(row.destination)?.formatted_address : '--'}
                    </TableCell>
                    <TableCell align="center">{row.target_budget}$</TableCell>
                    <TableCell align="center">
                   <WeeklyBudget id={row.id}>

                      <ListAlt sx={{cursor:'pointer'}}/>
                   </WeeklyBudget>
                   
                        
                    </TableCell>

                    <TableCell align="center">
                      <IconButton
                        onClick={() =>
                          navigate(`/auth/budgetplannerChart/${row.id}`)
                        }
                      >
                        {<RemoveRedEye />}
                      </IconButton>
                    </TableCell>
                    <TableCell align="center">
                      <Tooltip title="Delete">
                        <Delete
                          sx={{
                            "&:hover": {
                              color: "red",
                              cursor: "pointer",
                            },
                          }}
                          // onClick={() => props.deleteFun(row.id)}
                          onClick={() => deleteShowBox(row.id)}
                        />
                      </Tooltip>
                      <Tooltip title="Edit">
                        <Edit
                          sx={{
                            ml: ".5rem",
                            "&:hover": {
                              color: "green",
                              cursor: "pointer",
                            },
                          }}
                          onClick={() => props.editFun(row.id)}
                          title="edit"
                        />
                      </Tooltip>
                    
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        ml: "5rem",
                        mt: "5rem",
                      }}
                    >
                      <CircularProgress size="6rem" />
                    </Box>
                  </TableCell>
                </TableRow>
              )}

              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>:
            <Typography variant='body1' textAlign='center' mt={2}>No Data in the Table</Typography>}
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={Budgets.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Paper>
    </>
  );
};
