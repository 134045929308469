import * as React from "react"
import {
  CardContent,
  CardMedia,
  Typography,
  Card,
  Grid,
  Box,
  useTheme,
  Button,
} from "@mui/material"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import TextField from "@mui/material/TextField"
import SwapHorizIcon from "@mui/icons-material/SwapHoriz"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import Autocomplete from "react-google-autocomplete"

export default function LodgingFilters() {
  const theme = useTheme()
  const [startDate, setStartDate] = React.useState(null)
  const [endDate, setEndDate] = React.useState(null)
  const [age, setAge] = React.useState("")
  const handleChange = (event) => {
    setAge(event.target.value)
  }

  return (
    <Card
      sx={{
        display: "flex",
        justifyContent: "left",
        alignItems: "center",
        color: "white",
      }}
      className='lodging__filters_div'
    >
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "transparent",
          width: "90%",
          margin: "auto",
          position: "relative",
          boxShadow: "none !important",
        }}
        className=''
      >
        <Grid container style={{ borderColor: "transparent" }}>
          <Grid xs={3}>
            <Autocomplete
              style={{
                height: "40px",
                borderRadius: "5px",
                padding: "0 10px",
                width: "100%",
              }}
              onPlaceSelected={(place) => {
                console.log(place)
              }}
            />
            {/* <TextField id="outlined-basic" variant="outlined" size="small" label="Enter Destination" className="flights__form_input" sx={{ width: '100%', background: 'white', borderRadius: '5px'}}/> */}
          </Grid>

          <Grid xs={2}>
            <input
              type='date'
              className='lodging__dates'
              style={{ height: "40px", width: "90%", marginLeft: "10%" }}
            />
          </Grid>
          <Grid xs={2}>
            <input
              type='date'
              className='lodging__dates'
              style={{ height: "40px", width: "90%", marginLeft: "10%" }}
            />
          </Grid>

          <Grid xs={2}>
            <FormControl
              sx={{
                width: "90%",
                marginLeft: "10%",
                borderRadius: "5px",
                background: "white",
              }}
              size='small'
            >
              <InputLabel id='demo-select-small'>Travelers</InputLabel>
              <Select
                labelId='demo-select-small'
                id='demo-select-small'
                value={age}
                label='Age'
                onChange={handleChange}
              >
                <MenuItem value=''>
                  <em>None</em>
                </MenuItem>
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid xs={1}></Grid>
          <Grid xs={2}>
            <Button variant='contained'>Search</Button>
          </Grid>
        </Grid>
      </Box>
    </Card>
  )
}
