import React, {useState, useEffect} from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import { getcategories } from "../../../../../../api-services/adminServices/categoryService";
import {Typography, Button, Paper, Box, TextField, Avatar, Stack, Grid} from '@mui/material'
import Autocomplete from "react-google-autocomplete"
import { Emoji } from "emoji-picker-react";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from "react-router-dom"
import {
    addBudget
  } from "../../../../../../api-services/userServices/budgetServices";
import dayjs from "dayjs";
import Footer from './footers';

export default function Questions({index, maxSteps, activeStep, handleNext, handleBack, theme}) {
    const navigate = useNavigate()
    const[isLoading,setIsLoading]=React.useState(false)
    const [categories, setCategories] = useState(null)

    const [title, setTitle] = useState("")
    const [tripType, setTripType] = useState("")
    const [place, setPlace] = useState(null)
    const [budget, setBudget] = useState("")
    const [catBudgetData, setCatBudgetData] = useState(null)
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    const handleCategoryBudget = (cat, budget) => {
        const newState = catBudgetData.map(obj => {
            // 👇️ if id equals 2, update country property
            if (obj.id === cat.id) {
              return {...obj, budget: budget};
            }
      
            // 👇️ otherwise return object as is
            return obj;
          });
      
          setCatBudgetData(newState);
    }

    const initialCat = [];

    const getCategoryData = async () => {
        const response = await getcategories();
        setCategories(response.data.data);

        response.data.data?.map((cat) => {
            initialCat.push({id: cat.id, budget: ""});
        })

        setCatBudgetData(initialCat);
    };

    const budgetSubmitHandler = async () => {
        setIsLoading(true)
        const budgetFromData = new FormData();
        budgetFromData.append("title", title);
        budgetFromData.append("from_date", dayjs(startDate).format("YYYY-MM-DD"));
        budgetFromData.append("to_date", dayjs(endDate).format("YYYY-MM-DD"));
        budgetFromData.append("destination", place);
        budgetFromData.append("target_budget", budget);
        budgetFromData.append("trip_type",tripType)
        budgetFromData.append("budget_plan_expenses",JSON.stringify(catBudgetData))
       
        const response = addBudget(budgetFromData);
        localStorage.setItem("budgetAddDate", startDate);
        response.then((res) => {
          if (res.data.success === true) {
            navigate(`/auth/budgetplannerChart/${res.data?.data?.id}`)
          }
        });
      };

    useEffect(() => {
        getCategoryData();
    }, []);

    return(
        <>
            {index == 0 ? 
                <>
                <Box sx={{width: '50%'}}>
                    <Typography variant="h5" sx={{mb: '1rem'}}>First, what's the title of trip? i.e Trip To Europe</Typography>
                    <Box sx={{mb: '1rem'}}>
                    <TextField
                            fullWidth
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            name="title"
                            label="Title"
                            variant="outlined"
                            size="small"
                            />
                    </Box>
                </Box>
                <Footer activeStep={activeStep} maxSteps={maxSteps} handleBack={handleBack} handleNext={handleNext} theme={theme} value={title}/>
                </>
            : null}

            {index == 1 ? 
                <>
                <Box sx={{width: '50%'}}>
                <Typography variant="h5" sx={{mb: '1rem'}}>Second, what type of trip is this?</Typography>
                <Box sx={{marginTop: '1rem', width: '100%'}}>

                    <Grid container justifyContent="center" alignItems="center" spacing={2}>
                        <Grid item xs={4}>
                            <Avatar variant="square" className="trip_type_avatar" src="/trip/solo.png" sx={{width: '152px', height: '152px', border: '1px solid #aaa', "&:hover": {border: "1px solid #ff7041",cursor: 'pointer', textAlign: 'center'}, border: `${tripType === 'solo' ? '2px solid #ff7041' : '1px solid #aaa'}`}} onClick={() => setTripType('solo')} />
                            <Typography sx={{ml: '44px'}}>Solo</Typography>
                        </Grid>

                        <Grid item xs={4}>
                            <Avatar variant="square" className="trip_type_avatar" src="/trip/couple.png" sx={{width: '152px', height: '152px', border: '1px solid #aaa', "&:hover": {border: "1px solid #ff7041",cursor: 'pointer'}, border: `${tripType === 'couple' ? '2px solid #ff7041' : '1px solid #aaa'}`}} onClick={() => setTripType('couple')} />
                            <Typography sx={{ml: '44px'}}>Couple</Typography>
                        </Grid>

                        <Grid item xs={4}>
                            <Avatar variant="square" className="trip_type_avatar" src="/trip/group.png" sx={{width: '152px', height: '152px', border: '1px solid #aaa', "&:hover": {border: "1px solid #ff7041",cursor: 'pointer'}, border: `${tripType === 'group' ? '2px solid #ff7041' : '1px solid #aaa'}`}} onClick={() => setTripType('group')} />
                            <Typography sx={{ml: '44px'}}>Group</Typography>
                        </Grid>
                    </Grid>
                </Box>
                </Box>
                <Footer activeStep={activeStep} maxSteps={maxSteps} handleBack={handleBack} handleNext={handleNext} theme={theme} value={tripType}/>
                </>
            : null}

            {/* third question */}
            {index == 2 ?
                <> 
                <Box sx={{width: '50%'}}>
                <Typography variant="h5" sx={{mb: '1rem'}}>Third, Where do you plan of traveling?</Typography>
                <Box sx={{marginTop: '1rem'}}>

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                        <Autocomplete
                    
                        style={{
                            height:' 42px',
                            borderRadius: '5px',
                            padding:' 0px 10px',
                            width:' 100%',
                            marginTop: '0rem',
                            border: '1px solid #aaa',
                            color:'#F45714',
                            outline:'#F45714'
                        }}
                        onPlaceSelected={(place) => {
                            setPlace(JSON.stringify(place))
                        }}
                        />
                        </Grid>
                    </Grid>
                </Box>
                </Box>
                <Footer activeStep={activeStep} maxSteps={maxSteps} handleBack={handleBack} handleNext={handleNext} theme={theme} value={place}/>
                </>
            : null}

            {/* fourth question */}
            {index == 3 ?
                <> 
                <Box sx={{width: '50%'}}>
                <Typography variant="h5" sx={{mb: '1rem'}}>Fourth, How Much Money Do You Want to Save For The Trip?</Typography>
                <Box sx={{marginTop: '1rem'}}>

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                        <TextField
                        fullWidth
                        value={budget}
                        onChange={(e) => setBudget(e.target.value)}
                        name="budget"
                        label="Budget"
                        variant="outlined"
                        size="small"
                        />
                        </Grid>
                    </Grid>
                
                </Box>
                </Box>
                <Footer activeStep={activeStep} maxSteps={maxSteps} handleBack={handleBack} handleNext={handleNext} theme={theme} value={budget}/>
                </>
            : null}

            {/* fifth question */}
            {index == 4 ? 
                <>
                <Box sx={{width: '50%'}}>
                <Typography variant="h5" sx={{mb: '1rem'}}>Fifth, What's the duration for budget plan?</Typography>
                <Box sx={{marginTop: '0rem'}}>

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <MobileDatePicker
                                label="Start Date"
                                inputFormat="MM/DD/YYYY"
                                minDate={new Date()}
                                value={startDate}
                                onChange={(newValue) => {
                                setStartDate(newValue);
                                }}
                                renderInput={(params) => (
                                <TextField
                                    fullWidth
                                    sx={{ mt: "0.6rem" }}
                                    {...params}
                                    size='small'
                            />
                            )}
                        />
                        </Grid>
                        <Grid item xs={4}>
                            <MobileDatePicker
                                label="End Date"
                                inputFormat="MM/DD/YYYY"
                                minDate={startDate}
                                value={endDate}
                                onChange={(newValue) => {
                                setEndDate(newValue);
                                }}
                                renderInput={(params) => (
                                <TextField
                                    fullWidth
                                    sx={{ mt: "0.6rem" }}
                                    {...params}
                                    size="small"
                            />
                            )}
                        />
                        </Grid>
                        </Grid>    
                        
                        </LocalizationProvider>
                        </Grid>
                    </Grid>
                
                </Box>
                </Box>
                <Footer activeStep={activeStep} maxSteps={maxSteps} handleBack={handleBack} handleNext={handleNext} theme={theme} value={startDate}/>
                </>
            : null}

            {/* Sixth question */}
            {index == 5 ?
                <> 
                <Box sx={{width: '50%'}}>
                <Typography variant="h5" sx={{mb: '1rem'}}>Sixth, In Order To Help You Budget For The Trip Of Your Dreams.
                                Tell Us what Your Weekly Expenses are. This will help us to show areas where you can reduce and save more money?</Typography>
                <Box sx={{marginTop: '1rem'}}>

                    
                        {
                            categories ? categories.map((cat,index) => {
                                return (
                                    <Grid container spacing={2} sx={{marginBottom: '1rem'}} key={`unique_${cat.id}`}>
                                        <Grid item xs={1}></Grid>
                                        <Grid item xs={4}>
                                            <Emoji unified={cat.icon} size="18" />{"  "}{cat.name}
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                value={catBudgetData[index]?.budget}
                                                onChange={(e) => handleCategoryBudget(cat, e.target.value)}
                                                name={cat.name}
                                                label={cat.name}
                                                variant="outlined"
                                                size='small'
                                                />
                                        </Grid>
                                        <Grid item xs={1}></Grid>
                                    </Grid>
                                )
                            })
                            : null
                        }
                </Box>
                </Box>
                <Footer activeStep={activeStep} maxSteps={maxSteps} handleBack={handleBack} handleNext={handleNext} theme={theme} value='bud'/>
                </>
            : null}

            {/* last step */}
            {index == 6 ?
                <> 
                <Box sx={{width: '50%'}}>
                <Typography variant="h5" sx={{mb: '1rem'}}>All Steps Completed. Ready to Submit</Typography>
                <Box sx={{marginTop: '1rem'}}>
                    <Button variant="contained" onClick={() => budgetSubmitHandler()}>
                    {isLoading? <CircularProgress sx={{color:'white'}}/> :" Submit"}
                    </Button>
                </Box>
                </Box>
                <Footer activeStep={activeStep} maxSteps={maxSteps} handleBack={handleBack} handleNext={handleNext} theme={theme}/>
                </>
            : null}
            </>
    )
}