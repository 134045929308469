import {Box,Typography,  CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    Paper,
    TableRow,} from '@mui/material'
    import {useState,useEffect} from 'react'
    import { getPlanBudgetWeekly } from "../../../../api-services/userServices/budgetServices";
    import { Emoji } from "emoji-picker-react";
    import{useParams} from 'react-router-dom'    



const WeeklyBudgetTable = () => {
    const params=useParams()
    const[isFetching,setIsFetching]=useState(true)
    const [dataId, setDataId] = useState(params.id);
  const [weeklyData, setWeeklyData] = useState();
    const GetWeekly = async (id) => {
        setIsFetching(false)
        const resp = await getPlanBudgetWeekly(id);
        setWeeklyData(resp?.data?.data);
        setIsFetching(resp?.data.success)
      };
useEffect(()=>{
    GetWeekly(dataId)
},[])

  return (
    <Box sx={{mt:'1rem'}}>
    <Typography variant="h5" sx={{ color: "#ff7043",p:'0rem', mt: "0rem" }}>
              Weekly Budget 
              </Typography>
              <Paper    sx={{
              background: "#f7ede8",
              pt: "1rem",
              width: "100%",
              overflowX: "auto",
              overflowY: "hidden",
            }}>
              <TableContainer>
            <Table
              sx={{ minWidth: "100%", outline: "none" }}
              aria-label="simple table"
            >
              <TableHead
                sx={{
                  background: "#ff7043",
                  color: "white",
                  fontWeight: "700",
                }}
              >
                <TableRow>
                  <TableCell
                    sx={{ color: "white", fontSize: "20px", fontWeight: "700" }}
                  >
                    Title
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{ color: "white", fontSize: "20px", fontWeight: "500" }}
                  >
                    Budget
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {!isFetching?<CircularProgress sx={{ml:'100%'}}/>:weeklyData?.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ "&:nth-of-type(even)":{ background: "lightgray " } }}
                  >
                    <TableCell
                      sx={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                   
                      <Emoji unified={row?.category.icon} size="20" /> 
                 <Typography variant="body2" sx={{ml:'.5rem',fontWeight:'700'}}>{row?.category.name}</Typography>
                    </TableCell>
                    <TableCell>{row.budget}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          </Paper>
    </Box>
  )
}

export default WeeklyBudgetTable