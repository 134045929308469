import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { Box, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Logo from "../../images/logo.svg";

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary">
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const listOfShoestring = [
  "Blog",
  // "Report security issue",
  "Terms of use & Privacy policy",
  // "Mobile app",
  "Travel budgeting & cost tracking",
  // "How to embed a map on your travel blog",
];
const listOfGuide = [
  "Trip planners by destination",
  "Road trips by destination",
  "Distances between cities",
  "Best places to visit by category",
  // "Popular search terms by destination",
  // "Weather around the world",
];
const getApp = [
  "https://wanderlog.com/assets/AppBadge__ios.png",
  "https://wanderlog.com/assets/AppBadge__android.png",
];
let date = new Date();
date = date.getFullYear();
const endFooter = [
  `© Shoestring Budget ${date}`,
  "Terms & Conditions",
  "Privacy",
];

export default function Footer() {
  return (
    <>
      <CssBaseline />
      <Box
        component="footer"
        sx={{
          pt: 8,
          // px: 2,
          mt: "auto",
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[200]
              : theme.palette.grey[800],
        }}
      >
        <Container maxWidth="xl">
          <Typography
            sx={{
              fontSize: { xs: "1.3rem", sm: "1.9rem" },
              lineHeight: { xs: "1.7rem", sm: "2.1rem" },
              fontWeight: 800,
              textAlign: "center",
            }}
          >
            For every kind of trip and every destination
          </Typography>
          <Typography
            sx={{
              textAlign: "center",
              mt: { xs: "0.8rem", sm: "0.6rem" },
              fontSize: { xs: "0.9rem", sm: "1rem" },
            }}
          >
            Shoestring is the best free budgeting planning app for any trip you
            might have in mind. It's:
          </Typography>
        </Container>
        <Container maxWidth="xl" sx={{ padding: "0rem" }}>
          <Box sx={{ mx: { sm: "3rem" }, mt: "7rem" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: { xs: "space-between", sm: "space-around" },
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontWeight: 800,
                    fontSize: { xs: "0.75rem", sm: "1rem" },
                  }}
                >
                  Shoestring
                </Typography>
                <List>
                  {listOfShoestring.map((data, index) => {
                    return (
                      <ListItem
                        disablePadding
                        sx={{
                          mt: { xs: "0.6rem", sm: "0.7rem" },
                          fontSize: { xs: "0.8rem", sm: "1rem" },
                        }}
                        key={index}
                      >
                        {data}
                      </ListItem>
                    );
                  })}
                </List>
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontWeight: 800,
                    fontSize: { xs: "0.75rem", sm: "1rem" },
                  }}
                >
                  Guides
                </Typography>
                <List>
                  {listOfGuide.map((data, index) => {
                    return (
                      <ListItem
                        disablePadding
                        sx={{
                          mt: { xs: "0.6rem", sm: "0.7rem" },
                          fontSize: { xs: "0.8rem", sm: "1rem" },
                        }}
                        key={index}
                      >
                        {data}
                      </ListItem>
                    );
                  })}
                </List>
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontWeight: 800,
                    fontSize: { xs: "0.75rem", sm: "1rem" },
                  }}
                >
                  Get the app
                </Typography>
                <List>
                  {getApp.map((data, index) => {
                    return (
                      <ListItem
                        disablePadding
                        sx={{ mt: "0.7rem" }}
                        key={index}
                      >
                        <Box
                          component="img"
                          src={data}
                          sx={{ width: { xs: 75, sm: 115 } }}
                        />
                      </ListItem>
                    );
                  })}
                </List>
              </Box>
            </Box>
          </Box>
        </Container>
        <Grid
          container
          sx={{
            backgroundColor: "#e0e0e0",
            color: "gray",
            py: ".5rem",
            px: "3rem",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection:{xs:'column',md:'row'}
          }}
        >
          <Box>
            <Box
              component="img"
              src={Logo}
              sx={{ width: { xs: 160, sm: '100%',md:302 } }}
            />
          </Box>
          <Box sx={{width:{xs:'100%',md: '40%'},mt:{xs:'2rem',md:'0'}}}>
            <Grid container spacing={2}>
            {endFooter.map((data, index) => {
                return (
              
                  <Typography key={index} sx={{mr: '1rem',pr:'.6rem',borderRight:'1px solid gray'}}>{data}</Typography>
                );
              })}
              
            </Grid>
           
          </Box>
        </Grid>
      </Box>
    </>
  );
}
