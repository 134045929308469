import React, { useState, useEffect } from "react";
import Layout from "../Layout";
import {
  Container,
  Grid,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  Paper,
  Box,
  Button,
  Typography,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Dialog,
  TextField,
  tableCellClasses,
  Modal,
} from "@mui/material";
import BasicModal from "./BasicModal";
// import TableCell, { tableCellClasses } from "@mui/material/TableCell";

import dayjs from "dayjs";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";

import { Edit, Delete,  } from "@mui/icons-material";
import { Emoji } from "emoji-picker-react";
import {
  BarChart,
  Bar,
 
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,

} from "recharts";
import {
  addMicroTranstions,
  deleteMicrotransaction,
  getBudgetDetail,
  getGraphDetail,
} from "../../../../api-services/userServices/budgetServices";
import { useParams } from "react-router-dom";

import { getcategories, addCategory } from "../../../../api-services/adminServices/categoryService";
import { getSubCategories } from "../../../../api-services/adminServices/subCategories";
import { appTheme } from "../../../../theme";

import { BudgetPieChart } from "./BudgetPieChart";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import WeeklyBudgetTable from "./WeeklyBudgetTable";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: appTheme.palette.primary.main,
    // color: theme.palette.common.white,
    backgroundColor: "#cfd8dc",
    color: appTheme.palette.primary.main,
    fontWeight: "bold",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 17,
    fontWeight: 600,
  },
}));

export const BudgetPlannerChart = () => {
  const { id } = useParams();
  const [page, setPage] = React.useState(0);
  const [open, setOpen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [budgetDetails, setBudgetDetails] = useState([]);
  const [expenseDate, setExpenseDate] = useState("");
  const [categoryId, setCategoryId] = React.useState("");
  const [subCategory, setSubCategory] = React.useState("");
  const [categories, setCategories] = React.useState([]);
  const [subCategories, setSubCategories] = React.useState([]);
  const [graphDetail, setGraphDetail] = React.useState([]);
  const [graphData, setGraphData] = React.useState();
  const [pieBudgetData, setPieBudgetData] = React.useState();
  const [pieActualData, setPieActualData] = React.useState();
  const [editEnable, setEditEnable] = useState(false);
  const [subCategoryId, setSubCategoryId] = useState("");
  const [addNewCategory, setAddNewCategory] = useState(false);
  const [formValue, setformValue] = useState({
    description: "",
    quantity: "",
    unit_price: "",
    total_price: "",
    errors:{
      date:'',
      description:'',
      categoryId:'',
      subCategoryId:'',
      quantity:'',
      unitprice:'',
    }
  });
  const [editId, setEditId] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [showBox, setShowBox] = useState(false);
  const [showOther, setShowOther] = React.useState(false);

  const handleCloseDelete = () => setShowBox(false);
  const [deletedId, setDeletedId] = useState();
  const handleChange = (event) => {
    setformValue({
      ...formValue,
      [event.target.name]: event.target.value,
      errors:''
    });
    // setformValue({...formValue,errors:''})
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getBudgetDetailData = async () => {
    const fromDat =
      fromDate != null ? dayjs(fromDate).format("YYYY-MM-DD") : null;
    const toDat = toDate != null ? dayjs(toDate).format("YYYY-MM-DD") : null;
    const response = await getBudgetDetail(id, fromDat, toDat);

    setBudgetDetails(response.data.data);
  };
  const getGraphDetails = async () => {
    const fromDat =
      fromDate != null ? dayjs(fromDate).format("YYYY-MM-DD") : null;
    const toDat = toDate != null ? dayjs(toDate).format("YYYY-MM-DD") : null;
    const response = await getGraphDetail(id, fromDat, toDat);
    setGraphDetail(response.data.data);
    if (response.data.success) {
      let tempArr = [];

      response.data.data.forEach((budget) => {
        tempArr.push({
          name: budget.category,
          Actual: budget.total,
          Budget: budget.total_actual,
          icon: budget.category_icon,
        });
      });
      setGraphData(tempArr);

      let budgetArr = [];

      response.data.data.forEach((budget) => {
        budgetArr.push({
          name: budget.category,
          Budget: budget.total_actual,
          icon: <Emoji unified={budget.category_icon} size="20" />,
        });
      });
      setPieBudgetData(budgetArr);

      let actualArr = [];

      response.data.data.forEach((budget) => {
        actualArr.push({
          name: budget.category,
          Budget: budget.total,
          icon: budget.category_icon,
        });
      });
      setPieActualData(actualArr);
    }
  };

  const handleFilter = () => {
    getGraphDetails();
  };

  const resetFilter = async () => {
    setFromDate(null);
    setToDate(null);
    getGraphDetails();
    getBudgetDetailData();
  };

  const appylyFilter = async () => {
      getGraphDetails();
      getBudgetDetailData();
  };

  const getCategoryData = async () => {
    const response = await getcategories();
    setCategories(response.data.data);
  };

  const categoryHandler = async (e) => {
    setCategoryId(e.target.value);
    const response = await getSubCategories(e.target.value);
    setSubCategories(response.data.data);
    setformValue({...formValue,errors:''})
  };
  const subCategoryHandler = (e) => {
    setSubCategoryId(e.target.value);
    setformValue({...formValue,subCategoryId:''})
  };

  useEffect(() => {
    getBudgetDetailData();
    getCategoryData();
    getGraphDetails();
  }, []);

  // useEffect(() => {
  //   getGraphDetails();
  //   getBudgetDetailData();
  // }, [fromDate]);

  // useEffect(() => {
  //   getGraphDetails();
  //   getBudgetDetailData();
  // }, [toDate]);

  // useEffect(() => {
  //   getGraphDetails()
  // }, [resetFilter])
  // console.log(graphDetail)
  const microTransactionSubmitHandler = async (e) => {
    e.preventDefault();

 if(!expenseDate){
  setformValue({...formValue,errors:{...formValue.errors,date:'date required'}})
 }
else  if(!formValue.description){
  setformValue({...formValue,errors:{...formValue.errors,description:'description required'}})
}
else
 if(!categoryId){
  setformValue({...formValue,errors:{...formValue.errors,categoryId:'parent category required'}})
}
else if(!subCategoryId){
  setformValue({...formValue,errors:{...formValue.errors,subCategoryId:'sub category required'}})
}else
if(!formValue.quantity){
  setformValue({...formValue,errors:{...formValue.errors,quantity:'quantity required'}})
}
else if(!formValue.unit_price){
  setformValue({...formValue,errors:{...formValue.errors,unitprice:'unit price required'}})
}else
 {
    let total = formValue.quantity * formValue.unit_price;
    const microTransaction = new FormData();
    microTransaction.append("budget_plan_id", id);
    microTransaction.append(
      "expense_date",
      dayjs(expenseDate).format("YYYY-MM-DD")
    );
    microTransaction.append("description", formValue.description);
    microTransaction.append("category_id", categoryId);
    microTransaction.append("quantity", formValue.quantity);
    microTransaction.append("unit_price", formValue.unit_price);
    microTransaction.append("total_price", total);
    microTransaction.append("sub_category_id", subCategoryId);
    const response = addMicroTranstions(microTransaction);
    response.then((res) => {
      if (res?.data?.success === true) {
        setOpen(false);
        getBudgetDetailData();
        getGraphDetails();
        setformValue({
          description: "",
          notes: "",
          quantity: "",
          total_price: "",
          unit_price: "",
        });
        setExpenseDate("");
        setCategoryId("");
        setSubCategory("");
      }
    });
    setOpen(false);}
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, 17 - page * rowsPerPage);
  let BudgetDate = localStorage.getItem("budgetAddDate");
  const rowDeleteHandle = async (id) => {
    let newData = budgetDetails.filter((data) => data.id !== id);
    setBudgetDetails(newData);
    const res = await deleteMicrotransaction(id);
  };
  const EditFieldFunc = (id) => {
    setEditId(id);
    let editRowValue = budgetDetails.filter((data) => data.id === id);

    let editData = {
      description: editRowValue[0].description,
      notes: editRowValue[0].notes,
      quantity: editRowValue[0].quantity,
      total_price: editRowValue[0].total_price,
      unit_price: editRowValue[0].unit_price,
    };
    setformValue(editData);

    setExpenseDate(editRowValue[0].expense_date);

    setSubCategory(editRowValue[0].sub_category);

    setOpen(true);
    setEditEnable(true);
  };
  const EditSubmit = (e) => {
    e.preventDefault();
    const microTransaction = new FormData();
    microTransaction.append("budget_plan_id", id);
    microTransaction.append(
      "expense_date",
      dayjs(expenseDate).format("YYYY-MM-DD")
    );
    microTransaction.append("description", formValue.description);
    microTransaction.append("category_id", categoryId);
    microTransaction.append("quantity", formValue.quantity);
    microTransaction.append("unit_price", formValue.unit_price);
    microTransaction.append(
      "total_price",
      formValue.unit_price * formValue.quantity
    );
    microTransaction.append("edit_id", editId);
    microTransaction.append("sub_category_id", subCategoryId);

    const response = addMicroTranstions(microTransaction);

    response.then((res) => {
      if (res.data.success === true) {
        setOpen(false);
      }
      getBudgetDetailData();

      setformValue({
        description: "",
        notes: "",
        quantity: "",
        total_price: "",
        unit_price: "",
      });
      setExpenseDate("");
      setCategoryId("");
      setSubCategory("");

      setEditId(null);
    });
  };
  const deleteShowBox = (id) => {
    setDeletedId(id);
    setShowBox(true);
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 2,
    border: "none",
    outline: "none",
  };

  const handleCloseOther = () => setShowOther(false);

  const addSubCategory = async (data) => {
    const CategoryFormData = new FormData()
    CategoryFormData.append("name", data.categoryName)
    CategoryFormData.append("icon", data.categoryMedia)
    CategoryFormData.append("parent_id", categoryId)
    CategoryFormData.append("from_user", true)

    const response = addCategory(CategoryFormData)
    response.then( async (res) => {
      if (res.data.success === true) {
        const result = await getSubCategories(categoryId);
        if(result){
          setSubCategories(result.data.data);

        }
        
      }
    })
    setAddNewCategory(false)
  }

  const handleNewSubCategory = () => {

      setAddNewCategory(true)
    
  }
  console.log('date error',formValue.errors)

  return (
    <Layout name="Budget Planner">
      <Modal
        open={showBox}
        onClose={handleCloseDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            textAlign="center"
          >
            Are you want to delete it ?
          </Typography>
          <Typography id="modal-modal-title" variant="body1" component="h2">
          your micro transication only should be deleted
          </Typography>

          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              variant="outlined"
              sx={{
                cursor: "pointer",
                color: "blue",
                mt: "1rem",
                p: "3px",
                mb: "0",
              }}
              onClick={() => setShowBox(false)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{
                cursor: "pointer",
                color: "blue",
                mt: "1rem",
                p: "3px",
                color: "white",
              }}
              onClick={() => {
                rowDeleteHandle(deletedId);
                setShowBox(false);
              }}
            >
              Ok
            </Button>
          </Box>
        </Box>
      </Modal>
      <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={2}>
          <Grid item xs={3} md={3} lg={3}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileDatePicker
                label="From Date"
                inputFormat="MM/DD/YYYY"
                value={fromDate}
                onChange={(newValue) => {
                  setFromDate(newValue);
                }}
                renderInput={(params) => (
                  <TextField size="small" sx={{ mb: "0.6rem" }} {...params} />
                )}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={3} md={3} lg={3}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileDatePicker
                label="To Date"
                inputFormat="MM/DD/YYYY"
                value={toDate}
                onChange={(newValue) => {
                  setToDate(newValue);
                }}
                renderInput={(params) => (
                  <TextField size="small" sx={{ mb: "0.6rem" }} {...params} />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={3} md={3} lg={3}>
          <Button
              onClick={() => appylyFilter()}
              variant="contained"
              sx={{ color: "white", mr: "1rem" }}
              title="Apply Filter"
            >
              <CheckCircleOutlineIcon />
            </Button>

            {/* <Button
              onClick={() => resetFilter()}
              variant="contained"
              sx={{ color: "white", mr: "1rem" }}
              title="Reset Filter"
            >
              <HighlightOff />
            </Button> */}
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={8} md={8} lg={8}>
            <Paper
              sx={{
                background: "#f7ede8",
                pt: "1rem",
                width: "100%",
                overflowX: "auto",
                overflowY: "hidden",
              }}
            >
              {/* <ResponsiveContainer width="100%" height="100%"> */}
              <BarChart
                width={900}
                height={400}
                data={graphData}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="Budget" fill="#C1AFA3" />
                <Bar dataKey="Actual" fill="#ECAF91" />
              </BarChart>
            </Paper>
          </Grid>
          <Grid item xs={4} md={4} lg={4}>
            <Paper sx={{ background: "#f7ede8" }}>
              {/* </ResponsiveContainer> */}
              <Box>
                <TableContainer>
                  <Table sx={{ width: "100%" }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ fontWeight: 900 }}>Title</TableCell>
                        <TableCell
                          align="center"
                          sx={{ fontWeight: 900, background: "#C1AFA3" }}
                        >
                          Budget
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{ fontWeight: 900, background: "#ECAF91" }}
                        >
                          Actual
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {graphDetail?.map((row) => (
                        <TableRow
                          key={row.category}
                      
                        >
                          <TableCell component="th" scope="row">
                            <Emoji unified={row.category_icon} size="18" />
                            <span style={{ marginLeft: "0.5rem" }}>
                              {row.category}
                            </span>
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              background: "#C1AFA3",
                              color: "white",
                              fontWeight: "700",
                            }}
                          >
                            ${row.total_actual.toFixed(2)}
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              background: "#ECAF91",
                              color: "white",
                              fontWeight: "700",
                            }}
                          >
                            ${row.total}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Paper>
          </Grid>
        </Grid>

        <Grid container spacing={2} sx={{ mt: "1rem" }}>
          <Grid item xs={6} md={6} lg={6}>
            <Paper sx={{ background: "#f7ede8", pt: "1rem" }}>
              <Typography sx={{ fontWeight: "bold", pl: "1rem" }}>
                Budget Breakdown
              </Typography>
              {pieBudgetData ? (
                <BudgetPieChart graphDataAll={pieBudgetData} />
              ) : (
                ""
              )}
            </Paper>
          </Grid>

          <Grid item xs={6} md={6} lg={6}>
            <Paper sx={{ background: "#f7ede8", pt: "1rem" }}>
              <Typography sx={{ fontWeight: "bold", pl: "1rem" }}>
                Actual Breakdown
              </Typography>
              {pieActualData ? (
                <BudgetPieChart graphDataAll={pieActualData} />
              ) : (
                ""
              )}
            </Paper>
          </Grid>
        </Grid>

        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: "1.5rem" }}>
          <Button
            onClick={handleClickOpen}
            variant="contained"
            sx={{ color: "white" }}
          >
            Add micro transaction
          </Button>
        </Box>
        <Dialog
          open={open}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
        >{
          addNewCategory ? 
          <Container component="main" maxWidth="xs">
            <BasicModal addSubCategoryFun={addSubCategory}/>
          </Container>
          :
            <Container component="main" maxWidth="xs">
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",

                  zIndex: "-1 !important",
                }}
              >
                <Box
                  component="form"
                  onSubmit={
                    editEnable ? EditSubmit : microTransactionSubmitHandler
                  }
                  noValidate
                  sx={{ mt: "2.5rem" }}
                >
                   {/* <Typography variant='body1' textAlign='center' sx={{color:'red'}}>{formValue.errors?.date}</Typography> */}
                  <LocalizationProvider dateAdapter={AdapterDayjs}  >
                    
                    <MobileDatePicker
                      label={formValue.errors?.date?formValue.errors?.date:"Date"}
                      inputFormat="MM/DD/YYYY"
                      value={expenseDate}
                      minDate={BudgetDate}
                     sx={{color:'white !important'}}
                      onChange={(newValue) => {
                        setExpenseDate(newValue);
                        setformValue({...formValue,errors:''})
                      }}
                      renderInput={(params) => (
                        <TextField fullWidth  {...params} />
                      )}
             
                    />
                  </LocalizationProvider>
                
                    
                 
                  <TextField
                    fullWidth
                    value={formValue.description}
                    onChange={handleChange}
                    name="description"
                    label={formValue.errors?.description?formValue.errors.description:"Description"}
                    variant="outlined"
                   
                    sx={{mt: "0.9rem",color:formValue.errors?.description?'red':'black', 
                    // "& .MuiFormLabel-root.Mui-focused": {
                    //   // color: 'blue',
                    //   // display:'none'
                    //   mt:'-0.6rem',
                    //   background:'transparent'
                    //                                     }
                }}
                  />
                      {/* <Typography variant='body1' textAlign='center' sx={{color:'red'}}>{formValue.errors?.description}</Typography> */}
                  <FormControl required fullWidth margin="normal" >
                    <InputLabel id="category"   sx={{mt:'.5rem',color:formValue.errors?.categoryId?'red':'black',background:'white'}} >{formValue.errors?.categoryId?formValue.errors?.categoryId:"Select Category"}</InputLabel>
                    <Select
                      labelId={"Category"}
                      id="category"
                      name="category"
                      value={categoryId}
                      onChange={categoryHandler}
                      sx={{mt: "0.6rem"}}
                    >
                      {categories?.map((item) => {
                        return (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {/* <Typography variant='body1' textAlign='center' sx={{color:'red'}}>{formValue.errors?.categoryId}</Typography> */}
                  </FormControl>
                  <FormControl required fullWidth margin="normal">
                    <InputLabel id="subCategory " sx={{mt:'.5rem',background:'white', color:formValue.errors?.subCategoryId?'red':'black'}}>{formValue.errors?.subCategoryId?formValue.errors?.subCategoryId:"Select SubCategory"}</InputLabel>
                    <Select
                    // labelId={formValue.errors?.subCategoryId?formValue.errors?.subCategoryId:"subCategory"}
                      id="subCategory"
                      name="subCategory"
                      value={subCategoryId}
                      onChange={subCategoryHandler}
                      sx={{mt: "0.6rem",color:formValue.errors?.subCategoryId?'red':'black'}}
                    >
                      {categoryId ? (
                        <MenuItem
                          sx={{
                            background: "lightGray",
                            color: "#F45714",
                            zIndex: "0",
                            "&:hover": {
                              background: "#F45714",
                              color: "white",
                            },
                          }}
                          onClick={() => {
                            handleNewSubCategory()

                          }}
                        >
                          Add New Category
                        </MenuItem>
                      ) : null}
                      {subCategories.map((item) => {
                        return (
                          <MenuItem key={item.id} value={item.id}>
                            {item.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
               

                    {/* <Typography variant='body1' textAlign='center' sx={{color:'red'}}>{formValue.errors?.subCategoryId}</Typography> */}
                  </FormControl>
                  
                  <TextField
          
                    fullWidth
                    id='quantityInput'
                    value={formValue.quantity}
                    onChange={handleChange}
                    name="quantity"
                    label={formValue.errors?.quantity?formValue.errors?.quantity:"Quantity"}
                    variant="outlined"
                    sx={{ mt: "0.6rem" }}
                    
                  />
                      {/* <Typography variant='body1' textAlign='center' sx={{color:'red'}}>{formValue.errors?.quantity}</Typography> */}
                  <TextField
                    fullWidth
                    value={formValue.unit_price}
                    onChange={handleChange}
                    name="unit_price"
                    label={formValue.errors?.unitprice?formValue.errors?.unitprice:"Unit Price $0.0"}
                    variant="outlined"
                    sx={{ mt: "0.6rem",background:formValue.errors?.unitprice?'red':'white' }}
                  />
    {/* <Typography variant='body1' textAlign='center' sx={{color:'red'}}>{formValue.errors?.unitprice}</Typography> */}
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      my: "1rem",
                    }}
                  >
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{ color: "white" }}
                    >
                      add
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Container>
          }
        </Dialog>
        <Paper sx={{ width: "100%", overflow: "hidden", mt: "1.4rem" }}>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Date</StyledTableCell>
                  <StyledTableCell align="center">Description</StyledTableCell>
                  <StyledTableCell align="center">Category</StyledTableCell>
                  <StyledTableCell align="center">Sub Category</StyledTableCell>
                  <StyledTableCell align="center">Quantity</StyledTableCell>
                  <StyledTableCell align="center">Unit Cost</StyledTableCell>
                  <StyledTableCell align="center">
                    Total Ammount
                  </StyledTableCell>

                  <StyledTableCell align="center">Actions</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {budgetDetails
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow key={row.id}    sx={{ "&:nth-of-type(even)": { background: "lightgray !important" } }}>
                        <TableCell component="th" scope="row">
                          {row.expense_date}
                        </TableCell>
                        <TableCell align="center">{row.description}</TableCell>
                        <TableCell align="center">
                          {row.category != null ? row.category.name : ""}
                        </TableCell>
                        <TableCell align="center">
                          {row.sub_category ? row.sub_category.name : "--"}
                        </TableCell>
                        <TableCell align="center">{row.quantity}</TableCell>
                        <TableCell align="center">{row.unit_price}$</TableCell>
                        <TableCell align="center">{row.total_price}$</TableCell>

                        <TableCell align="center">
                          <Delete
                            sx={{
                              "&:hover": {
                                color: "red",
                                cursor: "pointer",
                              },
                            }}
                            onClick={() => deleteShowBox(row.id)}
                          />
                          <Edit
                            sx={{
                              "&:hover": {
                                color: "green",
                                cursor: "pointer",
                              },
                            }}
                            onClick={() => EditFieldFunc(row.id)}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={budgetDetails.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </Paper>
        <WeeklyBudgetTable/>
      </Container>
    </Layout>
  );
};
