import React, { useState, useEffect } from "react";
import Layout from "../Layout";
import {
  Container,
  Grid,
  Button,
  Box,
  Dialog,
  TextField,
  Typography,
} from "@mui/material";
import { BudgetPlannerTable } from "./BudgetPlannerTable";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import Autocomplete from "react-google-autocomplete";
import {
  addBudget,
  getBudget,
} from "../../../../api-services/userServices/budgetServices";
import { BudgetPlanSnapshot } from "./BudgetPlanSnapshot";

import { deleteBudgetPlan } from "../../../../api-services/userServices/budgetServices";
import { Link } from "react-router-dom";
export const BudgetPlanner = () => {
  const [open, setOpen] = useState(false);
  const [editEnable, setEditEnable] = useState(false);
  const [place, setPlace] = useState();
  const [formValue, setformValue] = useState({
    title: "",
    travelBudget: "",
    destination: "",
  });
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [Budgets, setBudgets] = useState([]);
  const [tableData, setTabelData] = useState(false);

  const [editId, setEditId] = useState(null);
  const handleChange = (event) => {
    setformValue({
      ...formValue,
      [event.target.name]: event.target.value,
    });
  };

  const handleClickOpen = (id) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getBudgetData = async () => {
    const response = await getBudget();
    setBudgets(response.data.data);
    setTabelData(response.data.success);
  };
  const budgetSubmitHandler = async (e) => {
    e.preventDefault();
    const budgetFromData = new FormData();
    budgetFromData.append("title", formValue.title);
    budgetFromData.append("from_date", dayjs(startDate).format("YYYY-MM-DD"));
    budgetFromData.append("to_date", dayjs(endDate).format("YYYY-MM-DD"));
    budgetFromData.append("destination", formValue.destination);
    budgetFromData.append("target_budget", formValue.travelBudget);
    budgetFromData.append("destination", place);

    const response = addBudget(budgetFromData);
    localStorage.setItem("budgetAddDate", startDate);
    response.then((res) => {
      if (res.data.success === true) {
        setOpen(false);
        getBudgetData();
      }
    });
    setformValue({ [e.target.name]: "" });
    setStartDate("");
    setEndDate("");
  };

  useEffect(() => {
    getBudgetData();
  }, []);
  const DeleteFunc = async (id) => {
    let newBudgets = Budgets.filter((data) => data.id !== id);

    setBudgets(newBudgets);
    const res = await deleteBudgetPlan(id);
  };
  const EditFunc = (id) => {
    setEditId(id);
    let editRowValue = Budgets.filter((data) => data.id === id);

    let editData = {
      title: editRowValue[0].title,
      travelBudget: editRowValue[0].target_budget,
      destination: editRowValue[0].destination,
    };
    setformValue(editData);
    setStartDate(editRowValue[0].from_date);
    setEndDate(editRowValue[0].to_date);

    setOpen(true);
    setEditEnable(true);
  };
  const EditFieldFunc = (e) => {
    e.preventDefault();
    const budgetFromData = new FormData();
    budgetFromData.append("title", formValue.title);
    budgetFromData.append("from_date", dayjs(startDate).format("YYYY-MM-DD"));
    budgetFromData.append("to_date", dayjs(endDate).format("YYYY-MM-DD"));
    budgetFromData.append("destination", formValue.destination);
    budgetFromData.append("target_budget", formValue.travelBudget);
    budgetFromData.append("edit_id", editId);

    const response = addBudget(budgetFromData);
    response.then((res) => {
      if (res.data.success === true) {
        setOpen(false);
        getBudgetData();
        let editData = {
          title: " ",
          travelBudget: " ",
          destination: " ",
        };
        setformValue(editData);
        setStartDate(" ");
        setEndDate(" ");
        setEditId(null);
      }
    });
  };

  return (
    <Layout name="Budget planner">
      <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
        <BudgetPlanSnapshot />
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant="h5" sx={{ color: "#ff7043", mt: "1rem" }}>
                Budget Planner
              </Typography>
              {/* <Button
                onClick={handleClickOpen}
                variant="contained"
                sx={{ color: "white", mt: "1rem" }}
              >
                add Budget
              </Button> */}

              <Link
                to="/auth/budgetplan"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <Button variant="contained" sx={{ color: "white", mt: "1rem" }}>
                  Add Budget Plan
                </Button>
              </Link>
            </Box>
            <BudgetPlannerTable
              Budgets={Budgets}
              deleteFun={DeleteFunc}
              editFun={EditFunc}
              tableDataSuccess={tableData}
            />
            <Dialog
              open={open}
              keepMounted
              onClose={handleClose}
              aria-describedby="alert-dialog-slide-description"
            >
              <Container component="main" maxWidth="xs">
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box
                    component="form"
                    onSubmit={editEnable ? EditFieldFunc : budgetSubmitHandler}
                    noValidate
                    sx={{ mt: "2.5rem" }}
                  >
                    <TextField
                      fullWidth
                      value={formValue.title}
                      onChange={handleChange}
                      name="title"
                      label="Title"
                      variant="outlined"
                    />
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <MobileDatePicker
                        label="Start Date"
                        inputFormat="MM/DD/YYYY"
                        minDate={new Date()}
                        value={startDate}
                        onChange={(newValue) => {
                          setStartDate(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            sx={{ mt: "0.6rem" }}
                            {...params}
                          />
                        )}
                      />
                      <MobileDatePicker
                        label="End Date"
                        inputFormat="MM/DD/YYYY"
                        minDate={startDate}
                        value={endDate}
                        onChange={(newValue) => {
                          setEndDate(newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            sx={{ mt: "0.6rem" }}
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                    {/* destination map */}
                    <Autocomplete
                      style={{
                        height: " 52px",
                        borderRadius: "5px",
                        padding: " 0px 10px",
                        width: " 100%",
                        marginTop: "0.6rem",
                        border: "1px solid #F45714",
                        color: "#F45714",
                        outline: "#F45714",
                      }}
                      onPlaceSelected={(place) => {
                        setPlace(JSON.stringify(place));
                      }}
                    />

                    <TextField
                      fullWidth
                      value={formValue.travelBudget}
                      onChange={handleChange}
                      name="travelBudget"
                      label="travel Budget $0.0"
                      variant="outlined"
                      sx={{ mt: "0.6rem" }}
                    />
                    <Box sx={{ position: "relative" }}></Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        my: "1rem",
                      }}
                    >
                      <Button
                        type="submit"
                        variant="contained"
                        sx={{ color: "white" }}
                      >
                        ADD
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Container>
            </Dialog>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
};
