import * as React from "react";
import { useTheme } from "@mui/material/styles";
import {Link,Typography} from '@mui/material'
import Title from './Title';
export default function Chart({totalBudget}) {
  const theme = useTheme();

  return (
    <React.Fragment>
         <Title >Total Budgets Plan</Title>
      <Typography component="p" variant="h4" textAlign='center'>
        {totalBudget}
      </Typography>
      
    </React.Fragment>
  );
}
