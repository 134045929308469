import {useState,forwardRef} from "react"
import {Button,Dialog,Slide,Container,Typography,Avatar,CssBaseline,TextField,FormControlLabel,Checkbox,Link,Grid,Box,CircularProgress} from "@mui/material"
import LockOutlinedIcon from "@mui/icons-material/LockOutlined"
import AuthService from "../../api-services/authService"
import { useNavigate } from "react-router-dom"
import HomePage from "../../pages/home"
function Copyright(props) {
  return (
    <Typography
      variant='body2'
      color='text.secondary'
      align='center'
      {...props}
    >
      {"Copyright © "}
      <Link color='inherit' href='https://mui.com/'>
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  )
}

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction='down' ref={ref} {...props} />
})

export const Login = () => {
  const [open, setOpen] = useState(false)
  const[isLoading,setIsLoading]=useState(false)
 const[isError,setIsError]=useState()
 const[email,setEmail]=useState()
 const[password,setPassword]=useState()
 const [formsValues, setFormValues] = useState({
  email: "",
  password: "",
});
 const navigate = useNavigate()
  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }
  const handleSubmit = async (event) => {
    event.preventDefault()
if(!email){
  setFormValues({email:'Field requried'})
}else
if(!password){
  setFormValues({password:'Field requried'})
  
}else{
  

    setIsLoading(true)
    const data = new FormData(event.currentTarget)
    let temp = await AuthService.login(data)
    setIsLoading(temp.success)
    if (temp.success) {
      localStorage.setItem("user", JSON.stringify(temp.data.user))
      localStorage.setItem("token", temp.data.token)
     
      if(temp.data.user.role_id == 1){
        navigate("/auth/adminDashboard")
      }else{
        navigate("/home")
       
      }
    }else{
      setIsError('Please check Your email & password')
    }}
  }

  return (
    <>
      <Button
        onClick={handleClickOpen}
        sx={{
          color: "primary.main",
          fontSize: { xs: "0.85rem", md: "1rem" },

        }}
      >
        Login
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby='alert-dialog-slide-description'
      >
        <Container component='main' maxWidth='xs'>
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography
              component='h1'
              variant='h5'
              sx={{ color: "text.secondary" }}
            >
              Sign in
            </Typography>
            <Box
              component='form'
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1, mx: "1.5rem" }}
            >
              <TextField
                margin='normal'
                required
                type='email'
                fullWidth
                id='email'
                label='Email Address'
                name='email'
                autoComplete='email'
                autoFocus
                value={email}
                onChange={(e)=>{setEmail(e.target.value)
                  setFormValues({email:''})
                  setIsError('')}}
/>
<Typography variant='body1' textAlign='center' sx={{color:'red'}}>{formsValues.email}</Typography>
              <TextField
                margin='normal'
                required
                fullWidth
                name='password'
                label='Password'
                type='password'
                id='password'
                value={password}
                onChange={(e)=>{setPassword(e.target.value)
                  setFormValues({password:''})
                setIsError('')}}
                autoComplete='current-password'
              />
              <Typography variant='body1' textAlign='center' sx={{color:'red'}}>{formsValues.password}</Typography>
              <Typography variant="body1" textAlign='center' sx={{color:'red'}}>{isError}</Typography>
              <FormControlLabel
                control={<Checkbox value='remember' color='primary' />}
                label='Remember me'
              />
              <Button
                type='submit'
                fullWidth
                variant='contained'
                sx={{ mt: 3, mb: 2 }}
              >
             {isLoading? <CircularProgress sx={{color:'white'}}/> :" Sign In"}
              </Button>
              <Grid container>
                <Grid item xs>
                  <Link href='#' variant='body2'>
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href='#' variant='body2'>
                    Don't have an account? Sign Up
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Copyright sx={{ mt: 8, mb: 4 }} />
        </Container>
      </Dialog>
    </>
  )
}
