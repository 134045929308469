import { AppBar, Toolbar, Typography, Box } from "@mui/material"
import Logo from "../../../images/logo.svg"
import { Login } from "../../authBackDrop"
import { Register } from "../../authBackDrop/register"
import Dropdown from "../../user/dashboard/Dropdown"
import {Link} from 'react-router-dom'
const CreatePlanTopBar = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  return (
    <Box>
      <AppBar
        component='nav'
        sx={{ boxShadow: " -1px 1px 14px 2px rgba(99,96,96,0.16)",width:'100%' }}
      >
        <Toolbar
          sx={{
            background: "white",
          }}
        >
          <Typography
            variant='h6'
            component='div'
            sx={{
              flexGrow: 1,
              fontWeight: 900,
              ml: { sm: "9rem" },
            }}
          >
                 <Link to='/'> 
            <Box
              component='img'
              src={Logo}
              sx={{ width: { xs: 160, sm: 302 },cursor:'pointer' }}
            /></Link>
          </Typography>
          {user?.name ? (
              <Dropdown>
              <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                  component="img"
                  src={user?.image_path}
                  sx={{
                    width: "50px",
                    height: "50px",
                    borderRadius: "50%",
                    cursor: "pointer",
                  }}
                />
                <Typography variant="h6" sx={{ ml: ".5rem",color:'black' }}>
                  {user?.name}
                </Typography>
              
              </Box></Dropdown>
            ) : (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Login />
                <Register />
              </Box>
            )}
        </Toolbar>
      </AppBar>
    </Box>
  )
}

export default CreatePlanTopBar
