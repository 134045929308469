
import { Typography,Box } from "@mui/material"

export const Budget = () => {
  return (
    <Box
      sx={{
        backgroundColor: "rgb(249, 249, 249)",
        width: "10rem",
        borderRadius: "0.5rem",
        borderBottom: 1,
        borderColor: "divider",
      }}
    >
      <Typography
        sx={{
          fontSize: "1.05rem",
          fontWeight: 600,
          pt: "1.4rem",
          ml: "1.2rem",
        }}
      >
        Budgeting
      </Typography>
      <Typography
        sx={{
          fontSize: "1.2rem",
          ml: "1.2rem",
          mt: "0.7rem",
          color: "rgb(128, 128, 128)",
        }}
      >
        0.0{localStorage.getItem("Budget")}$
      </Typography>
    </Box>
  )
}
