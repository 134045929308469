import {useState} from "react";
import Layout from "../Layout";
import Avatar from 'react-avatar-edit'
import {Box, Container, Typography, Button} from "@mui/material"
import { saveUserProfileImage } from '../../../../api-services/adminServices/userService'
import { useNavigate, useParams } from "react-router-dom"

const UpdateImage = () => {
    const loginUser = localStorage.getItem("user");
    const userData=  JSON.parse(loginUser);
    const image = userData.image_path;

    const [preview,setPreview]=useState(image);
    const navigate = useNavigate()
    const [src,setSrc]=useState();
    const onClose = () => {
        setPreview(null)
      }
      
    const onCrop = (preview) => {
        setPreview(preview)
        console.log(preview)
      }
    
    const onBeforeFileLoad = (elem) => {
        
      }
    const saveProfileImage = async () => {
      const userInfoFormData = new FormData()
        userInfoFormData.append("image", preview)
        const response = await saveUserProfileImage(userInfoFormData)

        if (response.data.success === true) {
            if(response.data.data.user){
                localStorage.setItem("user", JSON.stringify(response.data.data.user))
                navigate(`/auth/userProfile`)
            }
            
        }
    }

  return (
    <Layout>
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Box sx={{ display: 'flex' }}>
      <Box sx={{ width: '50%' }}>
        <Avatar
          height={295}
          onCrop={onCrop}
          onClose={onClose}
          onBeforeFileLoad={onBeforeFileLoad}
          src={src}
        />
        </Box>
        <Box sx={{ width: '50%', boxShadow: '0px 0px 5px rgba(0,0,0,0.2)' }}>
          <Typography sx={{ alignItems: 'center', fontWeight: 'bold', display: 'flex', justifyContent: 'center', fontSize: '24px', padding: '10px 0px' }}>Image Preview Here</Typography>
          { preview != null ? <img src={preview} alt="Preview" style={{ display: 'flex', margin: 'auto' }}/> : ''}
        </Box>
      </Box>

      <Box sx={{width: '100%', mt: '2rem'}}>
        <Button variant="contained" onClick={() => saveProfileImage()}>Save</Button>
      </Box>
      </Container>
    </Layout>
  );
};

export default UpdateImage;
