import { useState } from "react";
import Footer from "../../footer";
import Topbar from "../../../pages/home/Topbar";
import { Box,Typography, Grid, Button, TextField,FormControl,NativeSelect,Rating } from "@mui/material";
import Hotel from "./lodgingDropdown/Hotel";
import CheckIn from "./lodgingDropdown/CheckIn";
import dayjs from "dayjs";
import CheckOut from "./lodgingDropdown/CheckOut";
import Rooms from "./lodgingDropdown/Rooms";
import {  Search } from "@mui/icons-material";
import RangeSlider from "./RangeSlider";
import Hero from "./Hero";
import LodgingTop from "./LodgingTop";
export const LodgingPage = () => {
  const [selectHotel, setSelectHotel] = useState();
  const [selectCity, setSelectCity] = useState();
  const [checkIn, setChecIn] = useState(dayjs("2022-04-07"));
  const [checkOut, setCheckOut] = useState(dayjs("2022-05-24"));
  const [Adults, setAdults] = useState(1);
  const [Child, setChild] = useState(0);
  const [value, setValue] = useState(2);
  return (
    <>
      <Topbar />
      {/* //////////////////////////////////////////////////////////// */}
      <Box
        sx={{
          mt: "5rem",
          pt: ".4rem",
          width: "100%",
          background: "primary.900",
          boxShadow:
            " rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset",
          paddingBottom: ".8rem",
          display: "flex",
          justifyContent: "center",
          borderRadius:{xs:'15px',md:'0px'}
        }}
      >
        <Grid container xs={10} xl={9} mt={1}>
          <Grid container  md={3.5}>
            <Grid
              item
              xs={12}
              sx={{
                border: "1px solid #b8c5d3",
                // py: "0.1rem",
                height: "5rem",
                px: { xs: ".2", md: ".5rem" },
                borderRadius: "8px",
                mr: { xs: "0", md: ".4rem" },
                cursor: "pointer",
              }}
            >
              <Hotel
                setSelectCity={setSelectCity}
                setSelectHotel={setSelectHotel}
              >
                <Box sx={{ display: "flex", flexDirection: "column", p:{xs:'10px',md: "0"} }}>
                  <Typography variant="body2" sx={{fontSize:{xs:"12px",md:'14px'}}}>
                    CITY/HOTEL/RESORT/AREA
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{ color: "#503e6c", fontWeight: "bold",fontSize:{xs:'14px',md:'20px' }}}
                  >
                    {selectHotel ? selectHotel : "Nathia Gali"}
                  </Typography>
                  <Typography
                    variant="caption"
                    sx={{
                      fontSize: "12px",
                      textOverflow: "ellipsis",
                      width: "10rem",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    }}
                  >
                    {selectCity ? selectCity : "Pakistan"}
                  </Typography>
                </Box>
              </Hotel>
            </Grid>
          </Grid>
          {/* //////////////////////////////////////////////// */}
          <Grid
            container
            xs={12}
            md={8.5}
            sx={{ mt: { xs: ".5rem", md: "0", } }}
          >
            <Grid
              container
              xs={12}
              md={5}
              sx={{
                border: "1px solid #b8c5d3",
                // py: "0.1rem",
                px: ".5rem",
                borderRadius: "8px",
                mr: ".5rem",

                height: {xs:"4rem",md:'5rem'},
              }}
            >
              <Grid item xs={6} md={6} sx={{ pt:{xs:'.5rem',md: ".3rem"}, cursor: "pointer" }}>
                <CheckIn setChecIn={setChecIn} checkIn={checkIn} />
              </Grid>
              <Grid
                item
                xs={6}
                md={6}
                sx={{
                  pl: ".5rem",
                  pt:{xs:'.5rem',md: ".3rem"},
                  borderLeft: "1px solid #b8c5d3",
                  cursor: "pointer",
                }}
              >
                <CheckOut setCheckOut={setCheckOut} checkOut={checkOut} />
              </Grid>
            </Grid>
            <Grid
              container
              xs={12}
              md={6.5}
              sx={{ mt: { xs: ".5rem", md: "0" } }}
            >
              <Grid
                item
                xs={12}
                md={5.8}
                sx={{
                  border: "1px solid #b8c5d3",
                  p: {xs:'.5rem',md:".3rem"},
                  borderRadius: "8px",
                  height: {xs:"5rem",},
                  cursor: "pointer",
                }}
              >
                <Rooms
                  setAdults={setAdults}
                  Adults={Adults}
                  Child={Child}
                  setChild={setChild}
                >
                  <Typography
                    variant="body2"
                    sx={{ textTransform: "uppercase" }}
                  >
                    ROOMS & GUESTS
                  </Typography>
                  <Typography variant="body2">
                    <b sx={{ fontWeight: "bold", color: "#503e6c" }}>1</b> Room,
                    <b sx={{ fontWeight: "bold", color: "#503e6c" }}>2</b>
                    Guests
                  </Typography>
                  <Typography variant="body2">2 Adults</Typography>
                </Rooms>
              </Grid>
              <Grid
                item
                xs={12}
                md={5.8}
                sx={{
                  border: "1px solid #b8c5d3",
                  p: {xs:'.1rem',md:".4rem"},
                  borderRadius: "8px",
                  height: {md:"5rem",xs:'3rem'},
                  bgcolor: "primary.main",
                  ml: ".6rem",
                  mt:{xs:'.5rem',md:'0'}
                }}
              >
                <Typography
                  variant="h5"
                  textAlign="center"
                  sx={{
                    fontWeight: {xs:'300',md:"bold"},
                    px: "1rem",
                    color: "#ffff",
                    cursor: "pointer",
                    fontSize: "18px",
                    p: {xs:'.5rem',md:"1rem",}
                  }}
                >
                  Modify Search
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          background: "#ebf0f4",
          p:{xs:'0',md: ".4rem"},
        }}
      >
        <Grid container xs={12} xl={9} mt={1} sx={{}}>
          <Grid
          display={{ xs: "none", md: "block" }}
            md={3}
            sx={{
              mr: ".56rem",
              p: ".5rem",
              background:'white',
              boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
            }}
          >
            <Typography
              variant="h6"
              sx={{ fontSize: "17px", py: ".6rem", px: ".2rem" }}
            >
              Destination: 16 properties found
            </Typography>
            <FormControl fullWidth sx={{my:'1rem'}}>
              <NativeSelect
                defaultValue={30}
                inputProps={{
                  name: "age",
                  id: "uncontrolled-native",
                }}
              >
                <option value={20}>Price</option>
                <option value={30}>Popularity</option>
              </NativeSelect>
            </FormControl>
            <Grid mt={2}>
              <Typography
                variant="h6"
                sx={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  color: "#503e6c",
                  pb: ".3rem",
                  borderBottom: "1px solid gray",
                }}
              >
                Filter By
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <TextField variant="standard" label="Property Name" />
                <Search
                  sx={{
                    border: "1px solid gray",
                    mt: ".9rem",
                    p: ".1rem",
                    borderRadius: "5px",
                    bgcolor: "primary.main",
                    color: "white",
                    border: "none",
                    cursor: "pointer",
                    width: "10%",
                    height: "20%",
                  }}
                />
              </Box>
        <Grid mt={2}>
        <Typography varaint='h6' sx={{fontWeight:'bold'}}>Rating</Typography>
              <Rating
        name="simple-controlled"
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      />
        </Grid>
        <Grid sx={{my:'1rem',px:'7px'}}>
        <Typography varaint='h6' sx={{fontWeight:'bold'}}>Price Range</Typography>
        <RangeSlider/>
        </Grid>
            </Grid>
          </Grid>
          <Grid xs={11.7} md={8.78} sx={{ mt: { xs: "1rem",md:'0' } }}>
            {/* /////////////////////////////hero */}
            <LodgingTop/>
          <Hero/>
            {/* /////// hero */}
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ mt: "4rem" }}>
        <Footer />
      </Box>
    </>
  );
};
