import React from 'react'
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import { Marker } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '100vh'
};



// const center = {
//     lat: 0,
//     lng: -180
//   }
  
  

  const onLoad = marker => {
    console.log('marker: ', marker)
  }

function MyComponent(props) {
  const center = {
    lat: props.lat,
    lng: props.lng
  };
  const position = {
    lat: props.lat,
    lng: props.lng
  }
  return (

      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={10}
        options={{
          gestureHandling: "greedy"
      }}
      >
        { /* Child components, such as markers, info windows, etc. */ }
        <Marker
            onLoad={onLoad}
            position={position}
        />
        <></>
      </GoogleMap>
    
  )
}

export default MyComponent;