import Dashboard from "../components/user/dashboard/Dashboard"
import HomePage from "../pages/home"
import { MainTripPage } from "../pages/mianTripPage"
import ErrorPage from "../util/ErrorPage"
import AdminDashboard from "../components/admin/dashboard/Dashboard"
import AdminProfile from '../components/admin/AdminProfile'
import Category from "../components/admin/Categories"
import { Users } from "../components/admin/Users"
import { BudgetPlanner } from "../components/user/dashboard/budgetPlanner/BudgetPlanner"
import { BudgetPlannerChart } from "../components/user/dashboard/budgetPlanner/BudgetPlannerChart"
import SubCategory from "../components/admin/SubCategories"
import UserPofile from "../components/user/dashboard/UserPofile"
import ChangePassword from "../components/user/dashboard/ChangePassword"
import UpdateImage from "../components/user/dashboard/cropImage/UpdateImage"
import AdminUpdateProfile from "../components/admin/AdminUpdateProfile"
import BudgetPlanQuestions from "../components/user/dashboard/budgetPlanner/Questions/BudgetPlanQuestions"

const customRoutes = [{ path: "abc", element: <HomePage /> }]

const publicRoutes = [
  { path: "", element: <MainTripPage /> },
  { path: ":id/:trip_no", element: <MainTripPage /> }
]

const protectedRoutes = [
  {
    path: "pathname here",
    element: "Element here",
    errorElement: <ErrorPage />,
  },
  {
    path: "userDashboard",
    element: <Dashboard />,
    errorElement: <ErrorPage />,
  },
  {
    path: "adminDashboard",
    element: <AdminDashboard />,
    errorElement: <ErrorPage />,
  },
  {
    path:'adminProfile',
    element:<AdminProfile/>,
    errorElement:<ErrorPage/>
  },
  {
    path:'updateAdminProfileImage',
    element:<AdminUpdateProfile/>,
    errorElement:<ErrorPage/>
  },
  {
    path: "admin/categories",
    element: <Category />,
    errorElement: <ErrorPage />,
  },
  {
    path: "admin/users",
    element: <Users />,
    errorElement: <ErrorPage />,
  },
  {
    path: "user/budgetplanner",
    element: <BudgetPlanner />,
    errorElement: <ErrorPage />,
  },
  {
    path: "budgetplannerChart/:id",
    element: <BudgetPlannerChart />,
    errorElement: <ErrorPage />,
  },
  {
    path: "admin/:id/sub-categories",
    element: <SubCategory />,
    errorElement: <ErrorPage />,
  },
  {
    path:"userProfile",
    element:<UserPofile/>,
    errorElement:<ErrorPage/>
  },
  {
    path:'changePassword',
    element:<ChangePassword/>,
    errorElement:<ErrorPage/>
  },
  {
    path:'updateProfileImage',
    element:<UpdateImage/>,
    errorElement:<ErrorPage/>
  },
  {
    path:'budgetplan',
    element:<BudgetPlanQuestions/>,
    errorElement:<ErrorPage/>
  },
 
  // {
  //   path:'trip/:id/:trip_no',
  //   element:<MainTripPage/>,
  //   errorElement:<ErrorPage/>
  // },


]

export { protectedRoutes, publicRoutes, customRoutes }
