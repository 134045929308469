import * as React from "react"
import {Button,Dialog,Slide,Container,Typography,Avatar,CssBaseline,TextField,Link,Alert,Box} from "@mui/material"
import LockOutlinedIcon from "@mui/icons-material/LockOutlined"
import AuthService from "../../api-services/authService"

function Copyright(props) {
  return (
    <Typography
      variant='body2'
      color='text.secondary'
      align='center'
      {...props}
    >
      {"Copyright © "}
      <Link color='inherit' href='https://mui.com/'>
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  )
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='down' ref={ref} {...props} />
})

export const Register = () => {
  const [open, setOpen] = React.useState(false)
  const [errors, setErrors] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }
  const handleSubmit = async (event) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    let temp = await AuthService.register(data)

    if (!temp.success) {
      setErrors(!errors)
    }
  }

  return (
    <React.Fragment>
      <Button
        onClick={handleClickOpen}
        sx={{
          color: "primary.main",
          fontSize: { xs: "0.85rem", md: "1rem" },
        }}
      >
        Signup
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby='alert-dialog-slide-description'
      >
        <Container component='main' maxWidth='xs'>
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component='h1' variant='h5'>
              Sign Up
            </Typography>
            <Box
              component='form'
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 1, mx: "1.5rem" }}
            >
              {errors ? <Alert severity='error'>Errors</Alert> : ""}
              <TextField
                margin='normal'
                required
                fullWidth
                id='full_name'
                label='Full Name'
                name='name'
                autoFocus
              />
              <TextField
                margin='normal'
                required
                fullWidth
                id='email'
                label='Email Address'
                name='email'
                autoComplete='nope'
                autoFocus
              />
              <Button
                type='submit'
                fullWidth
                variant='contained'
                sx={{ mt: 3, mb: 2, bgcolor: "primary.main" }}
              >
                Sign Up
              </Button>
            </Box>
          </Box>
          <Copyright sx={{ mt: 8, mb: 4 }} />
        </Container>
      </Dialog>
    </React.Fragment>
  )
}
