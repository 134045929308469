import React from "react"
import {
  AppBar,
  Toolbar,
  Typography,
  ThemeProvider,
  CssBaseline,
  createMuiTheme,
  Button,
  Box,
  ButtonGroup,
} from "@mui/material"
import useScrollTrigger from "@mui/material/useScrollTrigger"
import { Login } from "../../components/authBackDrop"
import { Register } from "../../components/authBackDrop/register"
import Logo from "../../images/logo.svg"

const ScrollHandler = (props) => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: props.window ? window() : undefined,
  })

  return React.cloneElement(props.children, {
    style: {
      backgroundColor: trigger ? "white" : "transparent",
      color: trigger ? "black" : "black",
      transition: trigger ? "0.3s" : "0.5s",
      boxShadow: trigger ? " -1px 1px 14px 2px rgba(99,96,96,0.16)" : "none",
    },
  })
}

const ScrollToColor01 = (props) => {
  return <ScrollHandler {...props}>{props.children}</ScrollHandler>
}

function StaticTopbar(props) {
  const theme = createMuiTheme()

  return (
    <ThemeProvider>
      <AppBar
        component='nav'
        sx={{
          bgcolor: "white",
          boxShadow: " -1px 1px 14px 2px rgba(99,96,96,0.16)",
        }}
      >
        <Toolbar>
          <Typography
            variant='h6'
            component='div'
            sx={{
              flexGrow: 1,
              fontWeight: 900,
              ml: { sm: "3rem" },
            }}
          >
            <Box
              component='img'
              src={Logo}
              sx={{ width: { xs: 180, sm: 280 } }}
            />
          </Typography>
          {/* <Box sx={{ mr: { sm: "0rem" } }}>
              <AuthBackdrop />
              <Register />
            </Box> */}
        </Toolbar>
      </AppBar>
    </ThemeProvider>
  )
}

export default StaticTopbar
