
import {Dialog,Container,Modal,Box,InputBase,Paper,Typography,Button} from '@mui/material';
import { Emoji } from 'emoji-picker-react';
import EmojiPicker from 'emoji-picker-react';
import {useState} from 'react';


export default function BasicModal(props) {

  const [formValue, setformValue] = useState({
    categoryName: "",
    categoryIcon: "",
    categoryMedia: "",
    id: ""
  })

  const handleChange = (event) => {
    setformValue({
      ...formValue,
      [event.target.name]: event.target.value,
    })
  }

  return (
   
  <Box className='addCategory' sx={{zIndex:'999 !important'}}>
  

   
      <Box 
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: '70%',
      
        }}
      >
        <Box
          component='form'

          noValidate
          sx={{ mt: "2.5rem" }}
        >
          <Paper
            sx={{
              px: "0.3rem",
              display: "flex",
              alignItems: "center",
              width: '100%',
              ml:'15%'
             
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder='Add Category'
              inputProps={{ "aria-label": "Add_category" }}
              name='categoryName'
              value={formValue.categoryName}
              onChange={handleChange}
            />
          </Paper>

          <Paper
            sx={{
              px: "0.3rem",
              display: "flex",
              alignItems: "center",
              width: '100%',
              ml:'15%',
              marginBottom: '1rem',
              marginTop: '1rem',
              justifyContent:'center',
  
            }}
          >
          <Typography>Emoji Select : {formValue.categoryMedia != "" ? 
              <span style={{marginLeft: '0rem'}}>
                  <Emoji unified={formValue.categoryMedia} size="25" /> 
              </span>
                  : 'No Emoji Selected !!!'}
          </Typography>
          </Paper>
          
          <Paper
            sx={{
              // px: "0.3rem",
              display: "flex",
              alignItems: "center",
              width: '100%',
              ml:'15%'
            }}
          >
            <EmojiPicker onEmojiClick={(emojiData, event) => {
              setformValue({
                ...formValue,
                categoryMedia: emojiData.unified,
              })
            }}
          sx={{  width: '100%',}}
            />
          </Paper>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              my: "1rem",
            }}
          >
            <Button
              variant='contained'
              sx={{ color: "white" }}
              onClick={() => {props.addSubCategoryFun(formValue)}}
            >
              add
            </Button>
          </Box>
        </Box>
      </Box>
  
  </Box>
  );
}