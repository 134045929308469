import * as React from "react"
import { styled } from "@mui/material/styles"
import CssBaseline from "@mui/material/CssBaseline"
import MuiDrawer from "@mui/material/Drawer"
import Box from "@mui/material/Box"
import MuiAppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import List from "@mui/material/List"
import Typography from "@mui/material/Typography"
import Divider from "@mui/material/Divider"
import IconButton from "@mui/material/IconButton"
import Badge from "@mui/material/Badge"
import Avatar from "@mui/material/Avatar"
import MenuIcon from "@mui/icons-material/Menu"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import NotificationsIcon from "@mui/icons-material/Notifications"
import { ListItems, mainListItems, profileListItems } from "./listItems"
import Dropdown from "./Dropdown"
import FastRewindIcon from '@mui/icons-material/FastRewind';
import { useNavigate,Link } from "react-router-dom";
import Logo from '../../../images/logo.svg'
const drawerWidth = 240

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}))

function Layout(props) {
  const [open, setOpen] = React.useState(true)
  const navigate=useNavigate()
  const toggleDrawer = () => {
    setOpen(!open)
  }
 const loginUser=localStorage.getItem('user')

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position='absolute' open={open}>
        <Toolbar
          sx={{
            pr: "24px", // keep right padding when drawer closed
          }}
        >
          <IconButton
            edge='start'
            color='inherit'
            aria-label='open drawer'
            onClick={toggleDrawer}
            sx={{
              marginRight: "36px",
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon sx={{ color: "white" }} />
          </IconButton>
          <Typography
            component='h1'
            variant='h6'
            color='white'
            noWrap
            sx={{ flexGrow: 1,display:'flex',alignItems:'center' }}
          >
            <FastRewindIcon sx={{mr:{md:'1rem',cursor:'pointer'}}} onClick={()=>navigate(-1)}/>
            {props.name}
            
            <Link
              to="/"
              variant="body1"
              style={{
                marginLeft: "2rem",
                textDecoration: "none",
                color: "white",
                cursor: "pointer",
              }}
            >
              Home
            </Link>
          </Typography>
          <IconButton color='inherit' sx={{ mr: "2rem" }}>
            <Badge badgeContent={4} color='secondary' >
              <NotificationsIcon />
            </Badge >
          </IconButton>
          <Dropdown name={JSON.parse(loginUser).name}>
            <IconButton color='inherit'>
              <Avatar />
            </IconButton>
            <IconButton color='inherit'>
              <Typography
                component='h1'
                variant='h6'
                color='white'
                noWrap
                sx={{ flexGrow: 1 }}
              >
                
                 
                {/* {JSON.parse(loginUser).name} */}
              </Typography>
            </IconButton>
            </Dropdown>

        </Toolbar>
      </AppBar>
      <Drawer variant='permanent' open={open}>
        <Toolbar
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            px: [1],
          }}
        >
     <Link to='/'>     <Box component='img' src={Logo} sx={{width:'180px',m:'0',p:'0'}}/></Link>
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />

          </IconButton>
        </Toolbar>
        <Divider />
        <List
          component='nav'
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <ListItems />
        </List>
      </Drawer>
      <Box
        component='main'
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <Toolbar />
        {props.children}
      </Box>
    </Box>
  )
}

export default Layout
