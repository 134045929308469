import { useState } from "react";

import { ListItemText, ListItemIcon, ListItemButton } from "@mui/material";

import { useNavigate, useLocation, Link } from "react-router-dom";
import { Emoji } from "emoji-picker-react";

export const ListItems = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
  };
  const getColor = (curr) => {
    if (location.pathname === curr) {
      return {
        background: "#F45714",
        color: "white",
      };
    }
  };


  return (
    <>
      <div>
        <Link
          to="/auth/userDashboard"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <ListItemButton
            sx={{
              backgroundColor: getColor("/auth/userDashboard")?.background,
              color: getColor("/auth/userDashboard")?.color,
             
              "&:hover": {
                background: "#ff7041",
                opacity: "0.8",
                color: "white",
              },
            }}
          >
            <ListItemIcon
           
            >
              <Emoji unified="1f4ca" size="24" />
            </ListItemIcon>
            <ListItemText primary="Dashboard" sx={{ fontWeight: "bold" }} />
          </ListItemButton>
        </Link>

        <Link
          to="/userFlights"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <ListItemButton
            sx={{
              backgroundColor: getColor("/userFlights")?.background,
              color: getColor("/userFlights")?.color,
              "&:hover": {
                background: "#ff7041",
                opacity: "0.8",
                color: "white",
              },
            }}
          >
            <ListItemIcon
              sx={{
                color: getColor("/userFlights")?.background
                  ? getColor("/userFlights")?.color
                  : "gray",
              }}
            >
              <Emoji unified="2708-fe0f" size="24" />
            </ListItemIcon>
            <ListItemText primary="Flights" />
          </ListItemButton>
        </Link>

        <Link
          to="/userLodging"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <ListItemButton
            sx={{
              backgroundColor: getColor("/userLodging")?.background,
              color: getColor("/userLodging")?.color,
              "&:hover": {
                background: "#ff7041",
                opacity: "0.8",
                color: "white",
              },
            }}
          >
            <ListItemIcon
              sx={{
                color: getColor("/userLodging")?.background
                  ? getColor("/userLodging")?.color
                  : "gray",
              }}
            >
              <Emoji unified="1f6cc" size="24" />
            </ListItemIcon>
            <ListItemText primary="Lodging" />
          </ListItemButton>
        </Link>

        <Link
          to="/userTransportation"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <ListItemButton
            sx={{
              backgroundColor: getColor("/userTransportation")?.background,
              color: getColor("/userTransportation")?.color,
              "&:hover": {
                background: "#ff7041",
                opacity: "0.8",
                color: "white",
              },
            }}
          >
            <ListItemIcon
              sx={{
                color: getColor("/userTransportation")?.background
                  ? getColor("/userTransportation")?.color
                  : "gray",
              }}
            >
              <Emoji unified="1f686" size="24" />
            </ListItemIcon>
            <ListItemText primary="Transportation" />
          </ListItemButton>
        </Link>

        <Link
          to="/userSightSeeing"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <ListItemButton
            sx={{
              backgroundColor: getColor("/userSightSeeing")?.background,
              color: getColor("/userSightSeeing")?.color,
              "&:hover": {
                background: "#ff7041",
                opacity: "0.8",
                color: "white",
              },
            }}
          >
            <ListItemIcon
              sx={{
                color: getColor("/userSightSeeing")?.background
                  ? getColor("/userSightSeeing")?.color
                  : "gray",
              }}
            >
              <Emoji unified="1f52c" size="24" />
            </ListItemIcon>
            <ListItemText primary="Sight Seeing" />
          </ListItemButton>
        </Link>

        <ListItemButton
          sx={{
            backgroundColor: getColor("Sight Seeing")?.background,
            color: getColor("Sight Seeing")?.color,
            "&:hover": {
              background: "#ff7041",
              opacity: "0.8",
              color: "white",
            },
          }}
        >
          <ListItemIcon>
            <Emoji unified="1f5d3-fe0f" size="24" />
          </ListItemIcon>
          <ListItemText primary="Plan Your Trip" />
        </ListItemButton>
        <Link
          to="/auth/user/budgetplanner"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <ListItemButton
            sx={{
              backgroundColor: getColor("/auth/user/budgetplanner")?.background,
              color: getColor("/auth/user/budgetplanner")?.color,
              "&:hover": {
                background: "#ff7041",
                opacity: "0.8",
                color: "white",
              },
            }}
          >
            <ListItemIcon
              sx={{
                color: getColor("/auth/user/budgetplanner")?.background
                  ? getColor("/auth/user/budgetplanner")?.color
                  : "gray",
              }}
            >
              <Emoji unified="1f4b0" size="24" />
            </ListItemIcon>
            <ListItemText primary="Budget Planner" />
          </ListItemButton>
        </Link>
      </div>
      <div>
        <Link
          to="/auth/userProfile"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <ListItemButton
            sx={{
              backgroundColor: getColor("/auth/userProfile")?.background,
              color: getColor("/auth/userProfile")?.color,
              "&:hover": {
                background: "#ff7041",
                opacity: "0.8",
                color: "white",
              },
            }}
          >
            <ListItemIcon
              sx={{
                color: getColor("/auth/userProfile")?.background
                  ? getColor("/auth/userProfile")?.color
                  : "gray",
              }}
            >
              <Emoji unified="1f464" size="24" />
            </ListItemIcon>
            <ListItemText primary="Profile" />
          </ListItemButton>
        </Link>
        <ListItemButton
          sx={{
            backgroundColor: getColor("Profile")?.background,
            color: getColor("Profile")?.color,
            "&:hover": {
              background: "#ff7041",
              opacity: "0.8",
              color: "white",
            },
          }}
        >
          <ListItemIcon
            sx={{
              color: getColor("/setting")?.background
                ? getColor("/setting")?.color
                : "gray",
            }}
          >
            <Emoji unified="1f5c3-fe0f" size="24" />
          </ListItemIcon>
          <ListItemText primary="Setting" />
        </ListItemButton>
        <ListItemButton
          sx={{
            backgroundColor: getColor("Setting")?.background,
            color: getColor("Setting")?.color,
            "&:hover": {
              background: "#ff7041",
              opacity: "0.8",
              color: "white",
            },
          }}
        >
          <ListItemIcon
            sx={{
              color: getColor("Setting")?.background
                ? getColor("Setting")?.color
                : "gray",
            }}
          >
            <Emoji unified="1f381" size="24" />
          </ListItemIcon>
          <ListItemText primary="Affiliate" />
        </ListItemButton>
        <ListItemButton
          onClick={handleLogout}
          sx={{
            "&:hover": {
              background: "#ff7041",
              opacity: "0.8",
              color: "white",
            },
          }}
        >
          <ListItemIcon
            sx={{
              "&:hover": {
                color: "white",
              },
            }}
          >
            <Emoji unified="1f500" size="24" />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItemButton>
      </div>
    </>
  );
};
