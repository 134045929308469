import { useState } from "react";
import Footer from "../../footer";
import Topbar from "../../../pages/home/Topbar";
import {
  Box,
  Container,
  Radio,
  RadioGroup,
  Grid,
  FormControlLabel,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { SyncAlt } from "@mui/icons-material";
import TicketCards from "./TicketCards";
import FromDropdown from "./dropdown/FromDropdown";
import ToDropdown from "./dropdown/ToDropdown";
import JourneyDate from "./dropdown/JourneyDate";
import dayjs from "dayjs";
import ReturnDate from "./dropdown/ReturnDate";
import TravelClass from "./dropdown/TravelClass";
export const FlightPage = () => {
  const [placeSelect, setPlaceSelected] = useState();
  const [airport, setAirport] = useState();
  const [placeSelectTo, setPlaceSelectedTo] = useState();
  const [airportTo, setAirportTo] = useState();
  const [startDate, setStartDate] = useState(dayjs(''));
  const [returnDate, setReturnDate] = useState(dayjs(''));
  const [Adults, setAdults] = useState(1);
  const [Child, setChild] = useState(0);
  const [Infant, setInfant] = useState(0);

  const ChangeAirport = () => {
    setPlaceSelected(placeSelectTo);
    setPlaceSelectedTo(placeSelect);
    setAirport(airportTo);
    setAirportTo(airport);
  };

  return (
    <>
      <Topbar />
      <Box
        sx={{
          mt: "4rem",
          pt: ".4rem",
          width: "100%",
          background: "primary.900",
          boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
          paddingBottom: ".8rem",
        }}
      >
        <Container sx={{ mt: "0rem" }}>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            sx={{
              color: "#b8c5d3 !important",
            }}
          >
            <FormControlLabel
              value="onway"
              control={
                <Radio
                  sx={{
                    "&.Mui-checked": {
                      "&, & + .MuiFormControlLabel-label": {
                        color: "orangered ",
                        fontSize: "1rem",
                        fontWeight: "bold",
                      },
                    },
                  }}
                />
              }
              label="One Way"
            />
            <FormControlLabel
              value="roundway"
              control={
                <Radio
                  sx={{
                    "&.Mui-checked": {
                      "&, & + .MuiFormControlLabel-label": {
                        color: "orangered ",
                        fontSize: "1rem",
                        fontWeight: "bold",
                      },
                    },
                  }}
                />
              }
              label="Round Way"
            />
            <FormControlLabel
              value="multicity"
              control={
                <Radio
                  sx={{
                    "&.Mui-checked": {
                      "&, & + .MuiFormControlLabel-label": {
                        color: "orangered ",
                        fontSize: "1rem",
                        fontWeight: "bold",
                      },
                    },
                  }}
                />
              }
              label="Multi City"
            />
          </RadioGroup>
          {/* ////////////// */}
          {/* //////////////////////////////////////////////////////////// */}

          {/* //////////////////////////////////////////////////////////// */}
          <Grid container mt={1}>
            <Grid container xs={12} md={5} position="relative">
              <Box
                sx={{
                  position: "absolute",
                  top: "30%",
                  left: { xs: "44%", md: "46%" },
                  background: "white",
                  width: "30px",
                  height: "30px",
                  borderRadius: "50%",
                  border: "1px solid gray",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
                onClick={ChangeAirport}
              >
                <SyncAlt />
              </Box>

              <Grid
                item
                xs={6}
                md={5.8}
                sx={{
                  border: "1px solid #b8c5d3",
                  // py: "0.1rem",
                  height: "5rem",
                  px: { xs: ".2", md: ".5rem" },
                  borderRadius: "8px",
                  mr: { xs: "0", md: ".4rem" },
                  cursor: "pointer",
                }}
              >
                <FromDropdown
                  setAirport={setAirport}
                  setPlaceSelected={setPlaceSelected}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      p: { xs: "5px 10px", md: "0" },
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{ fontSize: { xs: "13px", md: "14px" } }}
                    >
                      From
                    </Typography>
                    {placeSelect ? (
                      <Typography
                        variant="h6"
                        sx={{
                          color: "#503e6c",
                          fontWeight: "bold",
                          fontSize: { xs: "18px", md: "22px" },
                        }}
                      >
                        {placeSelect}
                      </Typography>
                    ) : (
                      <span style={{ color: "#adb1b5", fontWeight: "bold" }}>
                        London
                      </span>
                    )}
                    {airport ? (
                      <Typography
                        variant="caption"
                        sx={{
                          fontSize: "12px",
                          textOverflow: "ellipsis",
                          width: {xs:'7rem',md:"10rem"},
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          '&:hover':{
                            overflow:'visible'
                          }
                        }}
                      >
                        {airport}
                      </Typography>
                    ) : (
                      <span style={{ color: "#adb1b5" }}>washington</span>
                    )}
                  </Box>
                </FromDropdown>
              </Grid>

              {/* ///////////////// */}
              <Grid
                item
                xs={6}
                md={5.8}
                sx={{
                  border: "1px solid #b8c5d3",
                  py: "0.1rem",
                  px: { xs: ".2", md: ".5rem" },
                  borderRadius: "8px",
                  mr: "0",
                }}
              >
                <ToDropdown
                  setAirportTo={setAirportTo}
                  setPlaceSelectedTo={setPlaceSelectedTo}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      p: "0",
                      pl: "1rem",
                      cursor: "pointer",
                    }}
                  >
                    <Typography variant="body2">To</Typography>
                    {placeSelectTo ? (
                      <Typography
                        variant="h6"
                        sx={{
                          color: "#503e6c",
                          fontWeight: "bold",
                          fontSize: { xs: "18px", md: "22px" },
                        }}
                      >
                        {placeSelectTo}
                      </Typography>
                    ) : (
                      <span style={{ color: "#adb1b5", fontWeight: "bold" }}>
                        Islamabad
                      </span>
                    )}

                    {airportTo ? (
                      <Typography
                        variant="caption"
                        sx={{
                          fontSize: "12px",
                          textOverflow: "ellipsis",
                          width: {xs:'7rem',md:"10rem"},
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          '&:hover':{
                            overflow:'visible'
                          }
                        }}
                      >
                        {airportTo}
                      </Typography>
                    ) : (
                      <span style={{ color: "#adb1b5" }}>Pakistan </span>
                    )}
                  </Box>
                </ToDropdown>
              </Grid>
            </Grid>
            {/* //////////////////////////////////////////////// */}
            <Grid
              container
              xs={12}
              md={7}
              sx={{ mt: { xs: ".5rem", md: "0" } }}
            >
              <Grid
                container
                xs={12}
                md={5}
                sx={{
                  border: "1px solid #b8c5d3",
                  // py: "0.1rem",
                  px: ".5rem",
                  borderRadius: "8px",
                  mr: ".5rem",

                  height: { xs: "3.5rem", md: "5rem" },
                }}
              >
                {/* <JourneyDate/> */}
                <Grid
                  item
                  xs={6}
                  md={5.2}
                  sx={{ pt: ".3rem", cursor: "pointer" }}
                >
                  <JourneyDate
                    setStartDate={setStartDate}
                    startDate={startDate}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  md={6.8}
                  sx={{
                    pl: ".5rem",
                    pt: ".3rem",
                    borderLeft: "1px solid #b8c5d3",
                    cursor: "pointer",
                  }}
                >
                  <ReturnDate
                    setReturnDate={setReturnDate}
                    returnDate={returnDate}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                xs={12}
                md={6.5}
                sx={{ mt: { xs: ".5rem", md: "0" } }}
              >
                <Grid
                  item
                  xs={12}
                  md={5.8}
                  sx={{
                    border: "1px solid #b8c5d3",
                    p: { xs: ".4rem", md: ".3rem" },
                    borderRadius: "8px",
                    height: "5rem",
                    cursor: "pointer",
                  }}
                >
                  <TravelClass
                    setAdults={setAdults}
                    Adults={Adults}
                    Child={Child}
                    Infant={Infant}
                    setInfant={setInfant}
                    setChild={setChild}
                  >
                    <Typography
                      variant="body2"
                      sx={{ textTransform: "uppercase" }}
                    >
                      Travel,class
                    </Typography>
                    <Typography
                      variant="h5"
                      sx={{ fontWeight: "bold", color: "#503e6c" }}
                    >
                      {Adults} Traveler
                    </Typography>
                    <Typography variant="body2">Economy</Typography>
                  </TravelClass>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={5.8}
                  sx={{
                    border: "1px solid #b8c5d3",
                    p: ".3rem",
                    borderRadius: "8px",
                    height: { xs: "3rem", md: "5rem" },
                    bgcolor: "primary.main",
                    ml: { xs: "0", md: ".6rem" },
                    mt: { xs: ".5rem", md: 0 },
                  }}
                >
                  <Typography
                    variant="h5"
                    textAlign="center"
                    sx={{
                      fontWeight: { xs: "300", md: "bold" },
                      px: "1rem",
                      color: "#ffff",
                      cursor: "pointer",
                    }}
                  >
                    Modify Search
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <TicketCards />
      <Footer />
    </>
  );
};

// import React from "react"
// import Box from "@mui/material/Box"
// import Grid from "@mui/material/Grid"
// import TextField from "@mui/material/TextField"
// import SwapHorizIcon from "@mui/icons-material/SwapHoriz"
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
// import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker"
// import { Typography, Button } from "@mui/material"
// import Paper from "@mui/material/Paper"
// import MediaControlCard from "../../trip/flight/cards"
// import Topbar from "../../../pages/home/Topbar"
// import Footer from "../../footer"

// export const FlightPage = () => {
//   const [startDate, setStartDate] = React.useState(null)
//   const [endDate, setEndDate] = React.useState(null)
//   return (
//     <>
//       <Topbar />
//       <Box sx={{ mt: "6rem" }}>
//         <Typography
//           style={{
//             textAlign: "center",
//             fontSize: "2rem",
//             fontWeight: 900,
//             // mt: "10rem",
//           }}
//         >
//           Flights To:
//         </Typography>

//         <Paper
//           variant='outlined'
//           sx={{
//             display: "flex",
//             justifyContent: "center",
//             mx: "14rem",
//             py: "1.9rem",
//             bgcolor: "rgb(249, 249, 249)",
//             mt: "3rem",
//           }}
//         >
//           <Box sx={{ display: "flex" }}>
//             <Box
//               sx={{
//                 display: "flex",
//                 justifyContent: "space-around",
//                 alignItems: "center",
//                 width: "24rem",
//               }}
//             >
//               <TextField
//                 id='outlined-basic'
//                 label='Trip From'
//                 variant='outlined'
//               />
//               <SwapHorizIcon />
//               <TextField
//                 id='outlined-basic'
//                 label='Trip To'
//                 variant='outlined'
//               />
//             </Box>
//             <Box
//               sx={{
//                 display: "flex",
//                 justifyContent: "space-around",
//                 alignItems: "center",
//                 width: "24rem",
//               }}
//             >
//               <LocalizationProvider dateAdapter={AdapterDayjs}>
//                 <DesktopDatePicker
//                   label='Start Date'
//                   inputFormat='MM/DD/YYYY'
//                   value={startDate}
//                   onChange={(newValue) => setStartDate(newValue)}
//                   renderInput={(params) => <TextField {...params} />}
//                 />
//               </LocalizationProvider>
//               <Typography sx={{ mx: { xs: "0.6rem", sm: "0rem" } }}>
//                 To
//               </Typography>
//               <LocalizationProvider dateAdapter={AdapterDayjs}>
//                 <DesktopDatePicker
//                   label='End Date'
//                   inputFormat='MM/DD/YYYY'
//                   value={endDate}
//                   onChange={(newValue) => setEndDate(newValue)}
//                   renderInput={(params) => <TextField {...params} />}
//                 />
//               </LocalizationProvider>
//             </Box>
//           </Box>
//           {/* <Button
//           variant='contained'
//           style={{
//             display: "block",
//             margin: "auto",
//             position: "absolute",
//             bottom: "-12%",
//             left: "44%",
//             color: "white",
//           }}
//         >
//           Search
//         </Button> */}
//         </Paper>
//       </Box>
//       <Box sx={{ display: "flex", justifyContent: "center", mt: "3rem" }}>
//         <Box sx={{ display: "flex", flexDirection: "column", width: "31rem" }}>
//           <MediaControlCard />
//           <MediaControlCard />
//           <MediaControlCard />
//         </Box>
//         <Box sx={{ width: "31rem", ml: "1rem" }}>
//           <Box
//             component='img'
//             src='https://appassets.mvtdev.com/map/48/l/662/9141330/2.webp'
//             alt='Map'
//             sx={{ width: "100%" }}
//           />
//         </Box>
//       </Box>
//       <Box sx={{ mt: "4rem" }}>
//         <Footer />
//       </Box>
//     </>
//   )
// }
