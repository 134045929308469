import {useState} from 'react'
import { Grid,Box,Button,Rating,Typography } from "@mui/material"
import { LocationOn,Diversity3, LocalParking, } from '@mui/icons-material';
import { Link } from 'react-router-dom';
const Hero = () => {
    const [value, setValue] = useState(3);
    const cardsData=[
        {
            price:'20500',
            discountPrice:'18500',
            discount:'5',
            image:'https://images.unsplash.com/photo-1517840901100-8179e982acb7?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8aG90ZWx8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60',
            name:'Bellevue luxury apartments',
            address:'Nathiagali,Abbottabad',
            near:'3.4 Km from miranjani trek start point',
           
    },
        {
            price:'25000',
            discountPrice:'23000',
            discount:'3',
            image:'https://images.unsplash.com/photo-1455587734955-081b22074882?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8aG90ZWx8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60',
            name:'Francesca saraco',
            address:'scottland',
            near:'3.4 Km from scottland capital',
           
    },

    ]
  return (
    <>
    {cardsData?.map((data,index)=>(

        <Grid container mt={2} sx={{background:"white",p:'.7rem'}} key={index}>
        <Grid item xs={12} md={4}>
           <Link to={`/lodgingDetail/${index}`}>
           <Box sx={{width:'100%',height:'100%',cursor:'pointer'}} component='img' src={data.image}/>
           </Link>
        </Grid>
        <Grid container xs={12} md={8}  >
          <Grid item xs={12} md={7} sx={{mt:{xs:'.5rem',md:'0'}}}>
            <Box sx={{display:'flex',ml:'.5rem',alignItems:'center'}}>
                <Button variant='outlined'>APartment</Button>
                <Rating
            name="simple-controlled"
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
          />
            </Box>
            <Box sx={{display:'flex',flexDirection:"column",pl:'5px',mt:'4px'}}>
            <Typography variant='h6' sx={{px:'5px', fontWeight:'bold'}}>{data.name}</Typography>
            <Typography variant='body2' sx={{py:'3px',display:'flex',alignItems:'center',color:"#503e6c",textTransform:'capitalize' }}><LocationOn sx={{fontSize:'22px'}}/>{data.address}</Typography>
            <Typography variant='body2' sx={{py:'2px',display:'flex',alignItems:'center',color:"gray",textTransform:'capitalize' ,mt:'2px'}}><LocationOn sx={{fontSize:'20px'}}/> {data.near}</Typography>
        
            </Box>
    
        </Grid>
          <Grid item xs={12} md={5}>
            <Grid>
    <Box sx={{display:'flex',alignItems:'center',ml:{xs:'.8rem',md:'0'}}}>
    <Typography sx={{textDecoration:'line-through',mr:'5px'}}>PKR {data.price}</Typography>
    <Typography variant="caption" sx={{fontSize:'17px',fontWeight:'bold'}}>PKR {data.discountPrice}</Typography>
    
    </Box>
    <Typography variant='body2' sx={{ml:{xs:'.8rem',md:'0'}}}>for 1 Night,per room</Typography>
    <Grid  mt={2}><Button style={{background:'#e0f3f8',width:'60%',fontSize:'17px',fontWeight:'bold',borderRadius:'10px',padding:'5px 6px',textTransform:'capitalize',mt:'1rem'}}>{data.discount} % off</Button></Grid>
            </Grid>
          
          </Grid>
        <Grid container xs={12}md={8} sx={{ml:'5px',mt:{xl:'1rem',md:"0"}}}>
            <Grid xs={12}  md={5} sx={{mt:{xs:'.5rem',md:'0'}}}><button style={{display:'flex',justifyContent:'center',alignItems:'center',border:'0',outline:'none',cursor:'pointer',textTransform:'capitalize',color:'gray'}}><Diversity3 sx={{mr:'4px'}}/> Couple friendly</button></Grid>
            <Grid xs={12} md={7} sx={{mt:{xs:'.5rem',md:'0'}}}><button style={{display:'flex',justifyContent:'center',alignItems:'center',p:'0',m:'0',border:'0',outline:'none',color:'gray',cursor:'pointer',textTransform:'capitalize'}} ><LocalParking style={{p:'0',m:'0'}}/> free large vehicle parking</button></Grid>
         </Grid>
        </Grid>
     
    </Grid>
        ))}
        </>

  )
}

export default Hero