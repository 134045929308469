import * as React from "react"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import DashboardIcon from "@mui/icons-material/Dashboard"
import PeopleIcon from "@mui/icons-material/People"
import { Person, Settings, Redeem, Logout, Category } from "@mui/icons-material"
import { Link, useLocation,useNavigate } from "react-router-dom"

import { Emoji, EmojiStyle } from 'emoji-picker-react';
export const ListItems = () => {
  const navigate = useNavigate()
  const location = useLocation();
  const handleLogout = () => {
    localStorage.clear()
    navigate("/")
  }
  const getColor = (curr) => {
    if (location.pathname === curr) {
      return {
        background: "#F45714",
        color: "white",
      };
    }
  };

  return (
    <>
      <div>
      <Link
          to="/auth/adminDashboard"
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <ListItemButton
            sx={{
              backgroundColor: getColor("/auth/adminDashboard")?.background,
              color: getColor("/auth/adminDashboard")?.color,
              "&:hover": {
                background: "#ff7041",
                opacity: "0.8",
                color: "white",
              },
            }}
          >
            <ListItemIcon
            >
              <Emoji unified="1f4ca" size="24" />
            </ListItemIcon>
            <ListItemText primary="Dashboard" sx={{ fontWeight: "bold" }} />
          </ListItemButton>
        </Link>
       

        <Link
          to='/auth/admin/categories'
         
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <ListItemButton
            sx={{
              backgroundColor: getColor("/auth/admin/categories")?.background,
              color: getColor("/auth/admin/categories")?.color,
              "&:hover": {
                background: "#ff7041",
                opacity: "0.8",
                color: "white",
              },
            }}>
            <ListItemIcon>
              <Category />
            </ListItemIcon>
            <ListItemText primary='Categories' />
          </ListItemButton>
        </Link>
        <Link
          to='/auth/admin/users'
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <ListItemButton  sx={{
            backgroundColor: getColor("/auth/admin/users")?.background,
            color: getColor("/auth/admin/users")?.color,
            "&:hover": {
              background: "#ff7041",
              opacity: "0.8",
              color: "white",
            },
          }}>
            <ListItemIcon>
            <Emoji unified="1f465" size="24"/>
            </ListItemIcon>
            <ListItemText primary='Users' />
          </ListItemButton>
        </Link>
      </div>

      <div>
        <ListItemButton sx={{
            backgroundColor: getColor("/auth/admin/profile")?.background,
            color: getColor("/auth/admin/users")?.color,
            "&:hover": {
              background: "#ff7041",
              opacity: "0.8",
              color: "white",
            },
          }}>
          <ListItemIcon>
          <Emoji unified="1f464" size="24"/>
          </ListItemIcon>
          <ListItemText primary='Profile' />
        </ListItemButton>
        <ListItemButton sx={{
            backgroundColor: getColor("/auth/admin/setting")?.background,
            color: getColor("/auth/admin/users")?.color,
            "&:hover": {
              background: "#ff7041",
              opacity: "0.8",
              color: "white",
            },
          }}>
          <ListItemIcon>
          <Emoji unified="1f5c3-fe0f" size="24"/>
          </ListItemIcon>
          <ListItemText primary='Setting' />
        </ListItemButton>
        <ListItemButton sx={{
            backgroundColor: getColor("/auth/admin/affiliate")?.background,
            color: getColor("/auth/admin/affiliate")?.color,
            "&:hover": {
              background: "#ff7041",
              opacity: "0.8",
              color: "white",
            },
          }}>
          <ListItemIcon>
          <Emoji unified="1f381" size="24"/>
          </ListItemIcon>
          <ListItemText primary='Affiliate' />
        </ListItemButton>
        <ListItemButton onClick={handleLogout} sx={{
            backgroundColor: getColor("/auth/admin/logout")?.background,
            color: getColor("/auth/admin/logout")?.color,
            "&:hover": {
              background: "#ff7041",
              opacity: "0.8",
              color: "white",
            },
          }}>
          <ListItemIcon>
          <Emoji unified="1f500" size="24"/>
          </ListItemIcon>
          <ListItemText primary='Logout' />
        </ListItemButton>
      </div>
    </>
  )
}
