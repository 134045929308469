import React, { useEffect, useRef, useState } from "react";
import {
  Accordion,
  Typography,
  AccordionDetails,
  AccordionSummary,Box,
} from "@mui/material";
import {
  Person,
  PersonAddAlt1,
 
} from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { AccordionDetail } from "./accordionDetail/accordionDetail";

import { useParams } from "react-router-dom";

import { getTopPlaces, getTripDetail } from "../../../api-services/userServices/tripPlanServices";
import Slider from "../../common/Slider";
import axios from "axios";

export const ExploreAccordion = () => {
  const params = useParams();
  const [images, setImages] = useState(null);
const [countryName,setCountryName]=useState()
const [tourDate,setDate]=useState({

})
  const fetchPhotos = async () => {
    const tripPhotos = new FormData();
    tripPhotos.append("id", params.id);

    const response = getTripDetail(tripPhotos);
    response.then((res) => {
      if (res.data.success === true) {
       
        setCountryName(res?.data.data.name)

        setDate({startDate:res.data.data.from_date,endDate:res.data.data.to_date})
    
        let data = JSON.parse(res.data.data?.photos);
        if (data) {
          setImages(data);
        }
      }
    });
  };

  const getPlaces=async()=>{
  

    // let location=new FormData();
    // location.append('location','48.8566,2.3522')
    // const resp=getTopPlaces()
    // resp.then((res)=>{
    //   console.log('new data for places',res)
    // })
  }

  useEffect(() => {
    fetchPhotos();
    getPlaces();
  }, []);
 
 
  return (
    <>
      {/* <Box
        sx={{
          backgroundImage:
            "url(https://img.veenaworld.com/customized-holidays/world/europe/shfr1/banner.jpg)",
          height: 290,
          backgroundSize: "cover ",
          backgroundRepeat: "no-repeat",
        }}
      /> */}

      {images ? <Slider images={images} name={countryName} tourDate={tourDate}/> : null}

          

      <Accordion
        disableGutters
        // elevation={0}
        sx={{
          boxShadow: "none",
          bgcolor: "rgb(249, 249, 249)",
          // border: "none",
          width:'80%',
       m:'0 auto',
          ".MuiAccordion-root": {
            border: "none",
            boxShadow: "none",
          },
        }}
      >
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon sx={{ width: "1.8rem", height: "1.8rem" }} />
          }
          aria-controls="explore-content"
          id="explore-header"
          sx={{ display: "flex", flexDirection: "row-reverse", py: "2rem",width:'100%',}}
        >
          <Typography
            sx={{ fontSize: "1.5rem", fontWeight: 700, ml: "1.9rem" ,}}
          >
            Explore
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          sx={{ display: "flex", justifyContent: "space-evenly" }}
        >
       

          <AccordionDetail />
     
    
          {/* <AccordionDetail />
          <AccordionDetail /> */}
        </AccordionDetails>
      </Accordion>
      {/* <TripDetails /> */}
    </>
  );
};
