import React from "react"
import { List, Grid, Paper } from "@mui/material"
import { styled } from "@mui/material/styles"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemText from "@mui/material/ListItemText"
import Collapse from "@mui/material/Collapse"
import ExpandLess from "@mui/icons-material/ExpandLess"
import ExpandMore from "@mui/icons-material/ExpandMore"

export const SightSeeing = () => {
  const [open, setOpen] = React.useState(true)

  const handleClick = () => {
    setOpen(!open)
  }

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }))
  return (
    <>
      <List
        sx={{
          width: "100%",
          bgcolor: "background.paper",
          paddingBottom: "3rem",
        }}
        component='nav'
        className='explore__data_section'
        aria-labelledby='nested-list-subheader'
      >
        <ListItemButton onClick={handleClick}>
          {open ? (
            <ExpandLess
              style={{
                marginRight: "2rem",
                marginLeft: "1rem",
                width: "2rem",
                height: "2rem",
              }}
            />
          ) : (
            <ExpandMore
              style={{
                marginRight: "2rem",
                marginLeft: "1rem",
                width: "2rem",
                height: "2rem",
              }}
            />
          )}
          <ListItemText
            primary='Places to Visit'
            style={{ fontSize: "24px !important" }}
            className='explore__text'
          />
        </ListItemButton>
        <Collapse in={open} timeout='auto' unmountOnExit>
          <List component='div' disablePadding>
            <ListItemButton sx={{ pl: 4 }}>
              <Grid container spacing={2} sx={{ width: "80%", margin: "auto" }}>
                <Grid item xs={3}>
                  <Item sx={{ p: 0 }}>Hello world</Item>
                </Grid>

                <Grid item xs={3}>
                  <Item sx={{ p: 0 }}>Hello world</Item>
                </Grid>

                <Grid item xs={3}>
                  <Item sx={{ p: 0 }}>Hello world</Item>
                </Grid>

                <Grid item xs={3}>
                  <Item sx={{ p: 0 }}>Hello world</Item>
                </Grid>
              </Grid>
            </ListItemButton>
          </List>
        </Collapse>
      </List>
    </>
  )
}
