import {useState} from "react";
import Layout from "./Layout";
import {
  Typography,
  Box,
  TextField,
  Button,
  Dialog,
  DialogTitle
} from "@mui/material";
import { userChangePassword } from "../../../api-services/adminServices/categoryService";
const ChangePassword = () => {
    const [useChangePass,setUserChangePass]=useState();
    const [newPassword,setNewPassword]=useState();
    const [confirmPassword,setConfirmPassword]=useState()
    const [open,setOpen]=useState(false)



    const HandleChangePass=async(e)=>{
        e.preventDefault()
    const changedPassword = new FormData()
  
    if(newPassword===confirmPassword){
    changedPassword.append("new_password", newPassword)
    let temp = await userChangePassword(changedPassword)
      if(temp.data.success){
        setOpen(true)
      }
    }
    else{
        alert('Password not matched')
    }

    }
  return (
    <Layout>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Box
          component="form"
          noValidate
        onSubmit={HandleChangePass}
          sx={{
            mt: 1,
            mx: "1.5rem",
            width: { xs: "90%", sm: "60%", md: "35%" },

            mt: { xs: "20%", md: "15%" },
          }}
        >
          <Typography variant="h5" sx={{ color: "#F45714" }}>
            Change Password
          </Typography>
          <TextField
            margin="normal"
            required
            type="password"
            fullWidth
            label="New Password"
            autoFocus
            size="small"
            onChange={(e)=>setNewPassword(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            size="small"
            type="password"
            label="Confirm Password"
            onChange={(e)=>setConfirmPassword(e.target.value)}
          />

          <Button
            type="submit"
            fullWidth
            size="small"
            variant="contained"
            sx={{
              mt: 3,
              mb: 2,
              width: { xs: "60%", sm: "35%", md: "30%" },
              ml: { xs: "40%", sm: "65%", md: "70%" },
            }}
          >
            Update
          </Button>
        </Box>

        <Dialog open={open} maxWidth="lg">
          <DialogTitle>Password Updated Successfully !!!</DialogTitle>
          <Box sx={{display: 'flex',justifyContent: 'space-around',paddingBottom:'1rem'}}>
          
              <Box>
                <Button variant="outlined" color="error" onClick={() => setOpen(false)} size="small">
                    Close
                </Button>
              </Box>
          </Box>
        </Dialog>
      </Box>
    </Layout>
  );
};

export default ChangePassword;
