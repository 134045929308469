import * as React from "react";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { Box } from "@mui/material";

export default function ReturnDate(props) {


  return (
    <>
      <Box className="datePicker">
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          sx={{ border: "none", outline: "none" }}
        >
          <Stack spacing={3}>
            <MobileDatePicker
              label="Return Date"
              value={props.returnDate}
              onChange={(newValue) => {
                props.setReturnDate(newValue);
              }}
              renderInput={(params) => (
                <TextField
            
                  variant="standard"
                  {...params}
                  InputProps={{
                    disableUnderline: true, // <== added this
                  }}
               
                />
              )}
            />

          </Stack>
        </LocalizationProvider>
      </Box>
    </>
  );
}
