import * as React from 'react';
import {TextField,Autocomplete,CircularProgress} from '@mui/material';



export default function AutoFetch(props) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0;
  const [value, setValue] = React.useState()

  React.useEffect(() => {
    let active = true;
    setOptions(props.currencies)
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const handleChange = async (e,value) => {
    props.saveCurrencyFun(value);
  }
  return (
    <Autocomplete
      id="currencies"
      sx={{ width: 300 }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      isOptionEqualToValue={(option, value) => option.name === value.name}
      getOptionLabel={(option) => `${option.name} (${option.abbreviation})(${option.symbol})`}
      options={options}
      loading={loading}
      onChange={(event, newValue) => handleChange(event,newValue)}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Currencies"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
