import "./App.css"
import { connect } from "react-redux"
import { Route, Routes } from "react-router-dom"
import RouteMiddleware from "./routes/middleware"
import Layout from "./components/layout"
import { protectedRoutes, publicRoutes } from "./routes"
import { ThemeProvider } from "@mui/material/styles"
import { appTheme } from "./theme"
import { CreatePlan } from "./components/createPlan"
import HomePage from "./pages/home"
import Dashboard from "./components/user/dashboard/Dashboard"
import Flights from "./components/user/dashboard/trip/Flights"
import Lodging from "./components/user/dashboard/trip/Lodging"
import Transportation from "./components/user/dashboard/trip/Transportation"
import Sights from "./components/user/dashboard/trip/Sights"
import { FlightPage } from "./components/my-work-on-tripPage/flight"
import { LodgingPage } from"./components/my-work-on-tripPage/lodging"
// import { Flights } from "./components/trip/flights"
import AdminDashboard from "./components/admin/dashboard/Dashboard"
import Category from "./components/admin/Categories"

import { ConfirmPassword } from "./components/confirmPassword"
import { BudgetPlanner } from "./components/user/dashboard/budgetPlanner/BudgetPlanner"
import { BudgetPlannerChart } from "./components/user/dashboard/budgetPlanner/BudgetPlannerChart";
import { TransportationPage } from "./components/my-work-on-tripPage/transportation/transportation"
import { SightSeeing } from "./components/my-work-on-tripPage/sightseeing"
import TravelGuide from "./components/guide/TravelGuide"
import GuideDashboard from "./components/guide/GuideDashboard"
import Home from "./components/home/Home"
import RoomDetails from "./components/my-work-on-tripPage/lodging/RoomDetails"

const Protect=({children})=>{
  const user=localStorage.getItem('user')
  if(user){
    return children
  }
  return   <HomePage />
}
const App = () => {
 

  return (
    <>
      {/* <ThemeProvider theme={appTheme}> */}
        <Routes>
          {/* {user? :} */}
          <Route
          path="/"
          element={<Protect>
          
            <Home />
          </Protect>}
          />
          {/* <Route exact path='/' element={<HomePage />} />
          // <Route exact path='/home' element={<Home />} /> */}
         <Route exact path='/home' element={<Home />} /> 
          <Route exact path='/createplan' element={<CreatePlan />} />
          <Route exact path='/userFlights' element={<Flights />} />
          <Route exact path='/userLodging' element={<Lodging />} />
          <Route exact path='/travelGuide' element={<TravelGuide />} /> 
          <Route exact path='/guideDashboard' element={<GuideDashboard/>} /> 
          {/* budget planner */}
       
          <Route
            exact
            path='/userTransportation'
            element={<Transportation />}
          />
          <Route exact path='/userSightSeeing' element={<Sights />} />
          {/* <Route exact path='/flights' element={<Flights />} /> */}
          <Route
            exact
            path='/confirmpassword/:id'
            element={<ConfirmPassword />}
          />

          <Route
            exact
            path='/confirmpassword/:id'
            element={<ConfirmPassword />}
          />
          <Route exact path='/flight' element={<FlightPage />} />
          <Route exact path='/lodging' element={<LodgingPage />} />
          <Route exact path='/lodgingDetail/:id' element={<RoomDetails />} />
          <Route exact path='/transportation' element={<TransportationPage />} />
          <Route exact path='/Sight' element={<SightSeeing />} />
          <Route
            exact
            path='/trip'
            element={<Layout />}
            children={publicRoutes.map((route, idx) => (
              <Route
                key={idx}
                path={route.path}
                isProtected={false}
                element={route.element}
              />
            ))}
          />

          {/* admin routes */}
          <Route
            exact
            path='/auth/'
            element={<RouteMiddleware isProtected={true} />}
            children={protectedRoutes.map((route, idx) => (
              <Route
                key={idx}
                path={route.path}
                isProtected={true}
                element={route.element}
              />
            ))}
          />
          {/*  user profile route */}
      
      
        
        </Routes>
      {/* </ThemeProvider> */}
    </>
  )
}

export default connect(null, null)(App)
