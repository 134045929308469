
import axios from 'axios'

const API = axios.create({
  // baseURL: "http://localhost:8000/api/",
  // baseURL: "http://localhost/shoestring/api/",
  baseURL: "https://sbox.aladdinapps.com/api/",
  headers: {
    'Authorization': `Bearer ${localStorage.getItem("token")}`
  }
})

API.interceptors.response.use(
  res => { return res },
  err => { 
    if(err.status !== 401) throw err
   }
)

export default API