import * as React from 'react';
import {Box, Typography} from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Autocomplete from "react-google-autocomplete"
export default function FlightModal(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
 
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div >
      <Typography
        id="demo-positioned-button"
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
       
      >
       {props.children}
      </Typography>
      <Menu className='flightFrom'
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{mt:{md:'5%'},ml:{md:'-.5rem',}}}
      >
        <MenuItem >
        <>
        <Autocomplete
          
          style={{
            height:' 42px',
            borderRadius: '5px',
            padding:' 0px 10px',
            width:' 100%',
            // marginTop: '0.6rem',
            border:'none',
            borderBottom: '1px solid #F45714',
            color:'#F45714',
            outline:'none'
          }}
          onPlaceSelected={(place) => {
                 props.setPlaceSelected(JSON.parse(JSON.stringify(place)).address_components[0].long_name);
                 props.setAirport(JSON.parse(JSON.stringify(place)).formatted_address)
             }}
          />
        </>
        </MenuItem>
      
      
      </Menu>
    </div>
  );
}