import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import { MenuItem, Box, Grid, Typography } from "@mui/material";
import { RemoveCircleOutline, AddCircleOutline } from "@mui/icons-material";

export default function TravelClass(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const Decrease = () => {
    if (props.Adults > 1) {
      props.setAdults(props.Adults - 1);
    }
  };
  const Increase = () => {
    props.setAdults(props.Adults + 1);
  };

const DecreaseChild=()=>{
 
    if(props.Child>=1){
        props.setChild(props.Child-1)

    }
}
  return (
    <div>
      <Box
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        {props.children}
      </Box>
      <Menu
      sx={{mt:'1rem'}}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem sx={{ width: { md: "350px" } }}>
          <Grid container xs={{ p: "0" }}>
            <Grid container xs={7}>
              <Grid xs={12}>
                <Typography variant="body2">Adults</Typography>
              </Grid>
              <Grid xs={12}>
                {" "}
                <Typography
                  variant="body1"
                  sx={{ fontWeight: "300", color: "gray", fontSize: "11px" }}
                >
                  12 years and above
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              xs={5}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid xs={4}>
                <RemoveCircleOutline
                  sx={{ cursor: "pointer" }}
                  onClick={Decrease}
                />
              </Grid>
              <Grid xs={4}>
                <Typography variant="h6" textAlign="center">
                  {props.Adults}
                </Typography>
              </Grid>
              <Grid xs={4}>
                <AddCircleOutline onClick={Increase} />
              </Grid>
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem sx={{ width: { md: "350px", } }}>
          <Grid container xs={{ p: "0" }}>
            <Grid container xs={7} >
              <Grid xs={12}>
                <Typography variant="body2">Children</Typography>
              </Grid>
              <Grid xs={12}>
              
                <Typography
                  variant="body1"
                  sx={{ fontWeight: "300", color: "gray", fontSize: "11px" }}
                >
                  2-11 years
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              xs={5}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid xs={4}>
                <RemoveCircleOutline
                  sx={{ cursor: "pointer" }}
                  onClick={DecreaseChild}
                />
              </Grid>
              <Grid xs={4}>
                <Typography variant="h6" textAlign="center">
                  {props.Child}
                </Typography>
              </Grid>
              <Grid xs={4}>
                <AddCircleOutline onClick={()=>props.setChild(props.Child+1)} />
              </Grid>
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem>
        <Box sx={{display:'flex',justifyContent:'space-between',width:'100%'}}>
          <Button variant="outlined" size="small" sx={{width:'12rem',fontSize:'12px'}}>  <AddCircleOutline/>Add Another Room </Button>
          <Button variant='contained'>Done</Button>
        </Box>
        </MenuItem>
   
      </Menu>
    </div>
  );
}
