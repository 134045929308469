import { createTheme } from '@mui/material/styles';
// declare module "@mui/material/styles" {
//   interface SimplePaletteColorOptions {
//     // mainLighter?: string;
//     // mainDarker?:string;
//     // background100?: string;
//     // contrastText25?: string;
//     // contrastText50?: string;
//     // contrastText100?: string;
//     // contrastText400?: string;
//     // contrastText800?: string;
//     // contrastText900?: string;
//   }
// }

export const appTheme = createTheme({
  palette: {
    primary: {
    
      main: "#F45714",
      "900":"#ffffff"
      
    },
    secondary: {
      main: "#7BEBE3",
      "50": "#E9F3F4",
      "100": "#C1F4F1",
      "200": "#79D9D2",
      contrastText:"white"
    },
    text: {
      primary: "#3C4949",
     
      // secondary: "white",

    },
 
  },
  // typography: { fontFamily: 'Apercu Pro' }
})

// appTheme.components = {
//   MuiButton: {
//     styleOverrides: {
//       root: {
//         borderRadius: '0.6rem',
//         textTransform: 'unset',
//         '&.Mui-disabled': {
//           backgroundColor: 'rgba(13, 160, 107, 0.5)',
//           color: appTheme.palette.secondary.contrastText
//         }
//       }
//     }
//   }
// };
