import * as React from "react"
import List from "@mui/material/List"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import InboxIcon from "@mui/icons-material/Inbox"
import Collapse from "@mui/material/Collapse"
import ExpandLess from "@mui/icons-material/ExpandLess"
import ExpandMore from "@mui/icons-material/ExpandMore"
import NestedDates from "./NestedDates"

export const NestedList = ({ listItem, index }) => {
  const [selectedIndex, setSelectedIndex] = React.useState(0)
  const [nestedListOpen, setNestedListOpen] = React.useState(false)
  const handleListItemClick = (event, index) => {
    setSelectedIndex(index)
    setNestedListOpen(!nestedListOpen)
  }

  return (
    <List
      sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
      component='nav'
      aria-labelledby='nested-list'
    >
      <ListItemButton
        selected={selectedIndex === index}
        onClick={(event) => handleListItemClick(event, index)}
      >
        <ListItemIcon>
          <InboxIcon />
        </ListItemIcon>
        <ListItemText primary={listItem} />
        {nestedListOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={nestedListOpen} timeout='auto' unmountOnExit>
      <NestedDates/>
      </Collapse>
    </List>
  )
}
