import PropTypes from "prop-types"
import { Navigate, Outlet } from "react-router-dom"

const RouteMiddleware = ({ isProtected }) => {
  return isProtected && localStorage.getItem("token") ? (
    <Outlet />
  ) : (
    <Navigate to={{ pathname: "/" }} />
  )
}

RouteMiddleware.propTypes = {
  isProtected: PropTypes.bool,
}

export default RouteMiddleware
