import * as React from "react"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import FlightIcon from "@mui/icons-material/Flight"
import HotelIcon from "@mui/icons-material/Hotel"
import DirectionsRailwayIcon from "@mui/icons-material/DirectionsRailway"
import BiotechIcon from "@mui/icons-material/Biotech"
import PropTypes from "prop-types"
import { useNavigate } from "react-router-dom"

function TabPanel(props) {
  const { children, value, index, to, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  }
}

export const BbudgetTabs = () => {
  const [value, setValue] = React.useState(0)
  const navigate = useNavigate()
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <>
      <Box
        sx={{
          backgroundColor: "rgb(249, 249, 249)",
          borderBottom: 1,
          borderColor: "divider",
          borderRadius: "0.5rem",
        }}
      >
        <Typography
          sx={{
            fontSize: "1.05rem",
            fontWeight: 600,
            pt: "1.4rem",
            textAlign: "center",
          }}
        >
          Start your shoestring budget adventure
        </Typography>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label='Trip'
          sx={{ mx: "3.45rem" }}
        >
          <Tab
            label='Flights'
            icon={<FlightIcon sx={{ width: "1.5rem", height: "1.5rem" }} />}
            {...a11yProps(0)}
            onClick={() => navigate("/flight")}
            sx={{ color: "rgb(102, 102, 102)", fontSize: "0.6rem" }}
          />
          <Tab
            label='Lodging'
            icon={<HotelIcon sx={{ width: "1.5rem", height: "1.5rem" }} />}
            {...a11yProps(1)}
            onClick={() => navigate("/lodging")}
            sx={{ color: "rgb(102, 102, 102)", fontSize: "0.6rem" }}
          />
          <Tab
            label='Transportation'
            icon={
              <DirectionsRailwayIcon
                sx={{ width: "1.5rem", height: "1.5rem" }}
              />
            }
            onClick={() => navigate("/transportation")}
            {...a11yProps(2)}
            sx={{ color: "rgb(102, 102, 102)", fontSize: "0.6rem" }}
          />
          <Tab
            label='SightSeeing'
            icon={<BiotechIcon sx={{ width: "1.5rem", height: "1.5rem" }} />}
            onClick={() => navigate("/sight")}
            {...a11yProps(3)}
            sx={{ color: "rgb(102, 102, 102)", fontSize: "0.6rem" }}
          />
        </Tabs>
      </Box>
    </>
  )
}
