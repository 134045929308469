import React from 'react'
import {Link} from 'react-router-dom';
import {TableRow,TableHead,TableCell,TableBody,Table,CircularProgress,Box,styled} from '@mui/material';
import Title from './Title';






function preventDefault(event) {
  event.preventDefault();
}

export default function RecentBudgets({latestBudget}) {

  const TableCellStyled=styled(TableCell)({
    fontWeight:'bold',
    color:"#ff7043",
    backgroundColor:'#cfd8dc',
  })

  return (
    <React.Fragment>
      <Title>Recents Budgets</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCellStyled>Title</TableCellStyled>
            <TableCellStyled>Duration</TableCellStyled>
            <TableCellStyled>Destination</TableCellStyled>
            <TableCellStyled>Travel Budget</TableCellStyled>
          
          </TableRow>
        </TableHead>
        <TableBody>
          {latestBudget.latest_five_plans?latestBudget.latest_five_plans?.map((row) => (
            <TableRow key={row.id}    sx={{ "&:nth-of-type(even)": { background: "lightgray" } }}>
               <TableCell>{row.title}</TableCell>
              <TableCell>{row.from_date} To {row.to_date}</TableCell>
              <TableCell>{row.destination ? JSON.parse(row.destination)?.formatted_address : '--'}</TableCell>
              <TableCell>{row.target_budget}</TableCell>
            
            </TableRow>
          )):    <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
           ml:'25rem',
           mt:'5rem'
          }}
        >
          <CircularProgress size='6rem' />
        </Box>}
        </TableBody>
      </Table>
      <Link color="primary" to="/auth/user/budgetplanner"    style={{ textDecoration: 'none' , color:'#ff7043', }}>
        See more budget plan
      </Link>
    </React.Fragment>
  );
}