import {Box,Typography,Input,Button,Container,FormLabel, Grid, Paper, Dialog, DialogTitle, DialogContentText, DialogContent } from '@mui/material'
import Layout from './Layout'
import { getCategoriesBudget, saveUserInfo, getCurrencies, saveUserCurrency } from '../../../api-services/adminServices/userService'
import { styled } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import { Link } from "react-router-dom"
import AutoFetch from '../../common/AutoFetch'

import {useState,useEffect} from 'react'
const UserPofile = () => {
    const loginUser = localStorage.getItem("user")
    const [categories, setCategories] = useState([])
    const [currencies, setCurrencies] = useState([])
    const [open, setOpen] = useState(false)
    const [openCurrency, setOpenCurrency] = useState(false)
    const [catId, setCatId] = useState(null)
    const [newBudget, setNewBudget] = useState(null)
    const [selectedCat, setSelectedCat] = useState(null)

    const userData=  JSON.parse(loginUser)
    const {name,email}=userData;
    const [currency, setCurrency] = useState(userData.currency)
    const [profileImage, setProfileImage] = useState(userData.image_path)

    const getCategoryData = async () => {
      const response = await getCategoriesBudget()
      setCategories(response.data.data)
    }

    const getCurrenciesData = async () => {
        const response = await getCurrencies()
        setCurrencies(response.data.data)
      }

    const editCategoryBudget = async (data) => {
        setCatId(data.id)
        setSelectedCat(data.name)
        setOpen(true)
    }

    const editCurrency = async (currency) => {
        setOpenCurrency(true)
    }

    const catBudget = async (e) => {
        setNewBudget(e.target.value)
    }

    const saveCurrency = async (currency) => {
        const userInfoFormData = new FormData()
        userInfoFormData.append("currency_id", currency.id)
        const response = await saveUserCurrency(userInfoFormData)

        if (response.data.success === true) {
            setOpenCurrency(false)
            setCurrency(currency)
            if(response.data.data.user){
                localStorage.setItem("user", JSON.stringify(response.data.data.user))
            }
            
        }
    }

    const saveCatBudget = async () => {
        // console.log(`Cat ${catId}`);
        // console.log(`Budget ${newBudget}`);

        const userInfoFormData = new FormData()
        userInfoFormData.append("category_id", catId)
        userInfoFormData.append("budget", newBudget)
        const response = await saveUserInfo(userInfoFormData)

        if (response.data.success === true) {
            setOpen(false)
            getCategoryData()
        }
    }
    

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'left',
        color: theme.palette.text.secondary,
    }));

    useEffect(() => {
      getCategoryData()
    }, [])
    useEffect(() => {
        getCurrenciesData()
    }, [])
  return (
    <Layout>
        <Container>
        <Grid container spacing={2} sx={{mt: '1rem'}}>
            <Grid item xs={4}>
                <Item>
                    <Box sx={{height: '300px', backgroundImage: `url(${profileImage != null ? profileImage : 'https://png.pngtree.com/png-vector/20190704/ourlarge/pngtree-businessman-user-avatar-free-vector-png-image_1538405.jpg'})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}></Box>
                    <Box sx={{mt: '1rem'}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                            <Link
                                component="button"
                                variant="body2"
                                decoration="none"
                                style={{marginLeft: '1rem', textDecoration: "none", color: "inherit"}}
                                to='/auth/updateProfileImage'
                            >
                                <Button variant="contained">
                                    Update Image
                                </Button>
                            </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Item>
            </Grid>
            <Grid item xs={8} sx={{paddingBottom: '1rem',overflow: 'hidden'}}>
                <Grid container spacing={2} sx={{margin: 'auto', background: 'white'}}>
                    <Grid item xs={12} sx={{padding: 0}}>
                        <Grid container spacing={2} sx={{margin: 'auto'}}>
                            <Grid item xs={12} sx={{padding: '3px !important', height: '100%'}}>
                                <Typography sx={{fontWeight: 'bold', fontSize: '18px', margin: '0', color: '#F45714'}}>
                                    Personal Information
                                </Typography>
                            </Grid>

                            <Grid item xs={6} sx={{padding: '3px !important', height: '100%'}}>
                                <Typography>
                                    Name
                                </Typography>
                            </Grid>

                            <Grid item xs={6} sx={{padding: '3px !important', height: '100%'}}>
                                <Typography>
                                    {name}
                                </Typography>
                            </Grid>

                        </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{padding: 0}}>
                        <Grid container spacing={2} sx={{margin: 'auto'}}>
                            <Grid item xs={6} sx={{padding: '3px !important', height: '100%'}}>
                                <Typography>
                                    Email
                                </Typography>
                            </Grid>

                            <Grid item xs={6} sx={{padding: '3px !important', height: '100%'}}>
                                <Typography>
                                    {email}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} sx={{padding: 0}}>
                        <Grid container spacing={2} sx={{margin: 'auto'}}>
                            <Grid item xs={6} sx={{padding: '3px !important', height: '100%'}}>
                            <Typography>
                                    Currency
                                </Typography>
                            </Grid>

                            <Grid item xs={6} sx={{padding: '3px !important', height: '100%'}}>
                                <Typography>
                                    {currency != null ? `${currency.name} - ${currency.symbol}` : ''}
                                    <Link
                                            component="button"
                                            variant="body2"
                                            decoration="none"
                                            style={{marginLeft: '1rem'}}
                                            onClick={() => {editCurrency(currency)}}
                                        >
                                        <EditIcon fontSize="12px" />
                                    </Link>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container spacing={2} sx={{margin: 'auto', background: 'white', mt: '1rem'}}>
                    <Grid item xs={12} sx={{padding: 0}}>
                        <Grid container spacing={2} sx={{margin: 'auto'}}>
                            <Grid item xs={12} sx={{padding: '3px !important', height: '100%'}}>
                                <Typography sx={{fontWeight: 'bold', fontSize: '18px', margin: '0', color: '#F45714'}}>
                                    Weekly Approx. Budget For Categories
                                </Typography>
                            </Grid>

                            {categories?.map((data,index)=>( 
                                <>
                                    <Grid item xs={6} sx={{padding: '3px !important', height: '100%'}}>
                                        <Typography>
                                        {data.name}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={6} sx={{padding: '3px !important', height: '100%'}}>
                                        {data.category_budget != null ? `${data.category_budget.budget} ${currency != null ? currency.symbol : ''}` : `N.A ${currency != null ? currency.symbol : ''}`} 
                    
                                        <Link
                                            component="button"
                                            variant="body2"
                                            decoration="none"
                                            style={{marginLeft: '1rem'}}
                                            onClick={() => {editCategoryBudget(data)}}
                                        >
                                        <EditIcon fontSize="12px" />
                                    </Link>
                                    </Grid>
                                </>
                            ))}

                            

                            
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>

        </Container>

        <Dialog open={open} maxWidth="lg">
            <DialogTitle>Enter New Budget For {selectedCat} Category</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <Input id="outlined-basic" label="Outlined" variant="outlined" sx={{width:{xs:'100%',md:"80%"}}} placeholder="New Budget" onChange={(e) => catBudget(e)}/>
                </DialogContentText>
            </DialogContent>
            <Box sx={{display: 'flex',justifyContent: 'space-around',paddingBottom:'1rem'}}>
                <Box>
                    <Button variant="outlined" color="error" onClick={() => setOpen(false)} size="small">
                        Close
                    </Button>
                </Box>
                <Box>
                    <Button
                        variant='contained'
                        sx={{ color: "white" }}
                        size="small"
                        onClick={() => saveCatBudget()}
                    >
                        Save
                    </Button>
                </Box>
            </Box>
        </Dialog>

        <Dialog open={openCurrency} maxWidth="lg">
            <DialogTitle>Select Currency</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    <AutoFetch currencies={currencies} currency={currency} saveCurrencyFun={saveCurrency}/>
                </DialogContentText>
            </DialogContent>
            <Box sx={{display: 'flex',justifyContent: 'space-around',paddingBottom:'1rem'}}>
                <Box>
                    <Button variant="outlined" color="error" onClick={() => setOpenCurrency(false)} size="small">
                        Close
                    </Button>
                </Box>
                <Box>
                    
                </Box>
            </Box>
        </Dialog>
    </Layout>
  )
}

export default UserPofile