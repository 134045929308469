import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import {Typography} from '@mui/material'
import PersonIcon from '@mui/icons-material/Person';
import LockResetIcon from '@mui/icons-material/LockReset';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from "react-router-dom"
import { Link } from 'react-router-dom';
const StyledMenu = styled((props) => (

  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

export default function Dropdown(props) {
       
    const navigate= useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);

  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const logOut = () => {
    localStorage.clear()
    navigate("/")
    window.location.reload(false);
  }
  const MenuItemStyle=styled(MenuItem)({
    '&:hover':{
        backgroundColor:'#F45714',
        color:'white'
    }
  })
  return (


    <>
      <Typography
        id="demo-customized-button"
        // aria-controls={open ? 'demo-customized-menu' : undefined}
        // aria-haspopup="true"
        // aria-expanded={open ? 'true' : undefined}
        // variant="contained"
        onClick={handleClick}
        // endIcon={<KeyboardArrowDownIcon />}
        sx={{fontSize: '20px'}}
      >
        {props.children}
        {props.name}
      </Typography>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{ml:{xs:'-3rem',md:'0rem'},mt:'.9rem'}}
      >
       <Link to="/auth/userProfile" sx={{color:'white'}} style={{ textDecoration: 'none' }}>
        <MenuItemStyle onClick={handleClose} disableRipple sx={{color:'black',textDecoration:'none'}}>
       <PersonIcon />
          Profile
      
        </MenuItemStyle>
        </Link>
        <Link to ="/auth/changePassword"sx={{color:'white'}} style={{ textDecoration: 'none' ,color:"black"}}>
        <MenuItemStyle onClick={handleClose} disableRipple>
          <LockResetIcon />
        Change Password
        </MenuItemStyle>
        </Link>
        <Divider sx={{ my: 0.5 }} />
        <MenuItemStyle  disableRipple onClick={logOut}>
          <LogoutIcon/>
         Log out
        </MenuItemStyle>
      
      </StyledMenu>
    </>
  );
}
