import API from "../api"
import axios from 'axios'

// function for saving initial destination and duration
export const addTripPlan = async (data) => {
    try {
      return await API.post("/add-user-trip", data)
    } catch (error) {
      console.log(error)
    }
  }

// function for getting map detail
export const getMapDetail = async (id) => {
  try {
    return await API.post("/get-trip-map", id)
  } catch (error) {
    console.log(error)
  }
}

// function for getting place images
export const getTripDetail = async (id) => {
  try {
    return await API.post("/get-trip-detail", id)
  } catch (error) {
    console.log(error)
  }
}
export const getUserTrip=async()=>{
  try {
    return await API.post('/get-user-trips')
  } catch (error) {
    console.log(error)
  }
}
export const getTopPlaces=async()=>{
  try {
    
    return await API.post(`https://maps.googleapis.com/maps/api/place/nearbysearch/json?keyword=hotels&location='48.8566,2.3522'&opennow=true&radius=50000&language=en&key=AIzaSyDj8raFmgne-CAknrw-Zyzl7iE6sXaOE8A`)
  } catch (error) {
    console.log(error)
    
  }
}