import React from "react"
import Header from "./header"
import Filters from "./filters"
import {
  CardContent,
  CardMedia,
  Typography,
  Card,
  Grid,
  Box,
  useTheme,
  Button,
} from "@mui/material"
import MediaControlCard from "./cards"

export const TransportationPage = () => {
  const [startDate, setStartDate] = React.useState(null)
  const [endDate, setEndDate] = React.useState(null)
  return (
    <>
      <Header />
      <Filters />
      <div style={{ width: "85%", margin: "auto" }}>
        <h4 style={{ color: "red" }}>Featured Properties</h4>
        <Grid container>
          <Grid xs={4} style={{ paddingRight: "10px" }}>
            <MediaControlCard />
          </Grid>
          <Grid xs={4} style={{ paddingRight: "10px" }}>
            <MediaControlCard />
          </Grid>
          <Grid xs={4}>
            <MediaControlCard />
          </Grid>

          <Grid xs={12} sx={{ mt: "2rem" }}>
            <MediaControlCard />
          </Grid>
        </Grid>
      </div>
    </>
  )
}
