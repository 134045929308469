import React from "react"
import { ExploreAccordion } from "../../components/my-work-on-tripPage/exploreAccordion/exploreAccordion"
import { BbudgetTabs } from "../../components/my-work-on-tripPage/budgetTabs/BudgetTabs"
import { Box, Typography } from "@mui/material"
import { Budget } from "../../components/budget"
import { Itineray } from "../../components/my-work-on-tripPage/itinerary/Itineray"
import Layout from "../../components/layout"
import { useParams } from 'react-router-dom';

export const MainTripPage = () => {
  const params = useParams()
  // console.log(params)
  return (
    <>
      {/* <Budget /> */}
      {/* <Layout /> */}
      <ExploreAccordion />
      <Box sx={{ display: "flex", justifyContent: "center", mt:"2.5rem" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            // width: {md:"46rem",lg:"50rem"},
            width: "46rem",
          }}
        >
          <BbudgetTabs />
          <Budget />
        </Box>
      </Box>
      <Itineray />


    </>
  )
}
