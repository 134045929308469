import React, { useState, useEffect } from "react";
import {
  Paper,
  Box,
  Typography,
  styled,
  TablePagination,
  TableRow,
  TableBody,
  TableHead,
  Table,
  TableContainer,
  TableCell,
  tableCellClasses,
} from "@mui/material";

import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { getLatestBudgetPlan } from "../../../../api-services/userServices/budgetServices";

import { appTheme } from "../../../../theme";
import Grid from "@mui/material/Grid";
import { Emoji, EmojiStyle } from "emoji-picker-react";
import { BudgetPieChart } from "./BudgetPieChart";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: appTheme.palette.primary.main,
    // color: theme.palette.common.white,
    color: appTheme.palette.primary.main,
    fontWeight: "bold",
    backgroundColor: "#cfd8dc",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 17,
    fontWeight: 600,
  },
}));

export const BudgetPlanSnapshot = () => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [budgetPlan, setBudgetPlan] = React.useState([]);
  const [graphData, setGraphData] = React.useState();
  const [pieBudgetData, setPieBudgetData] = React.useState();
  const [pieActualData, setPieActualData] = React.useState();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getLatestBudget = async () => {
    const response = await getLatestBudgetPlan();
    setBudgetPlan(response.data.data.budget_plans);

    if (response.data.success) {
      let tempArr = [];

      response.data.data.budget_plans_graph.forEach((budget) => {
        tempArr.push({
          name: budget.category,
          Actual: budget.total,
          Budget: budget.total_actual,
          icon: budget.category_icon,
        });
      });
      setGraphData(tempArr);

      let budgetArr = [];

      response.data.data.budget_plans_graph.forEach((budget) => {
        budgetArr.push({
          name: budget.category,
          Budget: budget.total_actual,
          icon: <Emoji unified={budget.category_icon} size="20" />,
        });
      });
      setPieBudgetData(budgetArr);

      let actualArr = [];

      response.data.data.budget_plans_graph.forEach((budget) => {
        actualArr.push({
          name: budget.category,
          Budget: budget.total,
          icon: budget.category_icon,
        });
      });
      setPieActualData(actualArr);
    }
  };

  useEffect(() => {
    getLatestBudget();
  }, []);

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, 17 - page * rowsPerPage);
  return (
    <>
      <Typography variant="h5" sx={{ color: "#ff7043", mb: "0.5rem" }}>
        Latest Budget Planner
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={8} md={8} lg={8}>
          <Paper
            sx={{
              background: "#f7ede8",
              pt: "1rem",
              width: "100%",
              overflowX: "auto",
              overflowY: "hidden",
            }}
          >
            {/* <ResponsiveContainer width="100%" height="100%"> */}
            <BarChart
              width={900}
              height={400}
              data={graphData}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="Budget" fill="#C1AFA3" />
              <Bar dataKey="Actual" fill="#ECAF91" />
            </BarChart>
          </Paper>
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <Paper sx={{ background: "#f7ede8" }}>
            {/* </ResponsiveContainer> */}
            <Box>
              <TableContainer>
                <Table sx={{ width: "100%" }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 900 }}>Title</TableCell>
                      <TableCell
                        align="center"
                        sx={{ fontWeight: 900, background: "#C1AFA3" }}
                      >
                        Budget
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{ fontWeight: 900, background: "#ECAF91" }}
                      >
                        Actual
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {graphData
                      ? graphData.map((row) => (
                          <TableRow
                            key={row.name}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              <Emoji unified={row.icon} size="18" />{" "}
                              <span style={{ marginLeft: "0.5rem" }}>
                                {row.name}
                              </span>
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                background: "#C1AFA3",
                                color: "white",
                                fontWeight: "700",
                              }}
                            >
                              {row.Budget.toFixed(2)}
                          
                            </TableCell>
                            <TableCell
                              align="center"
                              sx={{
                                background: "#ECAF91",
                                color: "white",
                                fontWeight: "700",
                              }}
                            >
                              ${row.Actual}
                            </TableCell>
                          </TableRow>
                        ))
                      : "No Data Found !"}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Paper>
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ mt: "1rem" }}>
        <Grid item xs={6} md={6} lg={6}>
          <Paper sx={{ background: "#f7ede8", pt: "1rem" }}>
            <Typography sx={{ fontWeight: "bold", pl: "1rem" }}>
              Budget Breakdown
            </Typography>
            {pieBudgetData ? (
              <BudgetPieChart graphDataAll={pieBudgetData} />
            ) : (
              "No Data Found !"
            )}
          </Paper>
        </Grid>

        <Grid item xs={6} md={6} lg={6}>
          <Paper sx={{ background: "#f7ede8", pt: "1rem" }}>
            <Typography sx={{ fontWeight: "bold", pl: "1rem" }}>
              Actual Breakdown
            </Typography>
            {pieActualData ? (
              <BudgetPieChart graphDataAll={pieActualData} />
            ) : (
              "No Data Found !"
            )}
          </Paper>
        </Grid>
      </Grid>
      <Typography variant="h5" sx={{ color: "#ff7043", p: "5px",mt:'.8rem' }}>
          Micro Transaction
        </Typography>
      <Paper sx={{ width: "100%", overflow: "hidden", mt: "1.4rem" }}>
     
        <TableContainer component={Paper} sx={{ mt: "0rem" }}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Date</StyledTableCell>
                <StyledTableCell align="center">Description</StyledTableCell>
                <StyledTableCell align="center">Category</StyledTableCell>
                <StyledTableCell align="center">Quantity</StyledTableCell>
                <StyledTableCell align="center">Unit Cost</StyledTableCell>
                <StyledTableCell align="center">Total Amount</StyledTableCell>
           
              </TableRow>
            </TableHead>
            <TableBody>
              {budgetPlan
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow key={row.id}    sx={{ "&:nth-of-type(even)": { background: "lightgray" } }}>
                      <TableCell component="th" scope="row">
                        {row.expense_date}
                      </TableCell>
                      <TableCell align="center">{row.description}</TableCell>
                      <TableCell align="center">
                        {row.category != null ? row.category.name : "--"}
                      </TableCell>
                      <TableCell align="center">{row.quantity}</TableCell>
                      <TableCell align="center">{row.unit_price}$</TableCell>
                      <TableCell align="center">{row.total_price}$</TableCell>
                   
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={17}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Paper>
    </>
  );
};
