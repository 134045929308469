import {Box,Typography,Input,Button,Container,FormLabel, Grid, Paper, Dialog, DialogTitle, DialogContentText, DialogContent } from '@mui/material'
import Layout from './dashboard/Layout';
import { styled } from '@mui/material/styles';
import { Link } from "react-router-dom"
import {useState,useEffect} from 'react'
const AdminProfile = () => {
    const loginUser = localStorage.getItem("user")
    const userData=  JSON.parse(loginUser)
    const {name,email}=userData;

    const [profileImage, setProfileImage] = useState(userData.image_path)
    // const [profileImage, setProfileImage] = useState()


  


    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'left',
        color: theme.palette.text.secondary,
    }));

 
  return (
    <Layout>
        <Container>
        <Grid container spacing={2} sx={{mt: '1rem'}}>
            <Grid item xs={4}>
                <Item>
                    <Box sx={{height: '300px', backgroundImage: `url(${profileImage != null ? profileImage : 'https://png.pngtree.com/png-vector/20190704/ourlarge/pngtree-businessman-user-avatar-free-vector-png-image_1538405.jpg'})`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat'}}></Box>
                    <Box sx={{mt: '1rem'}}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                            <Link
                                component="button"
                                variant="body2"
                                decoration="none"
                                style={{marginLeft: '1rem', textDecoration: "none", color: "inherit"}}
                                to='/auth/updateAdminProfileImage'
                            >
                                <Button variant="contained">
                                    Update Image
                                </Button>
                            </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Item>
            </Grid>
            <Grid item xs={8} sx={{padding: 0}}>
                        <Grid container spacing={3} sx={{margin: 'auto',background:'white',p:'1rem'}}>
                            <Grid item xs={12} sx={{padding: '3px !important', height: '100%'}}>
                                <Typography sx={{fontWeight: 'bold', fontSize: '18px', margin: '0', color: '#F45714'}}>
                                    Personal Information
                                </Typography>
                            </Grid>

                            <Grid item xs={6} sx={{padding: '3px !important', height: '100%'}}>
                                <Typography>
                                    Name
                                </Typography>
                            </Grid>

                            <Grid item xs={6} sx={{padding: '3px !important', height: '100%'}}>
                                <Typography>
                                    {name}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sx={{padding: '3px !important', height: '100%'}}>
                                <Typography>
                                    Email
                                </Typography>
                            </Grid>

                            <Grid item xs={6} sx={{padding: '3px !important', height: '100%'}}>
                                <Typography>
                                    {email}
                                </Typography>
                            </Grid>

                        </Grid>
                    </Grid>
        </Grid>
    

        </Container>


    </Layout>
  )
}

export default AdminProfile