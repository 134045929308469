import * as React from "react"
import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import AccordionDetails from "@mui/material/AccordionDetails"
import Typography from "@mui/material/Typography"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import Box from "@mui/material/Box"
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth"
import Paper from "@mui/material/Paper"
import InputBase from "@mui/material/InputBase"
import IconButton from "@mui/material/IconButton"
import PlaceIcon from "@mui/icons-material/Place"
import { ItineraryAccordion } from "./itineraryAccordion"
import NestedDates from "../../nestedList/NestedDates"
export const Itineray = () => {
  return (
    <Box sx={{ bgcolor: "rgb(249, 249, 249)", mt: "2.8rem" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          justifyItems: "center",
          mx: "5.5rem",
          pt: "2.5rem",
        }}
      >
        <Typography
          sx={{ fontSize: "1.6rem", fontWeight: 800, color: "black" }}
        >
          Itineray
        </Typography>
        <Box
          sx={{
            bgcolor: "rgb(217, 217, 217)",
            display: "flex",
            justifyItems: "center",
            py: "0.6rem",
            px: "0.9rem",
            borderRadius: "1rem",
          }}
        >
          <CalendarMonthIcon
            sx={{ color: "black", width: "1rem", height: "1rem", mr: "0.3rem" }}
          />
          <Typography
            sx={{ fontSize: "0.8rem", color: "black", fontWeight: 600 }}
          >
            11/07--
          </Typography>
          <Typography
            sx={{ fontSize: "0.8rem", color: "black", fontWeight: 600 }}
          >
            21/04
          </Typography>
        </Box>
      </Box>
      <ItineraryAccordion />
    
      {/* <NestedDates/> */}
    </Box>
  )
}
