import React, { useState, useEffect } from "react"
import Layout from "./dashboard/Layout"
import {Container,Typography,Grid,Paper,Button,Box,Dialog,InputBase,} from "@mui/material"


import CategoryTable from "./SubCategoriesTable"
import EmojiPicker from 'emoji-picker-react';
import { useParams } from 'react-router-dom';

import { Emoji } from 'emoji-picker-react';
import LinearProgress from '@mui/material/LinearProgress';
import { addCategory, getcategories, getSubCategory, editCategory, deleteCategory } from "../../api-services/adminServices/categoryService"


function SubCategory(props) {
  const params = useParams()
  const [open, setOpen] = useState(false)
  const [loader, setLoader] = useState(false)
  const [formValue, setformValue] = useState({
    categoryName: "",
    categoryIcon: "",
    categoryMedia: "",
    id: ""
  })
  const [categories, setCategories] = useState([])
  const handleChange = (event) => {
    setformValue({
      ...formValue,
      [event.target.name]: event.target.value,
    })
  }

  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  const getCategoryData = async () => {
    const response = await getSubCategory(params.id);
    setCategories(response.data.data)
  }

  const addcategorySubmit = async (e) => {
    e.preventDefault()
    const CategoryFormData = new FormData()
    CategoryFormData.append("name", formValue.categoryName)
    CategoryFormData.append("icon", formValue.categoryMedia)
    CategoryFormData.append("parent_id", params.id)
    CategoryFormData.append("id", formValue.id)
    const response = addCategory(CategoryFormData)
    response.then((res) => {
      if (res.data.success === true) {
        setOpen(false)
        getCategoryData()
      }
    })
    setformValue({ categoryIcon: "", categoryName: "" })
  }

  const editCategoryFun = async (id) => {
    try {
        setOpen(true)
        setLoader(true)
        const response = await editCategory(id)
        if (response.data.success === true) {
            setformValue({ categoryMedia: response.data.data.icon, categoryName: response.data.data.name, id: response.data.data.id })
            setLoader(false)
        }
      } catch (error) {
        console.log(error)
    }
   
  }

  const deleteCategoryFun = async (id) => {
    try {
        const response = await deleteCategory(id)
        if (response.data.success === true) {
           getCategoryData()
        }
      } catch (error) {
        console.log(error)
    }
   
  }
  useEffect(() => {
    getCategoryData()
  }, [])
  return (
    <Layout name='Sub Categories'>
      <Container maxWidth='lg' sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} lg={12}>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                onClick={handleClickOpen}
                variant='contained'
                sx={{ color: "white" }}
              >
                add category
              </Button>
            </Box>
            <CategoryTable categories={categories} editCategory={editCategoryFun} deleteCategory={deleteCategoryFun}/>
            <Dialog
              open={open}
              keepMounted
              onClose={handleClose}
              aria-describedby='alert-dialog-slide-description'
              maxWidth='lg'
            >
              <Container component='main' maxWidth='lg'>
                {loader ? 
                    <Box sx={{ width: '100%' }}>
                        <LinearProgress />
                    </Box>
                    : ''    
                }
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box
                    component='form'
                    onSubmit={addcategorySubmit}
                    noValidate
                    sx={{ mt: "2.5rem" }}
                  >
                    <Paper
                      sx={{
                        px: "0.3rem",
                        display: "flex",
                        alignItems: "center",
                        width: 600,
                        marginBottom: '1rem'
                      }}
                    >
                      <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder='Add Category'
                        inputProps={{ "aria-label": "Add_category" }}
                        name='categoryName'
                        value={formValue.categoryName}
                        onChange={handleChange}
                      />
                    </Paper>

                    <Paper
                      sx={{
                        px: "0.3rem",
                        display: "flex",
                        alignItems: "center",
                        width: 600,
                        marginBottom: '1rem',
                        marginTop: '1rem'
                      }}
                    >
                    <Typography>Emoji Select : {formValue.categoryMedia != "" ? 
                        <span style={{marginLeft: '1rem'}}>
                            <Emoji unified={formValue.categoryMedia} size="25" /> 
                        </span>
                            : 'No Emoji Selected !!!'}
                    </Typography>
                    </Paper>
                    
                    <Paper
                      sx={{
                        px: "0.3rem",
                        display: "flex",
                        alignItems: "center",
                        width: 600,
                      }}
                    >
                      <EmojiPicker onEmojiClick={(emojiData, event) => {
                        setformValue({
                          ...formValue,
                          categoryMedia: emojiData.unified,
                        })
                      }}
                      width={600}
                      />
                    </Paper>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        my: "1rem",
                      }}
                    >
                      <Button
                        type='submit'
                        variant='contained'
                        sx={{ color: "white" }}
                      >
                        add
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Container>
            </Dialog>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  )
}
export default SubCategory
