
import Topbar from "../../pages/home/Topbar";
import Footer from "../footer";
import HomeSlider from "./HomeSlider";

const Home = () => {
  return (
    <>
      <Topbar />
        <HomeSlider /> 
      <Footer />
    </>
  );
};

export default Home;
