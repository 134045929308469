import * as React from 'react';
import {CardContent, CardMedia, Typography, Card, Grid, Box, useTheme} from '@mui/material';

export default function Header() {
    const theme = useTheme()

    return (
      <Card sx={{ display: "flex", mt: "2rem", justifyContent: "bottom", alignItems:  'end', color: 'white' }} className="trip__transportation__header lodging__header">
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          <CardContent sx={{ flex: "1 0 auto" }}>
            <Typography component='div' variant='h2'>
              {localStorage.getItem("whereto")}
            </Typography>
            <Typography
              variant='subtitle1'
              component='div'
            >
              {`${localStorage.getItem("startDate")} To ${localStorage.getItem(
                "endDate"
              )}`}
            </Typography>
          </CardContent>
        </Box>
      </Card>
    )
}
