import { Grid, Container, Box, Typography } from "@mui/material";
import { East } from "@mui/icons-material";
import { useState } from "react";
import Skeleton from '@mui/material/Skeleton';
const Filter = () => {
  const [activeCol, setActiveCol] = useState(true);
  const active = "#ebf0f4";
  const [loading,setLoading]=useState(true)
  const [cards,setCards]=useState([
    {
    id:'1',
    logo:"https://download.logo.wine/logo/Airblue/Airblue-Logo.wine.png",
    name:'Air Blue',
    timeDep:'07:30',
    from:'LHR',
    timeArr:'11:30',
    to:'ISL',
    Price:'12490'
  },
    {
    id:'2',
    logo:"https://e7.pngegg.com/pngimages/797/310/png-clipart-emirates-logo-dubai-airbus-a380-emirates-airline-logo-dubai-text-american-airlines-thumbnail.png",
    name:'Emirates',
    timeDep:'11:30',
    from:'LHR',
    timeArr:'5:30',
    to:'ISL',
    Price:'124590'
  },
    {
    id:'3',
    logo:"https://1000logos.net/wp-content/uploads/2021/04/Saudi-Arabian-Airlines-logo-500x292.png",
    name:'Saudia ',
    timeDep:'07:30',
    from:'Maddina',
    timeArr:'6:30',
    to:'ISL',
    Price:'165000'
  },
])
  return (
    <Box sx={{ bgcolor: "#ebf0f4", py: ".8rem" }}>
      <Container>
        <Grid container xs={12} md={11.75} sx={{}}>
          <Grid
            container
            xs={12}
            md={8.8}
            sx={{
              mr: {xs:'0',md:"10px"},
              px: { md: "1rem" },
              py: { md: ".5rem" },
              background: "#ffffff !important",
              boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
            }}
          >
            <Grid
              xs={12}
              md={6}
             
              sx={{
                my:{xs:'.5rem',md:'0'},
                cursor: "pointer",
                pl:".5rem",
                background: activeCol ? active : "white",
                borderLeft:{xs:activeCol? "1px solid #503e6c" : 'none'}
              }}
              onClick={() => setActiveCol(true)}
            >
              <Typography
                variant="h6"
                sx={{ color: "#503e6c", fontWeight: "bold", p: "0", }}
              >
                Cheapest
              </Typography>
              <Typography variant="caption">
                Showing the cheapest flights in ascending order
              </Typography>
            </Grid>
            <Grid
               xs={12}
               md={6}
               sx={{
                 my:{xs:'.5rem',md:'0'},
                
                borderLeft: {xs:'none',md:"1px solid #503e6c",},
                pl: ".5rem",
                cursor: "pointer",
                background: !activeCol ? active : "white",
               
                borderLeft:{xs:!activeCol? "1px solid #503e6c" : 'none'}
              }}
              onClick={() => setActiveCol(false)}
            >
              <Typography
                variant="h6"
                sx={{ color: "#503e6c", fontWeight: "bold", p: "0" }}
              >
                Fastest
              </Typography>
              <Typography variant="caption">
                Showing the fastest flights in ascending order
              </Typography>
            </Grid>
          </Grid>

          <Grid
            xs={12}
            md={3}
            sx={{
              px: { xs:".5rem",md: "1rem" },
              py: {xs:'.4rem', md: ".5rem" },
              background: "#ffffff !important",
              boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{ color: "#503e6c", fontWeight: "bold", p: "0" }}
            >
              Search Complete
            </Typography>
            <Typography variant="body2" sx={{ color: "gray", p: "0" }}>
              Showing <b>{cards.length}</b> results
            </Typography>
          </Grid>
          {loading?cards.map((data,index)=>(

       
<Grid
key={index}
  container
  xs={12}
  md={8.8}
  sx={{
    mt: "1rem",
    p: ".3rem",
    background: "white",
    boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
      py:".5rem"
  }}
>
  <Grid xs={6} md={3.5} sx={{ background: "" }}>
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box
        component="img"
        src={data.logo}
        sx={{ width: {xs:"50%",md:"50%"} }}
      />
      <Typography variant="body2">{data.name}</Typography>
    </Box>
  </Grid>
  <Grid container xs={12} md={5}>
    <Grid xs={3}>
      <Typography
        variant="h6"
        sx={{ color: "#503e6c", fontWeight: "bold", p: "0" }}
      >
      {data.timeDep}
      </Typography>
      <Typography variant="body2" sx={{ color: "gray" }}>
        {data.from}
      </Typography>
    </Grid>
    <Grid
      xs={6}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "start",
        mt: ".5rem",
      }}
    >
      <Typography variant="caption" sx={{ color: "#728db6" }}>
        Non-stop
      </Typography>
      <Box className="arrow-pointer"></Box>
    </Grid>
    <Grid xs={3} >
      <Typography
        variant="h6"
        sx={{ color: "#503e6c", fontWeight: "bold", p: "0" }}
      >
    {data.timeDep}
      </Typography>
      <Typography variant="body2" sx={{ color: "gray" }}>
        {data.to}
      </Typography>
    </Grid>
  </Grid>
  <Grid xs={12} md={3.5}sx={{ background: "#ebf0f4" }}>
    <Typography
      variant="caption"
      sx={{ textTransform: "capitalize", color: "#728db6" }}
    >
      Takeoff
    </Typography>
    <Typography variant="h6" sx={{ color: "#503e6c" }}>
      PKR {data.Price}
    </Typography>
    <Typography
      variant="button"
      sx={{
        display: "flex",
        justifyContent: "space-between",
        bgcolor: "primary.main",
        px: ".3rem",
        py: ".4rem",
        color: "white",
        cursor: "pointer",
        borderRadius: "5px",
      }}
    >
      <Typography>Select</Typography>
      <East />
    </Typography>
  </Grid>

</Grid>   )):  <Grid container xs={8.8} mt={3}>
         
<Grid xs={4}>
 <Skeleton variant="rectangular" width="100%"  height={118} />

 </Grid>
<Grid xs={4}> <Box sx={{ pt: 0.5 }} >
     <Skeleton />
     <Skeleton width="100%" />
     <Skeleton width="100%" />
     <Skeleton width="100%" />
     <Skeleton width="100%" />
   </Box></Grid>
<Grid xs={4}><Skeleton variant="rectangular" width="100%" height={118} /></Grid>
</Grid>}
          {/* //////////////////////////////////////////////// cards */}
          
          {/* /////////////////////////// cards end */}
        
        </Grid>
      </Container>
    </Box>
  );
};

export default Filter;
