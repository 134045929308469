import React from 'react'
import Layout from '../../Layout'
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import QuestionsSlider from './QuestionsSlider'
import TextMobileStepper from './typeForm/index'

function BudgetPlanQuestions() {
  return (
    <Layout name="Budget Plan Questions">
        <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              {/* Chart */}
              {/* <Grid item xs={12} md={12} lg={12}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <QuestionsSlider />
                </Paper>
              </Grid> */}

              <Grid item xs={12} md={12} lg={12}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <TextMobileStepper />
                </Paper>
              </Grid>
              
            </Grid>
          </Container>
    </Layout>
  )
}

export default BudgetPlanQuestions