import React, { useState, useEffect } from "react"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TablePagination from "@mui/material/TablePagination"
import Paper from "@mui/material/Paper"
import CircularProgress from "@mui/material/CircularProgress"
import Box from "@mui/material/Box"
import { styled } from '@mui/material/styles';
import { Emoji, EmojiStyle } from 'emoji-picker-react';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import { Link } from "react-router-dom"
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DialogTitle from '@mui/material/DialogTitle';
import {Dialog, Button} from '@mui/material';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#F45714',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 17,
    fontWeight:600
  },
}));


export default function SimpleTable({ categories, editCategory, deleteCategory }) {
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(7)
  const [deleteItem, setDeleteItem] = React.useState(null)
  const [open, setOpen] = React.useState(false)


  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }
  const handleDelete = (id) => {
    setDeleteItem(id)
    setOpen(true)
  }

  const deleteCat = (id) => {
    setOpen(false)
    deleteCategory(id)
  }

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, categories.length - page * rowsPerPage)
// console.log(categories.length)
  return (
    <TableContainer sx={{ mt: "1rem" }} component={Paper}>
      <Table aria-label='simple table'>
        <TableHead>
          <TableRow>
            <StyledTableCell>Name</StyledTableCell>
            <StyledTableCell align='center'>Icon</StyledTableCell>
            <StyledTableCell align='center'>Status</StyledTableCell>
            <StyledTableCell align='center'>Action</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {categories.length > 0  ? (
            categories
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <TableRow key={row.name}>
                  <TableCell >
                    {row.name}
                  </TableCell>
                  <TableCell align='center'>
                    <Emoji unified={row.icon} size="25" />
                  </TableCell>
                  <TableCell align='center'>{row.status == 1 ? <Box sx={{backgroundColor: 'green', width: '50%', margin: 'auto',color: 'white'}}>Active</Box> : <Box sx={{backgroundColor: 'red', width: '50%', margin: 'auto',color: 'white'}}>Active</Box>}</TableCell>
                  <TableCell align='center'>
                  <Link
                    component="button"
                    variant="body2"
                    onClick={() => {
                        editCategory(row.id);
                    }}
                    decoration="none"
                    >
                    <EditIcon />
                 </Link>
                 <Link
                    component="button"
                    variant="body2"
                    decoration="none"
                    style={{marginLeft: '1rem'}}
                    onClick={() => {
                        handleDelete(row.id);
                    }}
                    >
                    <DeleteIcon />
                 </Link>
                 
                  </TableCell>
                </TableRow>
              ))
          ) : (
            <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
             ml:'25rem',
             mt:'5rem'
            }}
          >
            <CircularProgress size='6rem' />
          </Box>
          )}

          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component='div'
        count={categories.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    <Dialog open={open} maxWidth="lg">
        <DialogTitle>Are you sure ?</DialogTitle>
        <Box sx={{display: 'flex',justifyContent: 'space-around',paddingBottom:'1rem'}}>
            <Box>
            <Button variant="outlined" color="error" onClick={() => setOpen(false)} size="small">
                Cancel
            </Button>
            </Box>
            <Box>
            <Button
                onClick={ () => deleteCat(deleteItem)}
                variant='contained'
                sx={{ color: "white" }}
                size="small"
              >
                Yes
              </Button>
            </Box>
        </Box>
    </Dialog>
    </TableContainer>
  )
}
