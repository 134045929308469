import API from "../api"
export const addBudget = async (data) => {
  try {
    return await API.post("/add-budget-plan", data)
  } catch (error) {
    console.log(error)
  }
}
export const getBudget = async () => {
  try {
    return await API.get("/get-budget-plans")
  } catch (error) {
    console.log(error)
  }
}
export const getBudgetDetail = async (id, fromDate = null, toDate = null) => {
  try {
    return await API.get(`/get-budget-plan-detail/${id}?from_date=${fromDate}&to_date=${toDate}`)
  } catch (error) {
    console.log(error)
  }
}
export const getGraphDetail = async (id, fromDate = null, toDate = null) => {
  try {
    return await API.get(`/get-budget-plan-detail-for-graph/${id}?from_date=${fromDate}&to_date=${toDate}`)
  } catch (error) {
    console.log(error)
  }
}
export const addMicroTranstions = async (data) => {
  try {
    return await API.post("/add-budget-plan-detail", data)
  } catch (error) {
    console.log(error)
  }
}
export const getLatestBudgetPlan = async () => {
  try {
    return await API.get("/get-latest-budget-plan")
  } catch (error) {
    console.log(error)
  }
}
export const  deleteMicrotransaction= async (id) => {
  try {
    return await API.get(`/delete-microtransaction/${id}`)
  } catch (error) {
    console.log(error)
  }
}
export const  deleteBudgetPlan= async (id) => {
  try {
    return await API.get(`/delete-budget-plan/${id}`)
  } catch (error) {
    console.log(error)
  }
}
export const getTopRecentBudgets = async () => {
  try {
    return await API.get("/get-latest-five-budget-plan")
  } catch (error) {
    console.log(error)
  }
}
export const getPlanBudgetWeekly=async(id)=>{
  try {
    return await API.get(`/get-plan-budget/${id}`)
  } catch (error) {
    console.log(error)
  }
}