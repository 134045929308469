import * as React from "react";
import { useTheme } from "@mui/material/styles";
import {Link,Typography} from '@mui/material'
import Title from './Title';
export default function TotalTrip({totalTrip}) {
  const theme = useTheme();

  return (
    <React.Fragment>
         <Title>Total Trip</Title>
      <Typography component="p" variant="h4" textAlign='center'>
       {totalTrip}
      </Typography>
    
    </React.Fragment>
  );
}
