import {useState} from 'react';

import {MenuItem,Typography,Menu,Box} from '@mui/material';
import { Delete,Redo } from '@mui/icons-material';
export default function BasicMenu(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
   
    setAnchorEl(null);
  };
 

  return (
    <div>
      <Box
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{position:'absolute',right:'0',top:'2px',background:'RGBA(0,0,0,0.13)',color:'white',borderRadius:'50%','&:hover':{
              background:'RGBA(0,0,0,0.23)',
           
            }}}
      >
       {props.children}
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        sx={{ml:{xs:'-10.5%',sm:'-16%',md:'-11.5%',lg:'-9%',xl:'-7%'},mt:{xs:'1.5rem'}}}
      >
        <MenuItem onClick={handleClose} sx={{height:'25px','&:hover':{
            color:'red'
        }}}>
           <Typography variant='body2' onClick={()=>props.DelteCard(props.id)} sx={{display:'flex',alignItems:'center'}}> <Delete sx={{mr:'.4rem'}} />Delete</Typography>
        </MenuItem>
        <MenuItem sx={{height:'25px',}} onClick={handleClose}><Redo sx={{mr:'.4rem'}}/>Share</MenuItem>
   
      </Menu>
    </div>
  );
}