import { connect } from "react-redux"
import { Outlet } from "react-router-dom"
import * as React from "react"
import Box from "@mui/material/Box"
import Drawer from "@mui/material/Drawer"
import CssBaseline from "@mui/material/CssBaseline"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import Divider from "@mui/material/Divider"
import { styled, useTheme } from "@mui/material/styles"
import MuiAppBar from "@mui/material/AppBar"
import IconButton from "@mui/material/IconButton"
import MenuIcon from "@mui/icons-material/Menu"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import SpeedDial from "@mui/material/SpeedDial"
import MapIcon from "@mui/icons-material/Map"
import { NestedList } from "../nestedList"
import Logo from "../../images/logo.svg"
import Map from './Map'
import { useParams } from 'react-router-dom';
import {getMapDetail} from "../../api-services/userServices/tripPlanServices"

const drawerWidth = 430

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  }),
}))

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),

  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}))

const Layout = () => {
  const params = useParams()
  // console.log(params)
  const theme = useTheme()
  const [open, setOpen] = React.useState(false)
  const [mobileSidebarToggle, setMobileSidebarToggle] = React.useState(false)
  const [openMap, setOpenMap] = React.useState(false)

  const [mapLat, setMapLat] = React.useState(37.772)
  const [mapLong, setMapLong] = React.useState(-122.214)

  const toggleMap = () => {
    setOpenMap(true)
  }
  const toggleMapClose = () => {
    setOpenMap(false)
  }

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }
  const handleDrawerOpenMobile = () => {
    setMobileSidebarToggle(true)
  }

  const handleDrawerCloseMobile = () => {
    setMobileSidebarToggle(false)
  }

  const getMapData = () => {
    const TripData = new FormData()
    TripData.append("id", params.id)
   
    const response = getMapDetail(TripData)
    response.then((res) => {
      if (res.data.success === true) {
        // console.log(res.data.data);
        if(res.data.data?.destination){
          let destination = res.data.data?.destination
          setMapLat(JSON.parse(destination)?.geometry?.location?.lat)
          setMapLong(JSON.parse(destination)?.geometry?.location?.lng)
        }
      }
    })
  }

  React.useEffect(() => {
    getMapData()
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position='fixed'
        // open={open}
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          bgcolor: "white",
          boxShadow: " -1px 1px 14px 2px rgba(99,96,96,0.16)",
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          mr: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <Typography
            variant='h6'
            noWrap
            sx={{ flexGrow: 1, fontStyle: "italic" }}
            component='div'
            color='text.secondary'
          >
            <Box
              component='img'
              src={Logo}
              sx={{ width: { xs: 180, sm: 300 } }}
            />
          </Typography>
          {/* {!open && (
            <IconButton
              aria-label='open drawer'
              edge='end'
              onClick={handleDrawerOpen}
              sx={{ display: { xs: "none", sm: "block" } }}
            >
              <MenuIcon sx={{ color: "text.primary" }} />
            </IconButton>
          )} */}
        </Toolbar>
      </AppBar>
      <Box
        component='nav'
        sx={{ width: { sm: 190 }, flexShrink: { sm: 0 } }}
        aria-label='mailbox folders'
      >
        <Drawer
          variant='permanent'
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": { boxSizing: "border-box", width: 190 },
          }}
        >
          <Toolbar />
          <Box sx={{ overflow: "auto" }}>
            {<NestedList listItem='Overview' index={0} />}
            {<NestedList listItem='Itinerary' index={1} />}
            {<NestedList listItem='Budget' index={2} />}
          </Box>
        </Drawer>
        <Drawer
          variant='temporary'
          ModalProps={{
            keepMounted: true,
          }}
          open={mobileSidebarToggle}
          sx={{
            width: 190,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: {
              width: 190,
              boxSizing: "border-box",
            },
            display: { xs: "block", sm: "none" },
          }}
        >
          <Toolbar />
          <DrawerHeader
            sx={{
              display: { xs: "flex", sm: "none" },
              justifyContent: "flex-end",
            }}
          >
            <IconButton onClick={handleDrawerCloseMobile}>
              {theme.direction === "ltr" ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <Box sx={{ overflow: "auto" }}>
            {<NestedList listItem='Overview' index={0} />}
            {<NestedList listItem='Itinerary' index={1} />}
            {<NestedList listItem='Budget' index={2} />}
          </Box>
        </Drawer>
      </Box>
      <Box
        component='main'
        sx={{ flexGrow: 1 }}
        // sx={{width:"100%"}}
      >
        <DrawerHeader />
        <IconButton
          aria-label='open drawer'
          edge='end'
          onClick={handleDrawerOpenMobile}
          sx={{
            display: { xs: "block", sm: "none" },
            position: "fixed",
            top: "11.3%",
            left: "2%",
          }}
        >
          <MenuIcon sx={{ color: "text.secondary" }} />
        </IconButton>
        <Outlet />

        <SpeedDial
          ariaLabel='SpeedDial basic example'
          sx={{
            position: "fixed",
            bottom: 16,
            right: 16,
            display: { sm: "none" },
          }}
          icon={<MapIcon />}
          onClick={toggleMap}
        />

        <Drawer
          sx={{
            width: "100%",
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: "100%",
            },
            display: { sm: "none" },
          }}
          variant='temporary'
          anchor='right'
          open={openMap}
        >
          <Toolbar />
          <DrawerHeader>
            <IconButton onClick={toggleMapClose}>
              {theme.direction === "rtl" ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />

          <Typography sx={{ fontSize: "2rem" }}>
            <Map />
          </Typography>
        </Drawer>
      </Box>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
          },
          display: { xs: "none", sm: "block" },
        }}
        variant='permanent'
        anchor='right'
        // open={open}
      >
        {/* <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader> */}
        <Divider />

        <Typography sx={{ fontSize: "2rem" }}>
          <Map lat={mapLat} lng={mapLong}/>
        </Typography>
      </Drawer>
    </Box>
  )
}

export default connect(null, null)(Layout)
