
import {CssBaseline,Typography,Container,Box,TextField, Button} from "@mui/material";
import { useState } from "react";
import Autocomplete from "react-google-autocomplete"
import { Link, useNavigate } from "react-router-dom";

import CreatePlanTopBar from "../createPlan/createPlanTopBar/CreatePlanTopBar";
import Footer from "../footer";
 const TravelGuide = () => {
  const [placeSelected,setPlaceSelected]=useState();
  const [errorshow,setErrorShow]=useState();
  const navigate=useNavigate()
const StartWriting=()=>{
placeSelected?navigate('/guideDashboard'):setErrorShow('Choose a destination to start planning')
   
}
  return (
    <>
      <CreatePlanTopBar />
      <Container component="main" maxWidth="xs" sx={{ mb: "3rem"}}>
        <CssBaseline />
        <Box
          sx={{
            marginTop: 15,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
       <Typography variant='h1' sx={{fontWight:'700',fontSize:'2.25rem'}}>
        write a travel guide
       </Typography>
<Typography variant="body1" sx={{color:'#6c757d',fontSize:'.8rem',mt:'1rem'}}>Help fellow travelers by writing up your tips or a past itinerary</Typography>
<Autocomplete
          
                   style={{
                     height:' 52px',
                     borderRadius: '5px',
                     padding:' 0px 10px',
                     width:' 100%',
                     marginTop: '0.6rem',
                     border: '1px solid #F45714',
                     color:'#F45714',
                     outline:'#F45714'
                   }}
                   onPlaceSelected={(place) => {
                    setPlaceSelected(JSON.stringify(place));
                    setErrorShow('')
                }}
                   />
                   <Typography variant="caption" sx={{color:'red'}}>{errorshow}</Typography>
               
                <Button sx={{background:'#F45714',color:'white',mt:'1rem'}} variant='contained' onClick={StartWriting}>Start writing</Button>
        </Box>
      </Container>
      <Footer />
    </>
  );
};
export default TravelGuide