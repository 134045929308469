import * as React from "react"
import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import AccordionDetails from "@mui/material/AccordionDetails"
import Typography from "@mui/material/Typography"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import Box from "@mui/material/Box"
import Paper from "@mui/material/Paper"
import InputBase from "@mui/material/InputBase"
import IconButton from "@mui/material/IconButton"
import Button from "@mui/material/Button"
import PlaceIcon from "@mui/icons-material/Place"
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth"
import AddIcon from "@mui/icons-material/Add"
import dayjs from "dayjs"
export const ItineraryAccordion = () => {
  const getDate=JSON.parse(localStorage.getItem('dates'))
  // console.log('date from local',getDate[0])
  // getDate.map((data)=>console.log(dayjs(data).format("ddd-YYYY-MM-DD")))

  
  return (
    <>
    {getDate?getDate?.map((data,index)=>(
 <Accordion
 disableGutters
 sx={{
   boxShadow: "none",
   bgcolor: "rgb(249, 249, 249)",
   mt: "2.5rem",
   
   //   ".MuiPaper-root.MuiAccordion-root :before": {
   //     opacity: 0,
   //   },
 }}
>
 <AccordionSummary
   expandIcon={
     <ExpandMoreIcon sx={{ width: "1.8rem", height: "1.8rem", }} />
   }
   aria-controls='panel1a-content'
   id={index}
   sx={{ display: "flex", flexDirection: "row-reverse", ml: "2rem" }}
 >
   <Box sx={{ display: "flex", alignItems: "center" }}>
     <Typography
       sx={{ fontSize: "1.35rem", fontWeight: 800, ml: "0.5rem" }}
     >
{dayjs(data).format('ddd-YYYY-MM-DD')}
     </Typography>
     <CalendarMonthIcon sx={{ width: "1.3rem", ml: "0.4rem" }} />
   </Box>
 </AccordionSummary>
 <AccordionDetails
   sx={{
     display: "flex",
     justifyContent: "space-between",
     alignItems: "center",
     mt: "0.6rem",
     width: "87%",
     mx:"auto"
   }}
 >
   <Paper
     component='form'
     sx={{
       p: "2px 4px",
       display: "flex",
       alignItems: "center",
       width: "80%",
       // ml: "4.5rem",
     }}
   >
     <IconButton
       sx={{ p: "0.625rem", cursor: "context-menu" }}
       aria-label='menu'
     >
       <PlaceIcon />
     </IconButton>
     <InputBase
       sx={{ ml: 1, flex: 1 }}
       placeholder='Add Place'
       inputProps={{ "aria-label": "Add Place" }}
     />
   </Paper>
   <Button size='large' variant='contained' endIcon={<AddIcon />}>
     Other
   </Button>
 </AccordionDetails>
</Accordion>
    )):null}
    </>
   
  )
}
