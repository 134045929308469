import React from "react";
import { Box ,TextField,Typography,} from "@mui/material"
import {EditAttributes, Person,PersonAddAlt1} from '@mui/icons-material'
import {Link} from 'react-router-dom'
import dayjs from "dayjs";
export default function App(props) {
  const [value, setValue] = React.useState(0);
const [tourDate,setTourDate]=React.useState(props.tourDate)

const [user,setUser]=React.useState({})
const [genralTips, setGenralTips] = React.useState(props.name);
  const images = props.images

  React.useEffect(() => {
    const interval = setInterval(() => {
      setValue((v) => {
        return v === images.length-1 ? 0 : v + 1;
      });
    }, 3000);
    return () => clearInterval(interval);
  }, []);
React.useEffect(()=>{
  setUser(JSON.parse(localStorage.getItem('user')))
  localStorage.setItem('tourDates',JSON.stringify(tourDate))
},[])
// console.log('tour date',tourDate)

  return (
    <Box className="App"
     sx={{ height: "290px", position: 'relative',mb:'6rem'}}>
        <img src={`https://maps.googleapis.com/maps/api/place/photo?photo_reference=${images[0].photo_reference}&maxheight=290&key=AIzaSyDj8raFmgne-CAknrw-Zyzl7iE6sXaOE8A`} style={{width: '100%', height: '290px', objectFit: 'cover'}}/>
        <Box sx={{
            background: `linear-gradient(180deg,rgba(33,37,41,.5) 0,transparent 50%)`,
            bottom:0,
            left: 0,
            right: 0,
            top: 0,
            height: '100%',
            position: 'absolute'
        }}/>
                <Box
                  sx={{
                    width:{xs:'95%',md:"70%",},
                    height: "8rem",
                    background: "white",
                    position: "absolute",
                    // bottom: "-3/rem",
                    ml:{xs:'2%',md:'15%'},
                   mt:'-7.5rem',
                    p: ".8rem",
                    display: "flex",
                    flexDirection:'column',
                    justifyContent:'space-between',
                    zIndex: "1",
                    borderRadius: "10px",
                    boxShadow:
                      "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                  }}
                >
                  {/* <Typography variant="h5" textAlign='left' sx={{ fontWeight: "bold" }}>
                   Trips To  {name}
                  </Typography> */}
               <input type='text'
                      value={genralTips}
                      onChange={(e) => setGenralTips(e.target.value)}
                  
                      style={{ fontSize: "1.5rem", fontWeight: "bold",border:'none',height:'2.5rem',outline:'none',cursor:'text' }}
                    />
<Box sx={{display:'flex',justifyContent:'space-between',alignItems:'center',m:'0',p:'0t'}}>
<Typography variant="body2">{dayjs(props.tourDate?.startDate).format('DD/MM/YY')} -{dayjs(props.tourDate?.endDate).format('DD/MM/YY')}</Typography>
<Typography
                    variant="body2"
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "center",
                      pt: "1rem",
                    }}
                  >     
                 <Link to='/auth/userProfile' >   <Box component="img"
                    src={user.image_path}
                      sx={{
                        color: "gray",
                        width:'35px',
                        height:'35px',
                        borderRadius: "50%",
                        background: "lightGray",
                        mr: ".5rem",
                        cursor: "pointer",
                        "&:hover": {
                          transform: "scale(1.5)",
                        },
                      }}
                    /></Link>
                    <PersonAddAlt1
                      sx={{
                        color: "gray",
                        cursor: "pointer",
                        "&:hover": {
                          transform: "scale(1.5)",
                        },
                      }}
                    />
                  </Typography>
</Box>
                </Box>
    </Box>
  );
}