import { useState } from "react";
import {
  Grid,
  Rating,
  Box,
  Typography,
  Container,
  Button,
} from "@mui/material";
import Topbar from "../../../pages/home/Topbar";
import {
  Clear,
  Collections,
  InsertEmoticon,
  LocationCity,
  LocationOn,
} from "@mui/icons-material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const RoomDetails = () => {
  const settings = {
    dots: true,
    autoplay:true,
    autoplaySpeed: 2000,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          dots: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 320,
        settings: {
          dots: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const slider = [
    {
      image:
        "https://images.unsplash.com/photo-1564501049412-61c2a3083791?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1032&q=80",
    },
    {
      image:
        "https://images.unsplash.com/photo-1611892440504-42a792e24d32?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
    },
    {
      image:
        "https://images.unsplash.com/photo-1618773928121-c32242e63f39?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
    },
    {
      image:
        "https://plus.unsplash.com/premium_photo-1661505108331-0bba13ee1edc?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=871&q=80",
    },
    {
      image:
        "https://sgp1.digitaloceanspaces.com/gozayaan/media/hotel/688864/gozayaan/hotel_images/medium-vRkW04ywKicd81a136-a913-4fbf-a555-b500e134c7f5.jpg",
    },
    {
      image:
        "https://images.unsplash.com/photo-1512918728675-ed5a9ecdebfd?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
    },
    {
      image:
        "https://images.unsplash.com/photo-1578683010236-d716f9a3f461?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
    },
    {
      image:
        "https://images.unsplash.com/photo-1551882547-ff40c63fe5fa?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80",
    },
  ];

  const pic = slider.slice(0, 3).map((item) => (
    <Box
      component="img"
      src={item.image}
      sx={{
        width: { xs: "59px", md: "100%" },
        mx: { xs: "5px", md: "0" },
        height: { md: "9vh",lg:"16vh", xl: "10.8vh" },
        my: "5px",
        cursor: "pointer",
      }}
      
    />
  ));
  const [value, setValue] = useState(2);
  const [sliderFull, setSliderFull] = useState(false);
  return (
    <>
      {sliderFull ? (
        <Box
          sx={{
            height: "92vh",
            width: "100%",
            zIndex: "9999",
            background: "black",
            position: "absolute",
            top: "0",
            display:{xs:'none',md:'block'}
          }}
        >
          <Clear
            sx={{
              position: "absolute",
              right: "20px",
              top: "1rem",
              color: "white",
              zIndex: "99999",
              cursor: "pointer",
            }}
            onClick={() => setSliderFull(false)}
          />
          <Box sx={{ width: "80%", m: "0 auto", zIndex: "99999" }}>
            <Slider {...settings}>
              {slider.map((data, ind) => (
                <Box
                  component="img"
                  src={data.image}
                  key={ind}
                  sx={{ width: "80%", height: { md: "88vh", xl: "90vh" } }}
                />
              ))}
            </Slider>
          </Box>
        </Box>
      ) : (
        " "
      )}
      <Topbar />
      <Box sx={{background:'#ebf0f4',mt:'5rem',minHeight:'91.5vh'}} >
        <Container sx={{pt:{md:'2rem'}}}>

        <Grid container xs={12}sx={{ background:'white'}} >
          <Grid
            item
            xs={12}
            md={6}
            sx={{ py: "5px", height: { md: "70vh", xl: "50vh" } }}
          >
            <Slider {...settings} className="hotel__detail">
              {slider.map((data, ind) => (
                <Box
                  component="img"
                  src={data.image}
                  key={ind}
                  sx={{
                    width: { xs: "20%", md: "100%" },
                    height: { md: "40vh",lg:'66vh', xl: "46vh" },
                  }}
                />
              ))}
            </Slider>
          </Grid>
          <Grid container xs={12} md={6}>
            <Grid
              itme
              xs={12}
              md={2}
              sx={{ py: ".5rem", pt: { xs: "0", md: ".6rem" } }}
            >
              <Box
                sx={{
                  display: "flex",
                  height: { xs: "80px",md:'60vh',lg:'68vh' },
                  ml: { xs: ".3rem", md: "0" },

                  flexDirection: { xs: "row", md: "column" },
                }}
              >
                {pic}
                <Box
                  sx={{
                    width: { xs: "59px", md: "100%" },
                    height: { md: "9vh",lg:'15vh', xl: "10.6vh" },
                    my: "5px",
                    ml: { xs: ".3rem", md: "0" },
                    cursor: "pointer",
                    backgroundImage: `url(${
                      slider.length > 4 ? slider[4].image : ""
                    })`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                  onClick={() => setSliderFull(true)}
                >
                  <Box
                    sx={{
                      background: "black",
                      //   height: "15.3vh",
                      height: { md: "9vh",lg:"15vh", xl: "10.6vh" },
                      width: "100%",
                      opacity: "0.7",
                    }}
                  >
                    <Collections
                      sx={{
                        color: "white",
                        width: "100%",
                        height: { xs: "52px", md: "30%" },
                        m: "0 auto",
                        mt: { xs: "10%", md: "30%" },
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={10}
              sx={{ p: ".5rem", pt: { xs: ".3rem", md: ".9rem" } }}
            >
              <Grid xs={12} sx={{ py: ".8rem", pt: "0", px: ".3rem" }}>
                <Box
                  sx={{ display: "flex", alignItems: "center", mb: ".4rem" }}
                >
                  <Button
                    variant="outlined"
                    sx={{
                      p: "0",
                      width: {xs:"15%",lg:'20%'},
                      borderRadius: "7px",
                      mr: ".5rem",
                      textAlign: "center",
                    }}
                  >
                    Villa
                  </Button>
                  <Rating
                    size="small"
                    name="simple-controlled"
                    value={value}
                    onChange={(event, newValue) => {
                      setValue(newValue);
                    }}
                  />
                </Box>
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    color: "primary.main",
                    fontFamily: "monospace",
                    px: ".3rem",
                    
                  }}
                >
                  Mountain Terrace
                </Typography>
                <Typography sx={{display:'flex',alignItems:'center',color: "#503e6c",}}>

              
                <LocationOn />
                <Typography
                  variant="body2"
                  sx={{
                    
                  
                   
                    width: {xs:"100%",md:'400px'},
                    fontSize:{xl:"18px"},                  
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    "&:hover": {
                      overflowX: "visible",
                      whiteSpace:'pre-wrap',
                      position:'absolute',
                      background:'white',
                    
                    },
                  }}
                >
                
                  Near Nathia Gali, Khaira Gali, Khaira Gali, Abbottabad,
                  Pakistan
                </Typography>
                </Typography>
              </Grid>
              <Grid
                xs={12}
                sx={{
                  borderRadius: "10px",
                  height: "7rem",
                  p: { xs: "0", md: ".5rem" },
                  pb: ".8rem",
               
                }}
              >
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d13292.403961014967!2d73.0523224!3d33.60268405!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2s!4v1670829235070!5m2!1sen!2s"
                  style={{
                    width: "100%",
                    height: "100%",
                    border: "none",
                    borderRadius: "10px",
                  }}
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </Grid>
              <Grid
                xs={12}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  mt: { xs: ".8rem", md: "0", },
                }}
              >
                <Typography
                  variant="caption"
                  sx={{
                    pb: ".5rem",
                    px: ".5rem",
                    color: "gray",
                    display: "flex",
                    alignItems: "center",
                    fontSize:{xl:'20px'}
                  }}
                >
                  What's Nearby
                </Typography>
                <Typography
                  variant="caption"
                  sx={{
                    pb: ".5rem",
                    color: "#503e6c",
                    display: "flex",
                    alignItems: "center",
                    fontSize:{xl:'18px'}
                  }}
                >
                  <LocationOn sx={{ fontSize: "18px" }} /> 0.4 km from Khaira
                  Gali Pipeline Track
                </Typography>
                <Typography
                  variant="caption"
                  sx={{
                    pb: ".5rem",
                    color: "#503e6c",
                    display: "flex",
                    alignItems: "center",
                    fontSize:{xl:'18px'}
                  }}
                >
                  <LocationOn sx={{ fontSize: "18px" }} /> 10 km from Ayubia
                  Chairlift
                </Typography>
              </Grid>
              <Grid xs={12} sx={{ px: ".3rem", pt: ".7rem" }}>
                <Grid container xs={12} md={11} sx={{display:'flex',justifyContent:'space-between'}}>
                  <Grid item xs={6} md={3}>
                    <Button size="small" variant="outlined"  sx={{width:'100%',m:{xs:'0 auto',md:'0'}}}>
                      Check-In
                    </Button>
                  </Grid>
                  <Grid item xs={6} md={3}>
                  
                    <Button size="small" variant="outlined"  sx={{width:'100%',m:{xs:'0 auto',md:'0'}}}>
                      Check-Out
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={5} sx={{mt:{xs:'.6rem',md:'0',}}}>
                   
                    <Button size="small" variant="outlined" sx={{width:'100%',m:{xs:'0 auto',md:'0'}}}>
                      Coffee/Tea in Lobby
                    </Button>
                  </Grid>
                </Grid>
                <Grid container xs={12} md={11} sx={{mt:{xs:'.5rem',display:'flex',justifyContent:'space-between'}}}>
                  <Grid item xs={5.8} md={3} sx={{width:'100%',m:{xs:'0 auto',md:'0'}}}>
                  <Button size="small" variant="outlined">
                    <LocationCity />
                    Garden
                  </Button>
                  </Grid>
                  <Grid item xs={5.8} md={3} sx={{width:'100%',m:{xs:'0 auto',md:'0'}}}>
                  
                  <Button size="small" variant="outlined">
                    <InsertEmoticon />
                    Highchairs
                  </Button>
                  </Grid>
                  <Grid item xs={12} md={4} sx={{mt:{xs:'.6rem',md:'0',}}}>
                   
                  <Button size="small" variant="outlined" sx={{width:'100%',m:{xs:'0 auto',md:'0'}}}>
                    ID Required
                  </Button>
                  </Grid>
                </Grid>
         
              </Grid>
            </Grid>
          
         
          </Grid>
          <Grid xs={12} container sx={{mt:'2rem',    boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1px 1.95px 2.6px",mb:'1rem'}}>
            <Grid xs={12} sx={{p:'.5rem'}}><Typography variant='h6' sx={{fontWeight:'bold',color:"primary.main"}}>Hotel Description</Typography></Grid>
             <Grid item xs={6} md={6} sx={{p:'.5rem'}}>

<Typography variant='h6' sx={{}}>Hotel Name</Typography>
<Typography variant='h6' sx={{}}>Number of Rooms: </Typography>
<Typography variant='h6' sx={{}}>Number of Floors: </Typography>

                </Grid>
                <Grid item xs={6} md={6} sx={{p:'.5rem'}}>
<Typography variant='body2' sx={{py:'.3rem',fontWeight:'bold', fontFamily: "monospace",}}>Mountain Terrace</Typography>
<Typography variant='body2' sx={{fontWeight:'bold',py:'.3rem', fontFamily: "monospace",}}>Total 89 Rooms</Typography>
<Typography variant='body2' sx={{fontWeight:'bold',py:'.3rem', fontFamily: "monospace",}}>Total 5 Floors</Typography>

                </Grid>
             
            </Grid>
        </Grid>
        </Container>

      </Box>
    </>
  );
};

export default RoomDetails;
