import { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Grid,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Container,
  Button,
} from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { getUserTrip } from "../../api-services/userServices/tripPlanServices";
import { Link } from "react-router-dom";
import { Add, MoreHoriz } from "@mui/icons-material";
import DropDown from "./DropDown";
import Skeleton from "@mui/material/Skeleton";
import dayjs from "dayjs";
const HomeSlider = () => {
  const [tripData, setTripData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [deletedId, setDeletedId] = useState();
  const userTrip = async () => {
    const resp = await getUserTrip();

    if (resp.data.success) {
      setTripData(resp?.data.data);
      setIsLoading(resp?.data.success);
    }
  };
  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    userTrip();
  }, []);

  const settings = {
    dots:true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          dots:false,
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          dots:false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const DelteCard = (id) => {
    setDeletedId(id);
    setShow(true);
  };
  const ConfirmDelete = () => {
    let newData = tripData.filter((data) => {
      return data.id != deletedId;
    });
    setTripData(newData);
    setShow(false);
  };

  return (
    <>
      <Container>
        <Box sx={{ width: "100%", mt: "7rem", height: "50vh" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              variant="h4"
              sx={{ fontSize: { xs: "15px", md: "24px" } }}
            >
              Recent Trips
            </Typography>
            <Link to="/createPlan" style={{ textDecoration: "none" }}>
              <Button
                variant="contained"
                size="small"
                sx={{ fontSize: "12px" }}
              >
                <Add />
                Plan New Trip
              </Button>
            </Link>
          </Box>
          {show ? (
            <Container
              sx={{
                width: "100%",
                position: "absolute",
                top: "5rem",
                zIndex: "999",
              }}
            >
              <Box
                sx={{
                  width: { xs: "80%", md: "300px" },
                  m: "0 auto",
                  p: "1rem",
                  height: "8rem",
                  background: "white",
                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                }}
              >
                <Typography variant="subtitle2" sx={{ fontSize: "1.2rem" }}>
                  Are you sure to delete your trip
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mt: ".3rem",
                  }}
                >
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => setShow(false)}
                  >
                    Cancel
                  </Button>
                  <Button variant="contained" onClick={ConfirmDelete}>
                    Delete
                  </Button>
                </Box>
              </Box>
            </Container>
          ) : null}
          {!isLoading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
             
              <Typography textAlign="center" variant="h6" mt={2}>
                Please wait your trip data is loading{" "}
              </Typography>
              <Box sx={{ width: "100%", }}>
                <Grid container>
                  <Grid xs={12} md={3}>
                    <Skeleton
                      variant="rectangular"
                      sx={{ width: "90%" }}
                      height={118}
                    />
                    <Skeleton animation="wave" sx={{ width: "90%" }} />
                    <Skeleton animation={false} sx={{ width: "90%" }} />
                  </Grid>
                  <Grid xs={12} md={2.8} display={{xs:'none',md:'block'}}>
                    <Skeleton
                      variant="rectangular"
                      sx={{ width: "90%" }}
                      height={118}
                    />
                    <Skeleton animation="wave"  sx={{ width: "90%" }}/>
                    <Skeleton animation={false} sx={{ width: "90%" }} />
                  </Grid>
                  <Grid xs={12} md={2.8} display={{xs:'none',md:'block'}}>
                    <Skeleton
                      variant="rectangular"
                      sx={{ width: "90%" }}
                      height={118}
                    />
                    <Skeleton animation="wave" sx={{ width: "90%" }} />
                    <Skeleton animation={false}  sx={{ width: "90%" }}/>
                  </Grid>
                  <Grid xs={12} md={2.8} display={{xs:'none',md:'block'}}>
                    <Skeleton
                      variant="rectangular"
                      sx={{ width: "90%" }}
                      height={118}
                    />
                    <Skeleton animation="wave"  sx={{ width: "90%" }}/>
                    <Skeleton animation={false} sx={{ width: "90%" }} />
                  </Grid>
                </Grid>
              </Box>
              :
            </Box>
          ) : (
            <Slider {...settings}  >
              {tripData?.map((data, ind) => (
                <Card
                  key={ind}
                  sx={{ width: "80%", position: "relative" }}
                  className="home__slide"
                >
                  <CardActionArea sx={{ height: "9rem" }}>
                    <Link
                      to={`/trip/${data.id}/${data.trip_no}`}
                      style={{ textDecoration: "none" }}
                    >
                      <CardMedia
                        component="img"
                        src={`https://maps.googleapis.com/maps/api/place/photo?photo_reference=${
                          JSON.parse(data?.photos) != null ? JSON.parse(data?.photos)[0].photo_reference : ''
                        }&maxheight=290&key=AIzaSyDj8raFmgne-CAknrw-Zyzl7iE6sXaOE8A`}
                        sx={{
                          height: "100%",
                          transition: "all .3s",
                          borderRadius: "5px",
                          "&:hover": {
                            transform: "scale(1.1)",
                          },
                        }}
                      />
                    </Link>
                    <DropDown DelteCard={DelteCard} id={data.id}>
                      <MoreHoriz
                        sx={{
                          position: "absolute",
                          right: "0",
                          top: "2px",
                          background: "RGBA(0,0,0,0.20)",
                          color: "white",
                          borderRadius: "50%",
                          "&:hover": {
                            background: "RGBA(0,0,0,0.30)",
                          },
                        }}
                      />
                    </DropDown>
                  </CardActionArea>
                  <CardContent sx={{ height: "100%" }}>
                    <Typography
                      variant="p"
                      sx={{ "&:hover": { cursor: "pointer" } }}
                    >
                      Trip To <b>{data.name}</b>
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        mt: ".3rem",
                        display: "flex",
                        alignItems: "center",
                        color: "gray",
                      }}
                    >
                      <Link to="/auth/userProfile">
                        <Box
                          component="img"
                          src={user?.image_path}
                          sx={{
                            width: "30px",
                            height: "30px",
                            borderRadius: "50%",
                            cursor: "pointer",
                            mr: "4px",
                          }}
                        />
                      </Link>
                      {dayjs(data.from_date).format("DD-MMM-YYYY")} To
                      {dayjs(data.to_date).format("DD-MMM-YYYY")}
                    </Typography>
                  </CardContent>
                </Card>
              ))}
            </Slider>
          )}
        </Box>
      </Container>
    </>
  );
};

export default HomeSlider;
