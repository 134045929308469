import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';

export default function MediaControlCard() {
  const theme = useTheme();

  return (
    <Card sx={{ display: 'flex' }} className="flight__data_card">
        <Grid container>
        <Grid xs={4}>
            <CardMedia
                component="img"
                sx={{ width: '100%', maxHeight: '142px', minHeight: '142px' }}
                image="https://www.nps.gov/shen/planyourvisit/images/BML-One-Bedroom-Suite.jpg?maxwidth=1200&maxheight=1200&autorotate=false"
                alt="Live from space album cover"
            />
        </Grid>

            <Grid xs={8}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <CardContent sx={{ flex: '1 0 auto' }}>
                <Typography component="div" variant="h5">
                    Paris, France
                </Typography>
                <Typography variant="subtitle1" color="text.primary" component="div">
                    Mac Miller
                </Typography>
                </CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <p style={{padding: '0px 16px', margin: '0px'}}>Google Flights is an online flight booking search service which facilitates the purchase of airline tickets through third-party suppliers. It was launched by Google in 2011 following a buyout. It is now part of Google Travel</p>
                </Box>
            </Box>
            </Grid>
            
        </Grid>
      
      
    </Card>
  );
}
