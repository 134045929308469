import * as React from "react"
import StaticTopbar from "../staticTopbar"
import {Avatar,Container,Button,Typography,Box,TextField,CssBaseline} from "@mui/material"
import LockOutlinedIcon from "@mui/icons-material/LockOutlined"
import { ThemeProvider } from "@mui/material/styles"
import Footer from "../footer"
import { useParams } from "react-router-dom"
import axios from "axios"

export const ConfirmPassword = () => {
  const { id } = useParams()
  const [formValue, setformValue] = React.useState({
    password: "",
  })

  const handleChange = (event) => {
    setformValue({
      ...formValue,
      [event.target.name]: event.target.value,
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    const loginFormData = new FormData()
    loginFormData.append("user_id", id)
    loginFormData.append("password", formValue.password)

    try {
      const response = await axios({
        method: "post",
        url: `https://f90b-182-191-95-80.ngrok.io/shoestring/public/api/set-password`,
        data: loginFormData,
        headers: { "Content-Type": "multipart/form-data" },
      })
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <StaticTopbar />
      <ThemeProvider>
        <Container component='main' maxWidth='xs'>
          <CssBaseline />
          <Box
            sx={{
              marginTop: 15,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component='h1' variant='h5'>
              Password confirmation
            </Typography>
            <Box
              component='form'
              onSubmit={(e) => handleSubmit(e)}
              noValidate
              sx={{ mt: 1 }}
            >
              <TextField
                margin='normal'
                required
                fullWidth
                id='password'
                label='Password'
                name='password'
                autoComplete='password'
                autoFocus
                // inputProps={{
                //   pattern:
                //     "[^(?=.*[A-Za-z])(?=.*d)(?=.*[@$!%*#?&])[A-Za-zd@$!%*#?&]{8,}$]",
                //   title:
                //     "Minimum eight characters, at least one letter, one number and one special character",
                // }}
                value={formValue.password}
                onChange={handleChange}
              />
              {/* <TextField
                margin='normal'
                required
                fullWidth
                name='confirmPassword'
                label='Confirm password'
                type='password'
                id='confirmPassword'
                autoComplete='current-password'
                value={formValue.confirmPassword}
                onChange={handleChange}
              /> */}

              <Button
                type='submit'
                fullWidth
                variant='contained'
                sx={{ mt: 3, color: "white" }}
              >
                confirm
              </Button>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
      <Box
        sx={{
          mt: { xs: "8rem", sm: "10rem" },
        }}
      >
        <Footer />
      </Box>
    </>
  )
}
