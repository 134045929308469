import React from "react";
import { ListItemText, List, ListItemButton, Link,Typography } from "@mui/material";

import dayjs from "dayjs";
const NestedDates = () => {
  const dates = [];
  const [active,setActive]=React.useState()

  const dateItinerary = (startDate, endDate) => {
    const date = new Date(startDate.getTime());
  

    while (date <= endDate) {
      dates.push(new Date(date));
      date.setDate(date.getDate() + 1);
    }

    return dates;
  };
  const tourDate=JSON.parse(localStorage.getItem('tourDates'))


  const d1 = new Date(tourDate.startDate);
  const d2 = new Date(tourDate.endDate);
  dateItinerary(d1, d2);
  // console.log(dates[0].toString().split(' ')[0])

  localStorage.setItem("dates", JSON.stringify(dates));
  // console.log('dates',dates)

  return (
    <div>
      {/* <ListItemText sx={{ textAlign: "center",display:'flex',flexDirection:'col' }} primary={indata}/> */}

      {dates?.map((data, index) => {
        // return <Typography>{dayjs(data).format("YYYY-MM-DD")}</Typography>
        return (
          <List component="div" disablePadding>
            <ListItemButton sx={{ pl: 4,py:'.8rem',mt:'1rem',boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px"}} >
              {/* <ListItemText sx={{ textAlign: "center" }} primary={dayjs(data).format("ddd-YYYY-MM-DD")} /> */}
              <Link href={`#${index}`} style={{ textDecoration: "none"}}  >
                <Typography sx={{ color: "black" ,}} className={active==data?'active':undefined} id={data} onClick={(e)=>setActive(e.target.id)}>
                  {dayjs(data).format("ddd-YYYY-MM-DD")}
                </Typography>
              </Link>
            </ListItemButton>
          </List>
        );
      })}
    </div>
  );
};

export default NestedDates;
