import React from "react";
import { Box } from "@mui/material"


const images = [
  "./slide1.png",
  "./slide2.png",
  // "./slide3.png"
];

export default function App(props) {
  const [value, setValue] = React.useState(0);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setValue((v) => {
        return v === images.length-1 ? 0 : v + 1;
      });
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Box className="App"
     sx={{ backgroundImage: {xs:'none',sm:`url('./frame.png'), url(${images[value]})`}, height: "100vh", transition: 'all 1s',
     backgroundPosition: 'center', backgroundSize: 'cover',backgroundRepeat:'no-repeat', Postion: 'relative' }}>
      {props.children}
    </Box>
  );
}