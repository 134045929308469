import {useState,useEffect} from "react";
import Layout from "./Layout";
import {Container,Paper,Grid} from "@mui/material";
import Chart from "./Chart";
import TotalTrip from './TotalTrip'
import Deposits from "./Deposits";
import RecentBudgets from "./RecentBudgets";
import { getTopRecentBudgets } from '../../../api-services/userServices/budgetServices';

function Dashboard() {


  const[latestBudget,setLatestBudget]=useState([]);
  const getBudgetData = async () => {
   const response = await getTopRecentBudgets();
   setLatestBudget(response.data.data);
 
 };
 useEffect(() => {
   getBudgetData();
 }, []);

  return (
    <Layout name="Dashboard">
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={3}>
          {/* Chart */}
          <Grid item xs={12} md={4} lg={4}>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                height: 160,
              }}
            >
           <TotalTrip totalTrip={latestBudget.total_trips}/>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4} lg={4}>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                height: 160,
              }}
            >
          <Chart totalBudget={latestBudget.total_budget_plans}/>
            </Paper>
          </Grid>
          {/* Recent Deposits */}
          <Grid item xs={12} md={4} lg={4}>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                height: 160,
              }}
            >
              <Deposits />
            </Paper>
          </Grid>
          {/* Recent Orders */}
          <Grid item xs={12}>
            <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
              <RecentBudgets latestBudget={latestBudget}/>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
}

export default Dashboard;
