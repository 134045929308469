import {Box,Typography,Button} from '@mui/material'
import { Sell } from '@mui/icons-material'

const LodgingTop = () => {
  return (
    <Box
    sx={{
      display: "flex",
      p: ".5rem",
      boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
      background: "white",
    }}
  >
    <Box
      sx={{
        width: "12%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Sell
        sx={{
          bgcolor: "primary.main",
          color: "white",
          width: { md: "65%" },
          fontSize: "10px",
          height: { md: "80%" },
          p: ".7rem",
          borderRadius: "50%",
        }}
      />
    </Box>
    <Box sx={{ width: "87%" }}>
      <Typography
        variant="caption"
        sx={{
          color: "#503e6c",
          fontSize: "16px",
          fontWeight: "bold",
        }}
      >
        You could be seeing lower prices
      </Typography>
      <Typography variant="body2" sx={{ color: "gray" }}>
        With memeber prices,you could save more right now on thousands
        of properties
      </Typography>
      <Box>
        <Button>Sign in</Button>
        <Button>Sign up</Button>
      </Box>
    </Box>
  </Box>
  )
}

export default LodgingTop